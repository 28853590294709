import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import toLower from 'lodash/toLower';

const getWidgetSummaries = (widgetCategories, widgetsSummary, hasWidgetAdminRead) => widgetsSummary
  .filter((w) => hasWidgetAdminRead ? true : w.isActive)
  .map((widget) => (Object.assign({},
    { ...widget, tags: widget.tags.replace(/,/g, ', ') },
    { wcid: widget.wcid.join(' ') },
    { categories: widget.wcid.length === 0 ? '' : widget.wcid.map((cid) => widgetCategories.find((cat) => cat.wcid === cid).widgetCategoryName).join(' ') },
  )));

export const getSortedWidgetSummaries = createSelector(
  [getWidgetSummaries],
  (widgetSummaries) => sortBy(
    widgetSummaries,
    (eachWidget) => toLower(eachWidget.widgetTemplateName),
  ),
);
