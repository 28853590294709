/**
 *  * Created by Stewart Gordon on 7/15/2018.
 */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as widgetActions from '../../../actions/widgetActions';
import WidgetTypeNav from './WidgetTypeNav';
import { getSortedWidgetSummaries } from '../../../selectors/dashboardWidgetModal';
import CultureContext from '../../intl/CultureContext';
import { generateUniqueId, searchForMatchKeyword } from '../../../utils';
import AddDashboardWidgetsGridContainer from './AddDashboardWidgetsGridContainer';
import { process } from '@progress/kendo-data-query';
import * as datasets from '../../../enums/datasets';
import { userResources } from '../../../enums/resources';

export class AddDashboardWidgetModal extends React.Component {
  static contextType = CultureContext;
  constructor(props) {
    super(props);
    const columns = [
      { title: 'Widget Name', field: 'widgetTemplateName', show: true, filter: 'text', cell: this.renderDefaultCell },
      { title: 'Type', field: 'widgetTypeName', show: true, filter: 'text', cell: this.renderWidgetType },
      { title: 'Description', field: 'widgetTemplateDescription', show: true, filter: 'text', cell: this.renderDefaultCell },
      { title: 'Data Type', field: 'datasetName', show: true, filter: 'text', cell: this.renderDefaultCell },
      { title: 'Categories', field: 'categories', show: true, filter: 'text', cell: this.renderWidgetCategoriesCustom },
      { title: 'Tags ', field: 'tags', show: true, filter: 'text', cell: this.renderDefaultCell },
    ];
    const dataState = this.createDataState({
      sort: [
        { field: 'widgetTemplateName', dir: 'asc' },
      ],
      take: 10,
      skip: 0,
    }, this.modifyResult([], true));
    this.state = {
      selectedWidgets: [],
      selectedItems: {
        datasetNames: [],
        widgetTypeNames: [],
        categories: [],
      },
      search: '',
      searchFilter: '',
      columns,
      ...dataState,
      gridWidth: 768,
    };
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onItemClicked = this.onItemClicked.bind(this);
    this.onSearchFilterClicked = this.onSearchFilterClicked.bind(this);
  }

  componentDidMount() {
    const { actions, widgetCategories, widgetSummaries } = this.props;
    if (widgetCategories.length === 0) {
      actions.getWidgetCategories();
    }
    if (widgetSummaries.length === 0) {
      actions.getWidgetSummaries();
    }
  }

  // check for changes to search, selectedItems or widgetSummaries
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.widgetSummaries !== prevProps.widgetSummaries ||
      this.state.selectedItems !== prevState.selectedItems ||
      this.state.searchFilter !== prevState.searchFilter
    ) {
      this.updateWidgetTypes();
    }
  }

  //sets the name of the dataset depending on the cluster
  widgetDatasetNameSetter = (datasetName) => {
    const oneOrMoreDatasets = datasetName.split(',');
    const prefixedDs =  oneOrMoreDatasets.reduce((accumulator, ds) => {
      switch (ds) {
        case 'DiagnosticResults':
        case 'Diagnostics':
          accumulator.push('Diagnostics');
          break;
        case 'Tasks_LIVE':
        case 'Tasks':
        case 'TasksLiveAdx':
          accumulator.push('Tasks');
          break;
        case 'Raw Data':
        case 'Points':
        case 'Points_LIVE':
          accumulator.push('Raw Data');
          break;
        case 'AggregatedRawDataDataset':
          accumulator.push('Aggregated Raw Data');
          break;
        case 'VData':
          accumulator.push('Calculated Data');
          break;
        case 'Organizations':
          accumulator.push('Organization Details');
          break;
        case 'Buildings':
        case 'BuildingGroups':
        case 'BuildingVariables':
        case 'BuildingVariables_LIVE':
          accumulator.push('Building Details');
          break;
        case 'Equipment':
        case 'EquipmentVariables':
        case 'EquipmentVariables_LIVE':
        case 'Equipment Relationships':
        case 'Equipment_LIVE':
          accumulator.push('Equipment Details');
          break;
        case 'Data Sources':
        case 'DataSource Health':
          accumulator.push('Data Sources');
          break;
        default:
          accumulator.push(ds);
          break;
      }
      return accumulator;
    }, []);
    return prefixedDs.join(', ');
  };

  createDataState(dataState, newResult) {
    let data = newResult.filter((newResultItem) => newResultItem.datasetName !== 'Tasks with Diagnostics').map((newResultItem) => ({
      ...newResultItem,
      datasetName: this.widgetDatasetNameSetter(newResultItem.datasetName),
    }));
    const searchKeyword = this.state && this.state.searchFilter ? this.state.searchFilter : '';
    const selectedItems = this.state && this.state.selectedItems ? this.state.selectedItems : null;

    if (searchKeyword) {
      data = data.filter((item) => {
        const { widgetTemplateName, widgetTypeName, widgetTemplateDescription, datasetName, tags, categories  } = item;
        const differentWidgetTypeName = widgetTypeName === 'Highcharts' ? 'Chart' : widgetTypeName === 'HTML' ? 'Text' : widgetTypeName;
        const differentDatasetName = this.widgetDatasetNameSetter(datasetName);
        const fullString = `${widgetTemplateName} ${differentWidgetTypeName}  ${widgetTemplateDescription} ${differentDatasetName} ${categories} ${tags}`;
        const hasMatch = searchForMatchKeyword(fullString, searchKeyword);
        return hasMatch;
      });
    }

    //left hand nav widget types logic
    if (selectedItems && selectedItems.datasetNames.length > 0) {
      data = data.filter((item) => selectedItems.datasetNames.some((datasetId) => item.datasetIDs.includes(datasetId)));
    }
    if (selectedItems && selectedItems.widgetTypeNames.length > 0) {
      data = data.filter((item) => selectedItems.widgetTypeNames.some((widgetTypeId) => widgetTypeId === item.widgetTypeId));
    }
    if (selectedItems && selectedItems.categories.length > 0) {
      data = data.filter((item) => selectedItems.categories.some((category) => item.categories.includes(category)));
    }

    return {
      result: process(data, dataState),
      dataState,
    };
  }

  dataStateChange = (event) => {
    const newResult = this.modifyResult(this.state.selectedWidgets);
    this.setState(this.createDataState(event.data, newResult));
  };

  onItemClicked(field, value) {
    let newArr = this.state.selectedItems[field].slice(0);
    if (field === 'datasetNames') {
      if (newArr.some((arr1)=> value.includes(arr1))) {
        newArr = newArr.filter((item) => !value.includes(item));
      } else {
        newArr = [...newArr, ...value];
      }
    }
    else if (newArr.includes(value)) {
      newArr = newArr.filter((item) => item !== value);
    } else {
      newArr.push(value);
    }
    this.setState({selectedItems: {
      ...this.state.selectedItems,
      [field]: newArr,
    }});
  }

  onCellSelect = (evt, dataItem, nonClickEvent=false) => {
    if(!nonClickEvent) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    const newWidget = {...dataItem, id: generateUniqueId()};
    const dataState = this.state.dataState;
    this.setState((prevState) => ({
      selectedWidgets: this.modifySelectedWidgets(prevState.selectedWidgets, newWidget),
      ...this.createDataState(dataState, this.modifyResult(this.modifySelectedWidgets(prevState.selectedWidgets, newWidget))),
    }));
  };

  // Here we need to update dataState to reflect a new filter. This will change the data and the datastate both
  onSearchFilterClicked() {
    this.setState((state) => ({ searchFilter: state.search }));
  }

  // This just handles changes to teh state of the search field
  onSearchChange(e) {
    this.setState({ search: e.target.value });
  }

  modifySelectedWidgets(prevWidgets = [], newWidget = {}) {
    if (prevWidgets.some(({ wtid }) => wtid === newWidget.wtid)) {
      return prevWidgets.filter(({ wtid }) => wtid !== newWidget.wtid);
    }
    return [...prevWidgets, newWidget];
  }

  modifyResult(selectedWidgets, initialize = false) {
    const initialResult = this.props.widgetSummaries.length === 0 ? this.props.widgetSummaries.slice() : this.props.widgetSummaries.map((widgetSummary) => ({
      ...widgetSummary,
      selected: initialize ? false : selectedWidgets.some(({ wtid }) => wtid === widgetSummary.wtid),
    }));
    return initialResult;
  }

  handleKeyPress = (e) => {
    if ((e.keyCode || e.which) === 13) {
      this.onSearchFilterClicked();
    }
  };

  onNonClickEvent = (event) => {
    this.onCellSelect(event, event.dataItem, true);
  };

  onColumnsSubmit = (columnsState) => {
    this.setState({
      columns: columnsState,
    });
  };

  updateWidgetTypes = () => {
    const dataState = {
      sort: [
        { field: 'widgetTemplateName', dir: 'asc' },
      ],
      take: 10,
      skip: 0,
    };

    this.setState(this.createDataState(dataState, this.modifyResult([], true)));
  };

  renderDefaultCell = (cellprops) => {
    const isCentered = cellprops.field === 'widgetTypeName' || cellprops.field === 'datasetName' || cellprops.field === 'categories' || cellprops.field === 'tags';
    return (<td className='add-widget-cell' onClick={(evt) => this.onCellSelect(evt, cellprops.dataItem)}>
      <div className={`widget-category-container ${isCentered ? 'widget-category-container-centered' : ''}`}>
        <span>{cellprops.dataItem[cellprops.field]}</span>
      </div>
    </td>);
  };

  renderWidgetCategoriesCustom = (cellprops) => (
    <td className='add-widget-cell' onClick={(evt) => this.onCellSelect(evt, cellprops.dataItem)}>
      <div>
        {cellprops.dataItem.categories.length === 0 ? '' :
          cellprops.dataItem.categories.split(' ').map((wcat) => (
            <div key={wcat} className='widget-category-container widget-category-container-centered'><span className={`widget-category ${wcat}`}>{wcat}</span></div>
          ))
        }
      </div>
    </td>
  );

  renderWidgetType = (cellprops) => {
    var widgetTypeIcon = '';
    var widgetTypeTitle = '';
    if (cellprops.dataItem.widgetTypeName) {
      switch (cellprops.dataItem.widgetTypeName) {
        case 'Highcharts':
          widgetTypeIcon = 'flaticon-bar-chart';
          widgetTypeTitle= 'Chart';
          break;

        case 'HTML':
          widgetTypeIcon = 'flaticon-speech-bubble-with-text-lines';
          widgetTypeTitle= 'Text';
          break;

        case 'Map':
          widgetTypeIcon = 'flaticon-location';
          widgetTypeTitle= 'Map';
          break;

        case 'Table':
          widgetTypeIcon = 'flaticon-table-grid';
          widgetTypeTitle= 'Table';
          break;
        default:
          break;
      }
    }
    return (<td className='add-widget-cell' onClick={(evt) => this.onCellSelect(evt, cellprops.dataItem)}>
      <div className='widget-category-container widget-category-container-centered widget-category-type'>
        <i className={widgetTypeIcon} aria-hidden='true' title={widgetTypeTitle} />
      </div>
    </td>);
  };

  widgetDatasetOptionsMapper = (widgetDatasets) => {
    if (widgetDatasets && widgetDatasets.length > 0) {
      return [
        {
          field: 'Diagnostics',
          ids: widgetDatasets.filter((widgetDataset) =>
            widgetDataset.datasetId === datasets.widgetDatasets.DiagnosticsDataset ||
            widgetDataset.datasetId === datasets.widgetDatasets.DiagnosticResultsDataset)
            .map((widgetDataset) => widgetDataset.datasetId),
        },
        {
          field: 'Tasks',
          ids: widgetDatasets.filter((widgetDataset) =>
            widgetDataset.datasetId === datasets.widgetDatasets.TaskrecordsDatasetLive ||
              widgetDataset.datasetId === datasets.widgetDatasets.Tasks ||
              widgetDataset.datasetId === datasets.widgetDatasets.TaskrecordsDatasetLiveAdx)
            .map((widgetDataset) => widgetDataset.datasetId),
        },
        {
          field: 'Raw Data',
          ids: widgetDatasets.filter((widgetDataset) =>
            widgetDataset.datasetId === datasets.widgetDatasets.Rawdatadataset ||
            widgetDataset.datasetId === datasets.widgetDatasets.AggregatedRawDataDataset ||
            widgetDataset.datasetId === datasets.widgetDatasets.Points ||
            widgetDataset.datasetId === datasets.widgetDatasets.Points_LIVE)
            .map((widgetDataset) => widgetDataset.datasetId),
        },
        {
          field: 'Calculated Data',
          ids: widgetDatasets.filter((widgetDataset) =>
            widgetDataset.datasetId === datasets.widgetDatasets.VDataDataset ||
            widgetDataset.datasetId === datasets.widgetDatasets.DiagnosticResultsVDataAdx)
            .map((widgetDataset) => widgetDataset.datasetId),
        },
        {
          field: 'Organization Details',
          ids: widgetDatasets.filter((widgetDataset) =>
            widgetDataset.datasetId === datasets.widgetDatasets.Organizations)
            .map((widgetDataset) => widgetDataset.datasetId),
        },
        {
          field: 'Building Details',
          ids: widgetDatasets.filter((widgetDataset) =>
            widgetDataset.datasetId === datasets.widgetDatasets.Buildings ||
            widgetDataset.datasetId === datasets.widgetDatasets.BuildingGroups ||
            widgetDataset.datasetId === datasets.widgetDatasets.BuildingVariables ||
            widgetDataset.datasetId === datasets.widgetDatasets.BuildingVariables_LIVE ||
            widgetDataset.datasetId === datasets.widgetDatasets.BuildingsDatasetLive)
            .map((widgetDataset) => widgetDataset.datasetId),
        },
        {
          field: 'Equipment Details',
          ids: widgetDatasets.filter((widgetDataset) =>
            widgetDataset.datasetId === datasets.widgetDatasets.EquipmentDataset ||
            widgetDataset.datasetId === datasets.widgetDatasets.EquipmentVariables ||
            widgetDataset.datasetId === datasets.widgetDatasets.EquipmentVariablesLive ||
            widgetDataset.datasetId === datasets.widgetDatasets.EquipmentRelationships ||
            widgetDataset.datasetId === datasets.widgetDatasets.EquipmentRelationshipsAdx ||
            widgetDataset.datasetId === datasets.widgetDatasets.Equipment_LIVE)
            .map((widgetDataset) => widgetDataset.datasetId),
        },
        {
          field: 'Data Sources',
          ids: widgetDatasets.filter((widgetDataset) =>
            widgetDataset.datasetId === datasets.widgetDatasets.DataSources ||
            widgetDataset.datasetId === datasets.widgetDatasets.DataSourceHealth)
            .map((widgetDataset) => widgetDataset.datasetId),
        },
        {
          field: 'Users',
          ids: widgetDatasets.filter((widgetDataset) =>
            widgetDataset.datasetId === datasets.widgetDatasets.Users)
            .map((widgetDataset) => widgetDataset.datasetId),
        },
      ];
    }
    return [];
  };

  // TODO: do not pass the selected widget to addCallback....addCallback should be designed to just accept the selected id
  render() {
    const { widgetDatasets, widgetTypes, widgetCategories, addCallback, cancelCallback } = this.props;
    const filterTypes = {
      datasetNames: this.widgetDatasetOptionsMapper(widgetDatasets),
      widgetTypes: widgetTypes.map((widgetType) => ({
        id: widgetType.widgetTypeId,
        field: widgetType.widgetTypeName === 'Highcharts' ? 'Chart' : widgetType.widgetTypeName === 'HTML' ? 'Text' : widgetType.widgetTypeName,
      })),
      categories: widgetCategories.map((widgetCategory) => ({
        id: widgetCategory.wcid,
        field: widgetCategory.widgetCategoryName,
      })),
    };

    return (
      <div className="box">
        <div className="level modal-header">
          <div className="level-left">
            <h1 className="title">Add Widget</h1>
          </div>
          <div className="level-right">
            <div className="level-item">
              <input
                size={52}
                className="input"
                type="text"
                placeholder="Search based upon template name, description or tags."
                value={this.state.search}
                onChange={this.onSearchChange}
                onKeyPress={this.handleKeyPress}
                data-testid="widget-search"
              />
            </div>
            <div className="level-item">
              <button className="button" title="Search" onClick={this.onSearchFilterClicked} data-testid="widget-search-button">
                <span className="icon">
                  <i className="flaticon-search-interface-symbol" aria-hidden="true" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="box columns modal-main" style={{ marginLeft: '0', marginRight: '0' }}>
          <WidgetTypeNav selectedItems={this.state.selectedItems} onItemClicked={this.onItemClicked} data={filterTypes} />
          <div className="column tile-widget" style={{ marginTop: '1px' }} data-testid="widget-table">

            <div className="add-dashboard-widget-modal-container">
              <AddDashboardWidgetsGridContainer
                result={this.state.result}
                dataState={this.state.dataState}
                dataStateChange={this.dataStateChange}
                onNonClickEvent={this.onNonClickEvent}
                columns={this.state.columns}
                onColumnsSubmit={this.onColumnsSubmit}
              />
            </div>

          </div>
        </div>
        <div className={'modal-footer'} style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', paddingRight: '5px' }}>
          <div className="buttons">
            <button className="button is-info is-outlined is-medium" disabled={this.state.selectedWidgets.length === 0} onClick={() => (addCallback(this.state.selectedWidgets))}>Add</button>
            <button className="button is-info is-outlined is-medium" onClick={cancelCallback}>Cancel</button>
          </div>
        </div>
      </div>
    );
  }
}

AddDashboardWidgetModal.defaultProps = {
  dashboardWidgets: [],
};

AddDashboardWidgetModal.propTypes = {
  widgetDatasets: PropTypes.array.isRequired,
  widgetTypes: PropTypes.array.isRequired,
  widgetCategories: PropTypes.array.isRequired,
  widgetSummaries: PropTypes.array.isRequired,
  dashboardWidgets: PropTypes.array.isRequired, // eslint-disable-line
  actions: PropTypes.object.isRequired,
  addCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
};

// TODO: update categories to use category names instead of ids using map reduce
function mapStateToProps(state, ownProps) {
  const hasWidgetAdminRead = state.user.resources.includes(userResources.WidgetAdmin);
  return {
    dashboardWidgets: ownProps.dashboardWidgets,
    widgetDatasets: state.adminWidgets.widgetDatasets,
    widgetTypes: state.adminWidgets.widgetTypes,
    widgetCategories: state.adminWidgets.widgetCategories,
    widgetSummaries: getSortedWidgetSummaries(state.adminWidgets.widgetCategories, state.adminWidgets.widgetsSummary, hasWidgetAdminRead),
    addCallBack: ownProps.saveCallBack,
    cancelCallback: ownProps.cancelCallback,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, { push }, widgetActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDashboardWidgetModal);
