import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import TextInput from '../common/TextInput';
import CheckboxInput from '../common/CheckboxInput';
import SelectInput from '../common/SelectInput';
import LabelValue from '../common/LabelValue';
import { getEquipmentFieldErrors, maxCmmsLinkCharacters, maxCmmsLocationIdCharacters, maxCmmsReferenceIdCharacters, maxCmmsSiteIdCharacters, maxEquipmentNameCharacters, maxEquipmentNotesCharacters } from '../equipment/equipmentModal/utils';
import { useForm } from '../../utils/hooks';
import { updateEquipment } from '../../actions/equipmentModuleActions';
import { useGetBuildingSetting } from '../equipment/equipmentModal/hooks';
import { clearBuildingSettings, getBuildingSettings } from '../../actions/buildingSettingsActions';
import { mapper } from '../../utils';
import EquipmentProfile from '../diagnostics/diagnosticModal/EquipmentProfile';
import TextArea from '../common/TextArea';
import KendoMultiSelect from '../common/KendoMultiSelect';

const EditEquipmentModal = ({ dataItem, saveCallback, cancelCallback }) => {
  const { values, setValues, onInputChange } = useForm({
    equipmentName: dataItem.equipmentName,
    equipmentClassId: dataItem.equipmentClassId,
    equipmentLabels: dataItem.equipmentTags,
    equipmentTypeId: dataItem.equipmentTypeId,
    active: dataItem.active,
    visible: dataItem.visible,
    configurationStatusId: dataItem.configurationStatusId,
    equipmentNotes: dataItem.equipmentNotes,
    cmmsReferenceId: dataItem.cmmsReferenceId,
    cmmsLocationId: dataItem.cmmsLocationId,
    cmmsSiteId: dataItem.cmmsSiteId,
    cmmsLink: dataItem.cmmsLink,
  });


  const buildingSetting = useGetBuildingSetting(dataItem.bid);
  const isEnableCreateWorkOrder = buildingSetting?.isEnableCreateWorkOrder;

  const dispatch = useDispatch();
  const equipmentLabelsList = useSelector((state) => state.equipmentLookup.equipmentLabels);
  const equipmentSaving = useSelector((state) => state.equipmentModule.equipmentSaving);
  const equipmentLookup = useSelector((state) => state.equipmentLookup);
  const equipmentConfigurationStatuses = useSelector((state) => state.equipmentLookup.equipmentConfigurationStatuses);
  const [fieldErrors, setFieldErrors] = useState();

  useEffect(() => {
    if (dataItem.bid) {
      dispatch(getBuildingSettings([dataItem.bid]));
    }
    return () => {
      dispatch(clearBuildingSettings());
    };
  }, [dataItem]);

  const equipmentClassOptions = useMemo(
    () => mapper(
      equipmentLookup.classes, {
        inputValueKey: 'equipmentClassId',
        inputLabelKey: 'equipmentClassName',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [equipmentLookup.classes],
  );

  const equipmentTypeOptions = useMemo(
    () => mapper(
      equipmentLookup.types.filter(
        (e) => e.equipmentClassId === parseInt(values.equipmentClassId),
      ), {
        inputValueKey: 'equipmentTypeId',
        inputLabelKey: 'equipmentTypeName',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [equipmentLookup.types, values.equipmentClassId],
  );

  const equipmentConfigurationStatusOptions = useMemo(
    () => mapper(
      equipmentConfigurationStatuses, {
        inputValueKey: 'configurationStatusId',
        inputLabelKey: 'configurationStatusName',
        outputValueKey: 'value',
        outputLabelKey: 'text',
      },
    ),
    [equipmentConfigurationStatuses],
  );

  const handleEquipmentClassChange = (event) => {
    const { value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      equipmentClassId: value,
      equipmentTypeId: null,
    }));
  };

  const handleEquipmentLabelsChange = (event) => {
    let errors = [];
    const values = event.target.value;
    const lastItem = values[values.length - 1];
    if (lastItem) {
      values.pop();
      const sameItem = values.find((value) => value === lastItem);
      if (sameItem === undefined) {
        values.push(lastItem);
      }
    }

    const currentLength = values.join(', ').length;
    if (currentLength > 500) {
      errors.push('Cannot add item list length must be less than 500');
    }

    if (!errors.length > 0) {
      setValues((prevValues) => ({
        ...prevValues,
        equipmentLabels: values,
      }));
    }
  };

  const handleSubmit = () => {
    const errors = getEquipmentFieldErrors(values, { isEnableCreateWorkOrder });
    setFieldErrors(errors);
    if (errors) return;

    const payload = { eid: dataItem.eid, ...values };

    dispatch(updateEquipment(payload))
      .then((response) => {
        toastr.success('Sucessfully saved', 'Equipment have been successfully saved.', {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
        saveCallback(response);
      }).catch(() => {});
  };

  return (
    <form onSubmit={(evt) => evt.preventDefault()} className="box field-body--grow-4" name="editModal">
      <div className="panel-block is-wrapped filter no-border modal-header px-0">
        <h1 className="title mb-4">
          Edit {dataItem.equipmentName}
        </h1>
      </div>

      <div className="box modal-main edit-equipment-modal" style={{ minHeight: 120 }}>
        <TextInput
          label="*Equipment Name:"
          maxLength={maxEquipmentNameCharacters}
          name="equipmentName"
          value={values.equipmentName}
          onChange={onInputChange}
          errors={fieldErrors?.equipmentName && [fieldErrors.equipmentName]}
        />

        <LabelValue
          label="Organization Name:"
          value={dataItem.organizationName}
        />

        <LabelValue
          label="Building Name:"
          value={dataItem.buildingName}
        />

        <SelectInput
          label="*Equipment Class:"
          name="equipmentClassId"
          defaultOption="Select Equipment Class"
          options={equipmentClassOptions}
          value={values.equipmentClassId}
          onChange={handleEquipmentClassChange}
          warnings={values.equipmentClassId !== dataItem.equipmentClassId ? ['Warning: Changing the equipment class will result in the unreversible removal of all existing equipment variables not allowed on the new equipment class.'] : null}
          errors={fieldErrors?.equipmentClassId && [fieldErrors.equipmentClassId]}
        />

        <SelectInput
          label="*Equipment Type:"
          name="equipmentTypeId"
          defaultOption="Select Equipment Type"
          options={equipmentTypeOptions}
          value={values.equipmentTypeId}
          onChange={onInputChange}
          warnings={values.equipmentTypeId !== dataItem.equipmentTypeId ? ['Warning: Changing the equipment type will result in all point types which are not allowed on the new equipment type to be changed to Unassigned.'] : null}
          errors={fieldErrors?.equipmentTypeId && [fieldErrors.equipmentTypeId]}
        />

        <KendoMultiSelect
          info={[`Maximum length is 500 characters. Current length: ${values.equipmentLabels.join(', ').length}`]}
          value={values.equipmentLabels}
          allowCustom={true}
          autoClose={false}
          data={equipmentLabelsList.sort()}
          label={'Labels'}
          multiSelectClassName={classNames('dropdownMultiSelect border-0 kendo-multi-select', { hasValue: values.equipmentLabels.length > 0 })}
          popupSettings={{ className: 'multi-select-options' }}
          name={'equipmentLabels'}
          onChange={handleEquipmentLabelsChange} />

        <CheckboxInput
          label="*Active:"
          name="active"
          checked={values.active}
          onChange={onInputChange}
          errors={fieldErrors?.active && [fieldErrors.active]}
        />

        <CheckboxInput
          label="*Visibile:"
          name="visible"
          checked={values.visible}
          onChange={onInputChange}
          errors={fieldErrors?.visible && [fieldErrors.visible]}
        />

        <SelectInput
          label="*Configuration Status:"
          name="configurationStatusId"
          defaultOption="Select Configuration Status"
          options={equipmentConfigurationStatusOptions}
          value={values.configurationStatusId}
          onChange={onInputChange}
          errors={fieldErrors?.configurationStatusId && [fieldErrors.configurationStatusId]}
        />

        <TextArea
          label="Equipment Notes:"
          maxLength={maxEquipmentNotesCharacters}
          name="equipmentNotes"
          value={values.equipmentNotes}
          onChange={onInputChange}
          errors={fieldErrors?.equipmentNotes && [fieldErrors.equipmentNotes]}
        />

        {isEnableCreateWorkOrder && (
          <>
            <TextInput
              label="CMMS Reference ID:"
              maxLength={maxCmmsReferenceIdCharacters}
              name="cmmsReferenceId"
              value={values.cmmsReferenceId}
              onChange={onInputChange}
              errors={fieldErrors?.cmmsReferenceId && [fieldErrors.cmmsReference]}
            />

            <TextInput
              label="CMMS Location ID:"
              maxLength={maxCmmsLocationIdCharacters}
              name="cmmsLocationId"
              value={values.cmmsLocationId}
              onChange={onInputChange}
              errors={fieldErrors?.cmmsLocationId && [fieldErrors.cmmsLocationId]}
            />

            <TextInput
              label="CMMS Site ID:"
              maxLength={maxCmmsSiteIdCharacters}
              name="cmmsSiteId"
              value={values.cmmsSiteId}
              onChange={onInputChange}
              errors={fieldErrors?.cmmsSiteId && [fieldErrors.cmmsSiteId]}
            />

            <TextInput
              label="CMMS Link:"
              maxLength={maxCmmsLinkCharacters}
              name="cmmsLink"
              value={values.cmmsLink}
              onChange={onInputChange}
              errors={fieldErrors?.cmmsLink && [fieldErrors.cmmsLink]}
            />
          </>
        )}

        <EquipmentProfile
          showEquipmentInfo={false}
          unitid={dataItem.unitId}
          bid={dataItem.bid}
          eid={dataItem.eid}
          equipmentNotes={dataItem.equipmentNotes}
          configurationNotes={dataItem.configurationNotes}
        />
      </div>

      <div className="modal-footer d-flex justify-content-center">
        <div className="buttons">
          <button
            type="submit"
            className={classNames('button is-info is-medium', { 'is-loading': equipmentSaving })}
            disabled={equipmentSaving}
            onClick={handleSubmit}
          >
            Save
          </button>
          <button
            className="button is-info is-outlined is-medium"
            disabled={equipmentSaving}
            onClick={cancelCallback}
          >
            Close
          </button>
        </div>
      </div>
    </form>
  );
};

EditEquipmentModal.propTypes = {
  saveCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  dataItem: PropTypes.object.isRequired,
  culture: PropTypes.string.isRequired,
};

export default EditEquipmentModal;
