/* eslint-disable import/default */
import configureStore from './configureStore';
import * as loginActions from '../actions/loginActions';
import * as widgetActions from '../actions/widgetActions';
import * as dashboardActions from '../actions/dashboardActions';
import * as diagnosticsModuleActions from '../actions/diagnosticsModuleActions';
import * as pointModuleActions from '../actions/pointModuleActions';
import { getModuleConfig, getUserCurrencies } from '../actions/userActions';
import { getTaskLabels, getTaskStatuses, setDashboardFiltersLoaded, getEquipmentLabels } from '../actions/dashboardFilterActions';
import { getOrgTemplatesPermission, getManageOrgTemplatesPermissions } from '../actions/orgDashboardTemplatesActions';
import { getUnits  } from '../actions/buildingsFilterActions';
import * as emailTemplateActions from '../actions/emailTemplateAction';

const store = configureStore();

store.dispatch(loginActions.authenticate()).then(async () => {
  await store.dispatch(loginActions.getTheme());

  if (store.getState().user.isAuthenticated) {
    // necessary to load app
    await Promise.all([
      store.dispatch(loginActions.getUser()),
      store.dispatch(loginActions.getResources()),
      store.dispatch(getUnits()),
      store.dispatch(dashboardActions.getDashboards()),
      store.dispatch(dashboardActions.getBuildingPermission()),
      store.dispatch(dashboardActions.getEquipmentPermission()),
      store.dispatch(pointModuleActions.getPointPermission()),
    ]);

    // app loaded
    store.dispatch(loginActions.authUserAppInitialized());

    // get widgets info
    await Promise.all([
      store.dispatch(widgetActions.getWidgetCategories()),
      store.dispatch(widgetActions.getWidgetDatasets()),
      store.dispatch(widgetActions.getAnalysisTasks()),
      store.dispatch(widgetActions.getWidgetSummaries()),
    ]);

    // permissions
    await Promise.all([
      store.dispatch(dashboardActions.getDashboardTemplatePermissions()),
      store.dispatch(dashboardActions.getDashboardPermission()),
      store.dispatch(widgetActions.getWidgetTemplatesPermission()),
    ]);

    store.dispatch(getUserCurrencies());
    store.dispatch(emailTemplateActions.getEmailTemplates());

    store.dispatch(diagnosticsModuleActions.getEquipmentVariablesPermission());
    store.dispatch(diagnosticsModuleActions.getBuildingVariablesPermission());
    store.dispatch(diagnosticsModuleActions.getOrgPermission());

    // Task Status/Labels Filter
    await Promise.all([
      store.dispatch(getTaskStatuses()),
      store.dispatch(getTaskLabels()),
      store.dispatch(getEquipmentLabels()),
    ]);

    // module config
    await store.dispatch(getModuleConfig());

    // org dashboard template permissions
    store.dispatch(getManageOrgTemplatesPermissions());

    // get permission if user can save dashboard as org template
    store.dispatch(getOrgTemplatesPermission({ unitID: store.getState().user.unitId, isUserOrg: true }));

    store.dispatch(setDashboardFiltersLoaded());
  }
});

export default store;
