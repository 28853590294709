/* eslint-disable react/sort-comp */
/**
 *  * Created by Stewart Gordon on 6/8/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import omit from 'lodash/omit';
import { withFetching } from '../common/WithFetching/WithFetching';
import {AddDashboardWidgetContext} from './AddDashboardWidgetContext';
import Dashboard from './Dashboard';
import * as dashboardActions from '../../actions/dashboardActions';
import * as orgDashboardTemplatesActions from '../../actions/orgDashboardTemplatesActions';
import * as widgetActions from '../../actions/widgetActions';
import * as dashboardFilterActions from '../../actions/dashboardFilterActions';
import * as commonActions from '../../actions/commonActions';
import * as modalActions from '../../actions/modalActions';
import * as appliedFiltersActions from '../../actions/appliedFiltersActions';
import { getInitialStateFromProps, createElement, onAddItem, saveWidgetName } from './functions';
import { onBreakpointChange, onDragStop, onHighchartChange, onLayoutChange, onRemoveItem, onResizeStop  } from './functions/reactGridLayoutHandlers';
import { apiErrorHandler } from '../../api/apiErrorHandler';
import FilterContext from '../filters/FilterContext';
import { hasFilterValue } from '../../actionHelpers/commonHelpers';
import { filterIds, lookupFilterGroups } from '../../enums/filters';
import './styles.css';

/* eslint-disable prefer-rest-params */

class DashboardManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialStateFromProps(props);
    this.setDashboardRef = (element) => {
      this.dashboard = element;
    };
    this.setGridRef = (element) => {
      this.grid = element;
    };
    this.handleDeleteDashboard = this.handleDeleteDashboard.bind(this);
    this.createElement = createElement.bind(this);
    this.onBreakpointChange = onBreakpointChange.bind(this);
    this.onLayoutChange = onLayoutChange.bind(this);
    this.onResizeStop = onResizeStop.bind(this);
    this.onDragStop = onDragStop.bind(this);
    this.onAddItem = onAddItem.bind(this);
    this.onRemoveItem = onRemoveItem.bind(this);
    this.onHighchartChange = onHighchartChange.bind(this);
    this.saveWidgetName = saveWidgetName.bind(this);
  }

  componentDidMount() {
    this.props.actions.setExcludePageFilterIds([
      filterIds.isBuildingActive,
      filterIds.isEquipmentActive,
      filterIds.isPointActive,
      filterIds.currency,

      filterIds.resultGroup,
      filterIds.resultClass,
      filterIds.resultType,
      filterIds.resultSubType,
    ]);

    const { setApplyFilters, setSaveFilters } = this.context;
    setApplyFilters(this.handleRefreshDashboard);
    setSaveFilters(this.warnOrSaveDashboard);

    document.title = this.props.dashboard.userDashboardName;
    let breakpoint = '';
    if (this.dashboard.offsetWidth >= 1200) {
      breakpoint = 'lg';
    } else if (this.dashboard.offsetWidth >= 996) {
      breakpoint = 'md';
    } else {
      breakpoint = 'sm';
    }

    /* eslint-disable react/no-did-mount-set-state */
    this.setState({
      oldBreakpoint: breakpoint,
      currentBreakpoint: breakpoint,
      oldLayout: this.state.layouts[breakpoint],
      currentLayout: this.state.layouts[breakpoint],
      cols: this.props.cols[breakpoint],
    });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.dashboard.userDashboardName !== this.props.dashboard.userDashboardName) {
      document.title = this.props.dashboard.userDashboardName;
    }

    if (this.state.resizedItemIndex !== null) {
      this.setState((state) => ({ resizedItemIndex: null, items: state.items.slice(0, state.resizedItemIndex).concat(Object.assign({}, { ...state.items[state.resizedItemIndex] }, { reflow: !state.items[state.resizedItemIndex].reflow })).concat(state.items.slice(state.resizedItemIndex + 1)) }));
      return;
    }

    // when new dashboard/template id comes in, reset state values
    const { id } = this.props;
    if (id && id !== prevProps.id) {
      this.getInitialStateFromProps(this.props);
    }
  }

  componentWillUnmount() {
    this.props.actions.resetCurrentDashboard();
  }

  render() {
    const { saveStatus, cloneStatus, loadStatus, dashboard, permissions } = this.props;

    return (
      <AddDashboardWidgetContext.Provider value={this.onAddItem}>
        <Dashboard
          dashboardType={'dashboard'}
          generateLayout={this.createElement}
          saveStatus={saveStatus}
          cloneStatus={cloneStatus}
          loadStatus={loadStatus}
          onLayoutChange={this.onLayoutChange}
          saveFunc={this.warnOrSaveDashboard}
          saveAsOrgTemplate={this.saveAsOrgTemplate}
          deleteFunc={this.onClickDeleteIcon}
          addWidget={this.addWidget}
          onBreakpointChange={this.onBreakpointChange}
          items={this.state.items}
          dashboard={dashboard}
          checkForPrompt={this.checkForPrompt}
          setDashboardRef={this.setDashboardRef}
          showSettings={this.showSettings}
          onResizeStop={this.onResizeStop}
          onDragStop={this.onDragStop}
          layouts={this.state.layouts}
          setGridRef={this.setGridRef}
          onRefresh={this.handleRefreshDashboard}
          onClone={this.handleCloneDashboard}
          hasOrgDashboardTemplateCreatePermission={permissions.userOrgDashboardTemplates.c}
          hasDashboardCreatePermission={permissions.dashboard.c}
          hasDashboardUpdatePermission={permissions.dashboard.u}
          hasDashboardDeletePermission={permissions.dashboard.d}
        />
      </AddDashboardWidgetContext.Provider>
    );
  }


  onClickDeleteIcon = () => {
    const { actions } = this.props;
    // actions.showModal('TWO_BUTTON', { message: 'Do you wish to delete this dashboard? This cannot be undone.', yesLabel: 'Delete', noLabel: 'Cancel', yesCallback: () => this.handleDeleteDashboard(), noCallback: actions.hideModal });
    actions.showModal('DELETE', {
      close: actions.hideModal,
      stateProgress: 'ajaxCallsInProgress.dashboardSave',
      deleteFunc: this.handleDeleteDashboard,
      message: 'Do you wish to delete this dashboard? This cannot be undone.',
    });
  };

  addWidget = () => {
    const { actions } = this.props;
    actions.showModal('ADD_DASHBOARD_WIDGET', { modalContent: 'full scrollable', dashboardWidgets: this.state.items.map((widget) => parseInt(widget.i, 10)), addCallback: this.onAddItem, cancelCallback: actions.hideModal });
  };

  showSettings = () => {
    const { actions, dashboard } = this.props;
    actions.showModal('DASHBOARD_SETTINGS',
      { modalContent: 'full',
        dashboardName: dashboard.userDashboardName,
        dashboardDescription: dashboard.userDashboardDescription,
        dashboardType: 'dashboard',
        isDefault: dashboard.isDefault,
        title: 'Dashboard Settings',
        saveCallback: this.saveDashboardSettings,
        cancelCallback: actions.hideModal,
        saveButtonLabel: 'Save',
      });
  };

  checkForPrompt = () => {
    if (this.props.dashboard === undefined) {
      return false;
    }
    return this.hasDashboardChanged();
  };

  handleDeleteDashboard() {
    const { actions, dashboard } = this.props;
    actions.deleteDashboard(dashboard.udid).then(() => {
      actions.hideModal();
      actions.replace('/');
    }, (error) => actions.apiErrorHandler(error));
  }

  dataSavedToast = () => {
    toastr.success('Data Saved', 'Data saved.', {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  };

  saveDashboardSettings = ((settings) => {
    const { actions, dashboard } = this.props;
    return actions.saveDashboardSettings({ isDefault: settings.isDefault, userDashboardName: settings.dashboardName, userDashboardDescription: settings.dashboardDescription, udid: dashboard.udid })
      .then(() => {
        this.dataSavedToast();
        actions.hideModal();
      });
  }
  );

  saveDashboard = (dashboard) => {
    const { actions } = this.props;
    this.props.actions.saveDashboard(dashboard)
      .then(() => {
        this.setState({
          hasChanged: false,
          jsonFilters: dashboard.jsonFilters,
          layouts: dashboard.jsonLayout || this.state.layouts,
        });
        this.dataSavedToast();
      },
      (err) => actions.apiErrorHandler(err),
      );
  };

  hasDashboardChanged = () => this.state.hasChanged;

  pickJsonFiltersKeys(jsonFilters) {
    return jsonFilters.map(({ key }) => ({ key }));
  }

  pickJsonLayoutKeys(layoutItem) {
    return omit(layoutItem, ['wtid']);
  }

  widgetLevelFiltersHaveChanged() {
    const { dashboard } = this.props;
    if (
      dashboard.jsonWidgetSettings && dashboard.jsonWidgetSettings.widgets &&
      dashboard.templateJsonWidgetSettings && dashboard.templateJsonWidgetSettings.widgets
    ) {
      let changed = false;
      dashboard.jsonWidgetSettings.widgets.forEach((dashboardWidget) => {
        if (changed) {
          return true;
        }
        const templateWidget = dashboard.templateJsonWidgetSettings.widgets.find(
          (widget) => widget.id === dashboardWidget.id,
        );
        if (templateWidget) {
          const templateFilterKeys = templateWidget.jsonFilters.map((templateFilters) => templateFilters.key);
          if (
            dashboardWidget.jsonFilters.length !== templateFilterKeys.length ||
            !dashboardWidget.jsonFilters.every((filters) => templateFilterKeys.includes(filters.key))
          ) {
            changed = true;
          }
        }
      });
      return changed;
    }
    return false;
  }

  willDisconnectFromTemplate() {
    const dashboardToSave = {
      jsonLayout: {
        sm: this.state.layouts.sm.map(this.pickJsonLayoutKeys),
        md: this.state.layouts.md.map(this.pickJsonLayoutKeys),
        lg: this.state.layouts.lg.map(this.pickJsonLayoutKeys),
      },
    };

    const initialDashState = {
      jsonLayout: {
        sm: this.props.dashboard.jsonLayout.sm.map(this.pickJsonLayoutKeys),
        md: this.props.dashboard.jsonLayout.md.map(this.pickJsonLayoutKeys),
        lg: this.props.dashboard.jsonLayout.lg.map(this.pickJsonLayoutKeys),
      },
    };

    const areWidgetFiltersChanged = this.widgetLevelFiltersHaveChanged();

    return (
      JSON.stringify(initialDashState) !== JSON.stringify(dashboardToSave) ||
      areWidgetFiltersChanged
    );
  }

  /***
   * So the logic to remove the provider/org objects from the dashboard
   * is when a dashboard is saved where all of these objects are null
   * https://kgsbuildings.atlassian.net/browse/WEB-9621 AC#6
   */
  getNewJsonFilters = (dashboard) => {
    const { jsonFilters, jsonWidgetSettings } = dashboard;

    const buildingFilter = jsonFilters.find((e) => e.key === 'building');
    const buildingGroupFilter = jsonFilters.find((e) => e.key === 'buildingGroup');
    const equipmentFilter = jsonFilters.find((e) => e.key === 'equipment');

    let hasPointFilter = false;
    let hasDataSourceFilter = false;

    jsonWidgetSettings.widgets.forEach((widget) => {
      const pointFilter = widget.jsonFilters.find((e) => e.key === 'point');
      if (hasFilterValue(pointFilter)) hasPointFilter = true;

      const dataSourceFilter = widget.jsonFilters.find((e) => e.key === 'dataSource');
      if (hasFilterValue(dataSourceFilter)) hasDataSourceFilter = true;
    });

    if (
      !hasFilterValue(buildingFilter) &&
      !hasFilterValue(buildingGroupFilter) &&
      !hasFilterValue(equipmentFilter) &&
      !hasPointFilter &&
      !hasDataSourceFilter
    ) {
      return jsonFilters.filter(
        (e) => !lookupFilterGroups.globalGroup.includes(e.key),
      );
    }

    return jsonFilters;
  };

  warnOrSaveDashboard = ({ filtersOnly } = {}) => {
    // build dashboard object to pass to saveDashboard function
    const { actions, dashboard } = this.props;
    const dashboardChanged = filtersOnly ? false : this.willDisconnectFromTemplate();

    const newJsonFilters = this.getNewJsonFilters(dashboard);

    // setup the dashboard to be saved
    const dashboardToSave = filtersOnly ? {
      jsonFilters: newJsonFilters,
      udid: dashboard.udid,
    } : {
      jsonFilters: newJsonFilters,
      jsonLayout: this.state.layouts,
      jsonWidgetSettings: dashboard.jsonWidgetSettings,
      udid: dashboard.udid,
      dtid: dashboard.dtid,
      udtid: dashboard.udtid,
    };

    if (dashboardChanged && (dashboardToSave.dtid || dashboardToSave.udtid)) {
      dashboardToSave.dtid = null;
      dashboardToSave.udtid = null;
      // show a confirm, pass function to save as saveCallBack and modal actions hide modal as cancelCallback
      actions.showModal('TWO_BUTTON', { modalContent: 'full', yesLabel: 'Yes', noLabel: 'No', message: 'Saving changes to the dashboard will disconnect the dashboard from the template. Continue?', yesCallback: () => { this.saveDashboard(dashboardToSave); actions.hideModal(); }, noCallback: actions.hideModal });
    } else {
      this.saveDashboard(dashboardToSave);
    }
  };

  saveAsOrgTemplate = () => {
    const { actions, dashboard } = this.props;
    const name = dashboard.userDashboardName;
    const description = dashboard.userDashboardDescription;
    actions.showModal(
      'DASHBOARD_SAVE_AS_ORG',
      {
        modalContent: 'full',
        dashboardType: 'template',
        dashboardName: name,
        dashboardDescription: description,
        title: 'Save as Organization Template',
        saveCallback: this.saveDashboardTemplate,
        cancelCallback: actions.hideModal,
        saveButtonLabel: 'Ok',
      },
    );
  };

  saveDashboardTemplate = (data) => {
    const { actions, user, dashboard } = this.props;
    const orgTemplate = {
      unitid: user.unitId,
      unitDashboardTemplateName: data.dashboardName,
      unitDashboardTemplateDescription: data.dashboardDescription,
      jsonFilters: JSON.stringify(dashboard.jsonFilters),
      jsonLayout: JSON.stringify(this.state.layouts),
      jsonWidgetSettings: JSON.stringify(dashboard.jsonWidgetSettings),
    };
    return actions.createOrgTemplate(orgTemplate).then(() => {
      this.dataSavedToast();
      actions.hideModal();
    });
  };

  handleRefreshDashboard = async () => {
    await this.props.actions.updateCrossFilter(null);
    this.props.actions.clearDrillDown();
    this.props.actions.loadDashboard();
  };

  handleCloneDashboard = () => {
    // build dashboard object to pass to saveDashboard function
    const { dashboard } = this.props;
    const dashboardChanged = this.willDisconnectFromTemplate();
    const dashboardToCreate = {
      isDefault: false,
      jsonFilters: JSON.stringify(dashboard.jsonFilters),
      jsonLayout: JSON.stringify(this.state.layouts),
      jsonWidgetSettings: JSON.stringify(dashboard.jsonWidgetSettings),
      dtid: dashboard.dtid,
      udtid: dashboard.udtid,
      userDashboardName: `CLONE - ${dashboard.userDashboardName}`.slice(0, 50),
      userDashboardDescription: dashboard.userDashboardDescription,
    };

    if (dashboardChanged && (dashboardToCreate.dtid || dashboardToCreate.udtid)) {
      dashboardToCreate.dtid = null;
      dashboardToCreate.udtid = null;
    }

    this.setState({
      hasChanged: false,
    }, () => {
      this.props.actions.cloneDashboard(dashboardToCreate);
    });

  };
}

function getLoadStatus(state) {
  const { dashboardLoad } = state.ajaxCallsInProgress;
  const { widgetsLoading } = state.dataset;

  return dashboardLoad || Object.values(widgetsLoading).some(Boolean);
}

DashboardManager.propTypes = {
  id: PropTypes.number,
  actions: PropTypes.object.isRequired,
  // match: PropTypes.object.isRequired,
  dashboard: PropTypes.object,
  cols: PropTypes.object.isRequired,
  saveStatus: PropTypes.bool.isRequired,
  cloneStatus: PropTypes.bool.isRequired,
  loadStatus: PropTypes.bool.isRequired,
  widgets: PropTypes.array,
  permissions: PropTypes.object,
  user: PropTypes.object,
};

DashboardManager.defaultProps = {
  cols: { lg: 6, md: 4, sm: 1 },
};

DashboardManager.contextType = FilterContext;

function MapStateToProps(state) {
  return {
    dashboard: state.currentDashboard,
    saveStatus: state.ajaxCallsInProgress.dashboardSave,
    cloneStatus: state.ajaxCallsInProgress.dashboardClone,
    widgets: state.adminWidgets.widgetsSummary,
    permissions: state.permissions,
    user: state.user,
    loadStatus: getLoadStatus(state),
  };
}

function MapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...dashboardActions, ...widgetActions, ...dashboardFilterActions, ...orgDashboardTemplatesActions, ...commonActions, ...appliedFiltersActions, replace, apiErrorHandler }, dispatch),
  };
}

const ConnectedDashboard = connect(MapStateToProps, MapDispatchToProps)(DashboardManager);
const ConnectedDashboardWithFetching = withFetching(ConnectedDashboard, 'currentDashboard', 'ajaxCallsInProgress.dashboard', 'getDashboard');
export default ConnectedDashboardWithFetching;

