import React from 'react';
import PropTypes from 'prop-types';
import PriorityFilter from '../dashboardFilters/filterComponents/PriorityFilter';
import CostFilter from '../dashboardFilters/filterComponents/CostFilter';
import DateRangeFilter from '../dashboardFilters/filterComponents/DateRangeFilter';
import EquipmentFilter from '../dashboardFilters/filterComponents/EquipmentFilter';
import TaskStatusFilter from '../dashboardFilters/filterComponents/TaskStatusFilter';
import LabelsFilter from '../dashboardFilters/filterComponents/LabelsFilter';
import TaskAssigneeFilter from '../dashboardFilters/filterComponents/TaskAssigneeFilter';
import TaskReporterFilter from '../dashboardFilters/filterComponents/TaskReporterFilter';
import DiagnosticAnalysisIntervalFilter from '../dashboardFilters/filterComponents/DiagnosticAnalysisIntervalFilter';
import AnalysisFilter from '../dashboardFilters/filterComponents/AnalysisFilter';
import CurrencyFilter from '../dashboardFilters/filterComponents/CurrencyFilter';
import DataSourceFilterFilter from '../dashboardFilters/filterComponents/DataSourceFilter';
import DataDateRangeFilter from '../dashboardFilters/filterComponents/DataDateRangeFilter';
import BuildingFilter from '../dashboardFilters/filterComponents/BuildingFilter';
import PointFilter from '../dashboardFilters/filterComponents/PointFilter';
import ThreeStateFilter from '../dashboardFilters/filterComponents/ThreeStateFilter';
import GlobalFilter from '../dashboardFilters/filterComponents/GlobalFilter';
import AggregationIntervalFilter from '../dashboardFilters/filterComponents/AggregationIntervalFilter';
import ConfigurationStatusFilter from '../dashboardFilters/filterComponents/ConfigurationStatusFilter';
import ResultFilter from '../dashboardFilters/filterComponents/ResultFilter';

const componentMapping = {
  Priority: PriorityFilter,
  Cost: CostFilter,
  DateRange: DateRangeFilter,
  Equipment: EquipmentFilter,
  TaskStatus: TaskStatusFilter,
  Labels: LabelsFilter,
  TaskAssignee: TaskAssigneeFilter,
  TaskReporter: TaskReporterFilter,
  DiagnosticAnalysisInterval: DiagnosticAnalysisIntervalFilter,
  Analysis: AnalysisFilter,
  Currency: CurrencyFilter,
  DataSource: DataSourceFilterFilter,
  RawdatasetDate: DataDateRangeFilter,
  AggregationInterval: AggregationIntervalFilter,
  Point: PointFilter,
  Building: BuildingFilter,
  ThreeState: ThreeStateFilter,
  Global: GlobalFilter,
  ConfigurationStatus: ConfigurationStatusFilter,
  Result: ResultFilter,
};

function FilterGroupListItem(props) {
  const { type } = props;
  const ItemComponent = componentMapping[type];

  return ItemComponent && <ItemComponent {...props} />;
}

FilterGroupListItem.propTypes = {
  type: PropTypes.string.isRequired,
};

export default FilterGroupListItem;
