/* eslint-disable react/prop-types */
import React, { useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import FilterManager from '../filters/FilterManager';
import { setExcludePageFilterIds } from '../../actions/appliedFiltersActions';
import FilterContext from '../filters/FilterContext';
import { filterGroups, filterIds } from '../../enums/filters';
import { calculatingFiltersSelector } from '../../selectors/calculatingFilters';

const WidgetFilterModal = ({ datasetIDs, isCalculatingFilters, widgetFiltersLoaded, cancelCallback, saveCallback, saving, currentWidget, dispatch }) => {
  const { setApplyFilters, setSaveFilters } = useContext(FilterContext);

  const handleRefresh = useCallback(() => {}, []);
  const handleSave = useCallback(() => {}, []);

  useEffect(() => {
    dispatch(setExcludePageFilterIds([
      filterIds.isBuildingActive,
      filterIds.isEquipmentActive,
      filterIds.isPointActive,

      filterIds.resultGroup,
      filterIds.resultClass,
      filterIds.resultType,
      filterIds.resultSubType,
    ]));
  }, []);
  
  useEffect(() => {
    setApplyFilters(handleRefresh);
    setSaveFilters(handleSave);
  }, [handleRefresh, handleSave]);

    
  return (
    <div className="WidgetLevelFilters box">
      <span className="title widget-filter-title"><i className="flaticon-filter" aria-hidden="true"></i><span>Widget Filters</span></span>

      <div id="WidgetFilterModal" className="widget-filter-box">
        <FilterManager usePopup hideActionButtons datasetIDs={datasetIDs}  />
      </div>

      <div style={{ display: 'flex', justifyContent: 'right', marginTop: '5px', paddingRight: '5px' }}>
        <div className="buttons">
          <button
            disabled={saving || isCalculatingFilters || !widgetFiltersLoaded}
            className={classNames('button is-info', saving && 'is-loading')}
            onClick={handleOk}
          >
            Apply
          </button>
          <button
            disabled={saving || isCalculatingFilters || !widgetFiltersLoaded}
            className={'button is-info is-outlined'}
            onClick={cancelCallback}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  function handleOk() {
    const jsonFilters = currentWidget.jsonFilters.filter((e) => !filterGroups.buildingGroup.includes(e.key));
    const saveCurrentWidget = { ...currentWidget, jsonFilters };
    saveCallback(saveCurrentWidget);
  }
};

function mapStateToProps(state) {
  const isCalculatingFilters = calculatingFiltersSelector(state.calculatingFilters);

  return {
    isCalculatingFilters,
    saving: state.ajaxCallsInProgress.userSave,
    currentWidget: state.currentWidget,
    widgetFiltersLoaded: state.dashboardFilters.widgetFiltersLoaded,
  };
}

WidgetFilterModal.propTypes = {
  isCalculatingFilters: PropTypes.bool.isRequired,
  widgetFiltersLoaded: PropTypes.bool.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  currentWidget: PropTypes.object,
  datasetIDs: PropTypes.arrayOf(PropTypes.number),
};

export default connect(mapStateToProps)(WidgetFilterModal);
