import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import EquipmentInfo from './EquipmentInfo/EquipmentInfo';
import EquipmentVariables from './EquipmentVariables/EquipmentVariables';
import DataPoints from './DataPoints/DataPoints';
import BuildingVariables from './BuildingVariables/BuildingVariables';
import AssociatedEquipment from './AssociatedEquipment';
import AssociatedEquipmentVariables from './AssociatedEquipmentVariables';
import AssociatedEquipmentPoints from './AssociatedEquipmentPoints';
import EquipmentNotes from './EquipmentNotes';
import ConfigurationNotes from './ConfigurationNotes';
import ConfigurationCharts from './ConfigurationCharts';
import { userResources } from '../../../enums/resources';
import { clearDiagnosticEquipmentProfile } from '../../../actions/diagnosticsModuleActions';

const EquipmentProfile = ({ equipmentNotes, configurationNotes, unitid, bid, eid, showEquipmentInfo = true, readOnly }) => {
  const params = { unitid, bid, eid, equipmentNotes, configurationNotes, readOnly };
  const dispatch = useDispatch();
  const resources = useSelector((state) => state.user.resources);
  const hasOnboardingReadPermission = resources.includes(userResources.Onboarding);

  useEffect(() => () => {
    dispatch(clearDiagnosticEquipmentProfile());
  }, []);

  return (
    <div className='equipment-profile-container columns is-multiline is-variable is-4 m-0 p-6'>
      {showEquipmentInfo && (
        <section className='column is-12 pt-0'>
          <EquipmentInfo {...params} />
        </section>
      )}

      {hasOnboardingReadPermission && (<>
        <section className='column is-12'>
          <ConfigurationNotes {...params} />
        </section>

        <section className='column is-12'>
          <ConfigurationCharts eid={params.eid} />
        </section>
      </>)
      }

      <section className='column is-12'>
        <EquipmentNotes {...params} />
      </section>

      <section className='column is-12'>
        <EquipmentVariables {...params} />
      </section>

      <section className='column is-12'>
        <DataPoints {...params} />
      </section>

      <section className='column is-12'>
        <BuildingVariables {...params} />
      </section>

      <section className='column is-12'>
        <AssociatedEquipment {...params} />
      </section>

      <section className='column is-12'>
        <AssociatedEquipmentVariables {...params} />
      </section>

      <section className='column is-12'>
        <AssociatedEquipmentPoints {...params} />
      </section>
    </div>
  );
};

EquipmentProfile.propTypes = {
  readOnly: PropTypes.bool,
  showEquipmentInfo: PropTypes.bool,
  unitid: PropTypes.number.isRequired,
  bid: PropTypes.number.isRequired,
  eid: PropTypes.number.isRequired,
  equipmentNotes: PropTypes.string,
  configurationNotes: PropTypes.string,
};

export default EquipmentProfile;
