/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { formatDate } from '@telerik/kendo-intl';
import TextArea from '../common/TextArea';
import SelectInput from '../common/SelectInput';
import LabelValue from '../common/LabelValue';
import { hideModal, updateModal } from '../../actions/modalActions';
import {
  createTask,
  getReportersAndAssignees,
  getTaskPermissions,
} from '../../actions/taskModuleActions';
import { mergeArrayById } from '../../utils';
import {
  getFilteredValueOrLength,
  getAllAvailableOrAppliedFilters,
  getDiagnosticIntervalAndDate,
  getOrganization,
  getFlattenedEquipmentAnalysis,
} from '../diagnostics/utils';
import { getBuildingSettings } from '../../actions/buildingSettingsActions';


function getSelectAllFilteredValues(equipments, buildings, analyses, equipmentAnalysisAll) {
  // Rename 'name' property to 'equipmentName' for all equipments
  const updatedAllEquipmentsNameProperty = equipments.map(({ name, ...allEquipment }) => ({
    ...allEquipment,
    equipmentName: name,
  }));

  // Get equipment with analyses
  const mergedEquipmentAndAnalyses = mergeArrayById(getFlattenedEquipmentAnalysis(equipmentAnalysisAll, analyses), updatedAllEquipmentsNameProperty, 'equipmentId', 'id');

  // Get buildings with equipments and anayses
  return mergeArrayById(mergedEquipmentAndAnalyses, buildings, 'buildingId', 'bid');
}

const CreateTaskModal = ({
  assigneesAndReporters,
  assigneesAndReportersLoading,
  taskStatuses,
  buildingSettings,
  saving,
  dispatch,
  cancelCallback,
  selectedDiagnostics,
  equipmentAll: allEquipments,
  jsonFiltersWithDefault,
  isAllSelected,
  appliedQueryParams,
  equipmentAnalysisAll,
  dashboardFilters,
  client,
}) => {
  const selectedClient = useMemo(() => jsonFiltersWithDefault.find((item) => item.key === 'client'), []);
  const unitId = selectedClient.value[0];
  const selectedOrganization = getOrganization(client, unitId);
  const { buildingName, equipmentName, equipmentAnalysisName } = getDiagnosticFieldNameOrCount();

  const [state, setState] = useState({
    org: unitId,
    assignee: '',
    status: '',
    summary: '',
    description: '',
    recommendations: '',
    actions: '',
    hasNotification: false,
    hasGenerateWorkOrder: false,
    error: '',
    diagnosticOptions: [],
    isDiagnosticOptionsExpanded: false,
  });

  const {
    org,
    assignee,
    status,
    summary,
    description,
    recommendations,
    actions,
    hasNotification,
    hasGenerateWorkOrder,
    error,
    diagnosticOptions,
    isDiagnosticOptionsExpanded,
  } = state;
  const equipmentsIds = diagnosticOptions.map((diagnosticOption) => diagnosticOption.equipmentId);
  const isReadytoSubmit = org && diagnosticOptions.length && assignee && status && summary;

  const canCreateWorkOrderEquipmentList = useMemo(
    () =>
      allEquipments.filter(
        (equipment) => {
          const buildingSetting = buildingSettings.find((s) => s.bid === equipment.buildingId);
          return (
            equipmentsIds.some((e) => e === equipment.id) &&
            (
              (buildingSetting?.isEnableCreateWorkOrder && buildingSetting?.equipmentCmmsRefIdRequired && equipment.cmmsReferenceId) ||
              (buildingSetting?.isEnableCreateWorkOrder && !buildingSetting?.equipmentCmmsRefIdRequired)
            )
          );
        },
      ),
    [equipmentsIds, allEquipments, buildingSettings],
  );
  const canCreateWorkOrder = canCreateWorkOrderEquipmentList.length > 0;

  const appliedAssignees = assigneesAndReporters;

  const assigneeOptions = useMemo(
    () =>
      Array.from(new Set(appliedAssignees)).map((e) => ({
        value: e.uid,
        text: e.email,
      })),
    [appliedAssignees],
  );

  const appliedStatuses = taskStatuses;

  const statusOptions = useMemo(
    () =>
      Array.from(new Set(appliedStatuses)).map((e) => ({
        value: e.statusId,
        text: e.statusName,
      })),
    [appliedStatuses],
  );

  const checkOrgCreateTaskAccess = useCallback(async (unitId) => {
    try {
      const permissions = await dispatch(getTaskPermissions(unitId));
      if (!permissions.c) showCreateTaskError();
    } catch (error) {
      showCreateTaskError();
    }
  }, []);

  const hideTaskModal = () => dispatch(hideModal());

  const showCreateTaskError = useCallback(() => {
    dispatch(
      updateModal('NOTIFICATION', {
        type: 'error',
        title: 'Error',
        message:
          'Your account has not been configured for task creation for this organization. Please contact your system administrator to review your account access.',
        yesLabel: 'OK',
        yesCallback: hideTaskModal,
      }),
    );
  }, [dispatch]);

  function getDiagnosticFieldNameOrCount() {
    let buildingNameOrCount;
    let equipmentNameOrCount;
    const equipmentAnalysisNameOrCount = `(${getDataToCreateTask().length}) Equipment / Analysis Selected`;

    if (isAllSelected) {
      const { equipments: filteredEquipments, buildings } = getAllAvailableOrAppliedFilters(dashboardFilters, appliedQueryParams);
      buildingNameOrCount = getFilteredValueOrLength(buildings, 'buildingName', 'Buildings', true);
      equipmentNameOrCount = getFilteredValueOrLength(filteredEquipments, 'name', 'Equipment', true);
    } else {
      buildingNameOrCount = getFilteredValueOrLength(getDataToCreateTask(), 'buildingName', 'Buildings');
      equipmentNameOrCount = getFilteredValueOrLength(getDataToCreateTask(), 'equipmentName', 'Equipment');
    }

    return { buildingName: buildingNameOrCount, equipmentName: equipmentNameOrCount, equipmentAnalysisName: equipmentAnalysisNameOrCount };
  }

  function getDataToCreateTask() {
    if (isAllSelected) {
      const { equipments: filteredEquipments, buildings, analyses } = getAllAvailableOrAppliedFilters(dashboardFilters, appliedQueryParams);
      return getSelectAllFilteredValues(filteredEquipments, buildings, analyses, equipmentAnalysisAll);
    } else {
      return selectedDiagnostics;
    }
  }

  function getPermission(diagnosticsOptions) {
    const buildingIds = diagnosticsOptions.map((diagnosticOption) => diagnosticOption.bid || diagnosticOption.buildingId);
    let uniqueBuildingIds = Array.from(new Set(buildingIds));
    checkOrgCreateTaskAccess(org);
    dispatch(getReportersAndAssignees(org, uniqueBuildingIds));
    dispatch(getBuildingSettings(uniqueBuildingIds));
  }

  useEffect(() => {
    const buildingsWithEquipment = mergeArrayById(getDataToCreateTask(), allEquipments, 'equipmentId', 'id');
    const { analysisInterval, diagnosticDate } = getDiagnosticIntervalAndDate(jsonFiltersWithDefault);

    const updatedDiagnosticOptions = buildingsWithEquipment.map((diagnostic) => ({
      ...diagnostic,
      interval: analysisInterval || 'Daily',
      date: diagnosticDate,
    }));

    getPermission(updatedDiagnosticOptions);
    setState((prevState) => ({ ...prevState, diagnosticOptions: updatedDiagnosticOptions }));
  }, []);

  useEffect(() => {
    if (assignee && assigneeOptions.length && !assigneeOptions.some((s) => String(s.value) === String(assignee))) {
      setState((prevState) => ({ ...prevState, assignee: '' }));
    }
  }, [assignee, assigneeOptions]);

  const getAnalysisEquipment = () =>
    diagnosticOptions.length === 0
      ? []
      : diagnosticOptions.map((option) => ({
        EID: Number(option.equipmentId),
        AID: Number(option.analysisId),
        AnalysisInterval: option.interval,
        CMMSReferenceID: option.cmmsReferenceId,
        DiagnosticDate: formatDate(option.date, 'yyyy-MM-dd'),
      }));

  const handleCreateTask = async () => {
    const analysisEquipment = getAnalysisEquipment();
    const payload = {
      Meta: {
        HasNotification: hasNotification,
      },
      AnalysisEquipment: analysisEquipment,
      Resource: {
        OrganizationID: Number(org),
        AssignedUID: Number(assignee),
        StatusID: Number(status),
        Summary: summary,
        Description: description,
        Recommendations: recommendations,
        Actions: actions,
        HasGenerateWorkOrder: hasGenerateWorkOrder,
      },
    };

    await dispatch(createTask(payload)).then( () => {
      toastr.success('Successfully created', 'Task(s) have been successfully created.', {
        removeOnHover: true,
        removeOnHoverTimeOut: 1000,
      });
      hideTaskModal();}).catch(() => {
      // handled in creaetTask action
    });
  };

  const handleChangeInput = (setter) => (event) => {
    const { value, checked } = event.target;
    const newValue = typeof checked === 'boolean' ? checked : value;
    setState((prevState) => ({ ...prevState, [setter]: newValue }));
  };

  const handleExpandDiagnosticOptions = () => {
    setState((prevState) => ({ ...prevState, isDiagnosticOptionsExpanded: !isDiagnosticOptionsExpanded }));
  };

  const taskCount = diagnosticOptions.length;
  const workOrderCount = canCreateWorkOrderEquipmentList.length;

  return (
    <form
      data-testid="CreateTaskModal"
      onSubmit={(evt) => evt.preventDefault()}
      className="box field-body--no-padding modal-box"
      name="createTaskModal"
    >
      <div className="modal-header mb-4" style={{ paddingLeft: '0', paddingRight: '0' }}>
        <h1 className="title">Create Task</h1>
      </div>

      {error && (
        <div>
          <h6 style={{ marginBottom: '10px' }} className="is-6 subtitle has-text-danger">
            {error}
          </h6>
        </div>
      )}

      <div className="box modal-main create-task-body-container">
        <LabelValue
          label={'Organization'}
          title={'Organization'}
          value={<div className='input-disable'>{selectedOrganization.organizationName}</div>}
        />

        <LabelValue
          label={'Buildings'}
          title={'Buildings'}
          value={<div className='input-disable'>{buildingName}</div>}
        />

        <LabelValue
          label={'Equipments'}
          title={'Equipments'}
          value={<div className='input-disable'>{equipmentName}</div>}
        />

        <LabelValue
          label={'Equipment / Analysis'}
          title={'Equipment / Analysis Pairs '}
          value={<div className='input-disable'>{equipmentAnalysisName}</div>}
        />

        {!assigneesAndReportersLoading ?
          <SelectInput
            label={'*Assignee'}
            isLoading={assigneesAndReportersLoading}
            options={assigneeOptions}
            defaultOption={'Select Assignee'}
            name={'assignee'}
            value={assignee}
            onChange={handleChangeInput('assignee')}
          /> :
          <LabelValue
            label={'*Assignee'}
            value={
              <div className='input-disable'>
                {assigneesAndReportersLoading ?
                  <i className="bulma-loader-mixin" aria-hidden="true"></i> :
                  <span>Please select at least 1 Building</span>
                }
              </div>
            }
          />
        }

        <SelectInput
          label={'*Status'}
          options={statusOptions}
          defaultOption={'Select Status'}
          name={'status'}
          value={status}
          onChange={handleChangeInput('status')}
        />

        <TextArea
          label={'*Summary'}
          maxLength={250}
          rows={1}
          name={'summary'}
          value={summary}
          onChange={handleChangeInput('summary')}
        />

        <TextArea
          label={'Description'}
          infoText={'Describe the issue'}
          maxLength={5000}
          rows={2}
          name={'description'}
          value={description}
          onChange={handleChangeInput('description')}
        />

        <TextArea
          label={'Recommendations'}
          infoText={'What should be done?'}
          maxLength={5000}
          rows={2}
          name={'recommendations'}
          value={recommendations}
          onChange={handleChangeInput('recommendations')}
        />

        <TextArea
          label={'Actions'}
          infoText={'What has been done?'}
          maxLength={5000}
          rows={2}
          name={'actions'}
          value={actions}
          onChange={handleChangeInput('actions')}
        />


        <div className="diagnostic-options-expander-area">
          <span className="diagnostic-options-expander" onClick={handleExpandDiagnosticOptions}>
            {!isDiagnosticOptionsExpanded ? 'Expand to view associated analyses' : 'Collapse analysis details'}
            <i className={!isDiagnosticOptionsExpanded ? 'flaticon-down-arrow' : 'flaticon-up-arrow'} aria-hidden="true" />
          </span>
        </div>

        {isDiagnosticOptionsExpanded && (
          <>
            <div className="diagnostic-options-area columns">
              <div className="diagnostic-options-header column">
                <span>Equipment</span>
              </div>

              <div className="diagnostic-options-header column">
                <span>CMMS ID</span>
              </div>

              <div className="diagnostic-options-header column">
                <span>Analysis</span>
              </div>

              <div className="diagnostic-options-header column">
                <span>Interval</span>
              </div>

              <div className="diagnostic-options-header column">
                <span>Date</span>
              </div>
            </div>

            {diagnosticOptions?.map((diagnosticOption, index) => (
              <div key={index} className="diagnostic-options-area columns">
                <div className="diagnostic-options-equipment column">
                  <span>{diagnosticOption.equipmentName}</span>
                </div>

                <div className="column">
                  <span>{diagnosticOption.cmmsReferenceId}</span>
                </div>

                <div className="column">
                  <span>{diagnosticOption.analysisName}</span>
                </div>

                <div className="column">
                  <span>{diagnosticOption.interval}</span>
                </div>

                <div className="diagnostic-options-datepicker column">
                  <span>{formatDate(diagnosticOption.date)}</span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      <div className={'modal-footer is-flex create-task-footer-container'}>
        <div className="create-task-footer-email-container">
          <div className="field is-horizontal">
            <div className="field-body">
              <input
                id="hasNotification"
                aria-label="hasNotification"
                aria-checked={hasNotification}
                type="checkbox"
                checked={hasNotification}
                className="checkbox"
                onChange={handleChangeInput('hasNotification')}
              />
            </div>
            <div className="field-label">
              <label className="label cursor-pointer" htmlFor="hasNotification">Send Email</label>
            </div>
          </div>
          {canCreateWorkOrder && (
            <div className="field is-horizontal">
              <div className="field-body">
                <input
                  id="hasGenerateWorkOrder"
                  aria-label="hasGenerateWorkOrder"
                  aria-checked={hasGenerateWorkOrder}
                  type="checkbox"
                  checked={hasGenerateWorkOrder}
                  className="checkbox"
                  onChange={handleChangeInput('hasGenerateWorkOrder')}
                />
              </div>
              <div className="field-label">
                <label className="label cursor-pointer" htmlFor="hasGenerateWorkOrder">
                  {workOrderCount > 1 ? `Create (${workOrderCount}) Work Orders` : 'Create Work Order'}
                </label>
              </div>
            </div>
          )}
        </div>

        <div className="buttons">
          <button
            data-testid="close"
            className={'button is-info is-outlined is-medium'}
            onClick={cancelCallback}
          >
            Cancel
          </button>
          <button
            type={'submit'}
            data-testid="submit"
            className={`button is-info is-medium ${isReadytoSubmit ? '' : 'is-outlined '} ${saving ? 'is-loading' : ''}`}
            disabled={assigneesAndReportersLoading || !isReadytoSubmit}
            onClick={handleCreateTask}
          >
            {`Create (${taskCount}) Task${taskCount > 1 ? 's' : ''}`}
          </button>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  dashboardFilters: state.dashboardFilters,
  saving: state.taskModule.creating,
  currentWidget: state.currentWidget,
  buildings: state.buildings,
  equipmentAll: state.dashboardFilters.equipment,
  clientAll: state.dashboardFilters.clientAll,
  assigneesAndReporters: state.taskModule.assigneesAndReporters || [],
  assigneesAndReportersLoading: state.taskModule.assigneesAndReportersLoading,
  equipmentAnalysisAll: state.dashboardFilters.equipmentAnalysisAll,
  taskStatuses: state.taskStatuses,
  analyses: state.dashboardFilters.analysisAll,
  lastCreated: state.taskModule.lastCreated,
  buildingSettings: state.buildingSettings,
  client: state.dashboardFilters.client,
});

CreateTaskModal.propTypes = {
  cancelCallback: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  currentWidget: PropTypes.object,
  assigneesAndReporters: PropTypes.array.isRequired,
  taskStatuses: PropTypes.array.isRequired,
  lastCreated: PropTypes.object,
  selectedDiagnostics: PropTypes.array.isRequired,
  equipmentAll: PropTypes.array.isRequired,
  jsonFiltersWithDefault: PropTypes.array.isRequired,
  dashboardFilters: PropTypes.object,
  appliedQueryParams: PropTypes.object,
  isAllSelected: PropTypes.bool,
  client: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(CreateTaskModal);
