import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { addOrUpdateFilter } from '../actionHelpers/commonHelpers';
import modules from '../enums/modules';
import { filterFields } from '../enums/filters';

export default function diagnosticsModuleReducer(state = initialState.diagnosticsModule, action) {
  switch (action.type) {
    case types.ADD_OR_UPDATE_JSON_FILTERS:
      if (action.currentFilterField !== filterFields.diagnosticsModule) return state;
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: addOrUpdateFilter(state.config.jsonFilters, action.jsonFilter, action.shouldReplaceAll, action.isPartialUpdate),
        },
      };

    case types.REMOVE_JSON_FILTERS:
      if (action.currentFilterField !== filterFields.diagnosticsModule) return state;
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: state.config.jsonFilters.filter((eachFilter) => eachFilter.key !== action.key),
        },
      };

    case types.UPDATE_DIAGNOSTICS_MODULE_CONFIG_COLUMNS:
      return {
        ...state,
        config: {
          ...state.config,
          jsonLayout: {
            ...state.config.jsonLayout,
            columns: action.payload,
          },
        },
      };

    case types.UPDATE_DIAGNOSTICS_MODULE_CONFIG_TOP_FILTER:
      return {
        ...state,
        config: {
          ...state.config,
          jsonLayout: {
            ...state.config.jsonLayout,
            topBy: action.payload.topBy,
            topValue: action.payload.topValue,
          },
        },
      };

    case types.UPDATE_DIAGNOSTICS_MODULE_GRID_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          jsonLayout: {
            ...state.config.jsonLayout,
            grid: action.payload,
          },
        },
      };

    case types.GET_MODULE_CONFIG_SUCCESS: {
      const moduleConfig = action.payload.find((e) => e.moduleId === modules.userModules.Diagnostics);
      if (moduleConfig) {
        const initialJsonFilters = moduleConfig.jsonFilters.length
          ? moduleConfig.jsonFilters
          : initialState.diagnosticsModule.config.jsonFilters;
        return {
          ...state,
          config: {
            jsonFiltersFromAPILoaded: true,
            jsonLayout: moduleConfig.jsonLayout,
            jsonFilters: initialJsonFilters,
            jsonFiltersFromAPI: initialJsonFilters,
          },
        };
      }
      return state;
    }

    case types.UPDATE_DIAGNOSTICS_MODULE_CONFIG_SUCCESS: {
      return {
        ...state,
        config: {
          ...state.config,
          jsonFiltersFromAPI: JSON.parse(action.payload.update.jsonFilters),
        },
      };
    }

    case types.RESET_DIAGNOSTICS_MODULE_JSON_FILTERS: {
      return {
        ...state,
        config: {
          ...state.config,
          jsonFilters: state.config.jsonFiltersFromAPI,
        },
      };
    }

    case types.SET_DIAGNOSTICS_MODULE_CONFIG_SAVING:
      return {
        ...state,
        configSaving: action.payload,
      };

      // GET DIAGNOSTICS LIST
    case types.GET_DIAGNOSTICS_MODULE_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };

    case types.CLEAR_DIAGNOSTICS_MODULE:
      return {
        ...state,
        list: [],
      };

    case types.INITIALIZE_DIAGNOSTIC_DETAILS:
      return {
        ...state,
        currentDiagnosticDetail: {},
        currentDiagnosticDetailLoaded: false,
        currentDiagnosticDetailLoading: false,
      };

    case types.BEGIN_DIAGNOSTIC_DETAILS_CALL:
      return {
        ...state,
        currentDiagnosticDetail: {},
        currentDiagnosticDetailLoading: true,
      };

    case types.GET_DIAGNOSTIC_DETAILS_SUCCESS:
      return {
        ...state,
        currentDiagnosticDetail: action.payload,
        currentDiagnosticDetailLoaded: true,
        currentDiagnosticDetailLoading: false,
      };

    case types.DIAGNOSTIC_DETAILS_CALL_ERROR:
      return {
        ...state,
        currentDiagnosticDetailLoading: false,
      };

    case types.CLEAR_DIAGNOSTIC_EQUIPMENT_PROFILE:
      return {
        ...state,
        equipmentProfile: initialState.diagnosticsModule.equipmentProfile,
      };

    case types.GET_DIAGNOSTIC_REPORT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipmentProfile: {
          ...state.equipmentProfile,
          data: action.payload,
        },
      };

    case types.GET_DIAGNOSTIC_REPORT_CONFIGURATION_CHARTS_LOADING:
      return {
        ...state,
        configurationChartsLoading: action.payload,
      };

    case types.GET_DIAGNOSTIC_REPORT_CONFIGURATION_CHARTS_SUCCESS:
      return {
        ...state,
        equipmentProfile: {
          ...state.equipmentProfile,
          configurationCharts: action.payload,
        },
      };

    case types.GET_DIAGNOSTIC_REPORT_EQUIPMENT_VARIABLES_SUCCESS:
      return {
        ...state,
        equipmentProfile: {
          ...state.equipmentProfile,
          equipmentVariables: action.payload,
        },
      };

    case types.GET_DIAGNOSTIC_REPORT_EQUIPMENT_POINTS_SUCCESS:
      return {
        ...state,
        equipmentProfile: {
          ...state.equipmentProfile,
          equipmentPoints: action.payload,
        },
      };

    case types.GET_DIAGNOSTIC_REPORT_EQUIPMENT_BUILDING_VARIABLES_SUCCESS:
      return {
        ...state,
        equipmentProfile: {
          ...state.equipmentProfile,
          buildingVariables: action.payload,
        },
      };

    case types.GET_DIAGNOSTIC_REPORT_ASSOCIATED_EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipmentProfile: {
          ...state.equipmentProfile,
          associatedEquipment: action.payload,
        },
      };

    case types.GET_DIAGNOSTIC_REPORT_ASSOCIATED_EQUIPMENT_VARIABLES_SUCCESS:
      return {
        ...state,
        equipmentProfile: {
          ...state.equipmentProfile,
          associatedEquipmentVariables: action.payload,
        },
      };

    case types.GET_DIAGNOSTIC_REPORT_ASSOCIATED_EQUIPMENT_POINTS_SUCCESS:
      return {
        ...state,
        equipmentProfile: {
          ...state.equipmentProfile,
          associatedEquipmentPoints: action.payload,
        },
      };

    case types.DELETE_EQUIPMENT_VARIABLES_VALUE_SUCCESS:
      return {
        ...state,
        equipmentProfile: {
          ...state.equipmentProfile,
          equipmentVariables: state.equipmentProfile.equipmentVariables.filter((e) => e.evid !== action.payload.evid),
        },
      };

    case types.GET_EQUIPMENT_VARIABLES_LIST_SUCCESS:
      return {
        ...state,
        equipmentVariablesList: action.payload,
      };
    case types.GET_EQUIPMENT_VARIABLES_LIST_LOADING:
      return {
        ...state,
        equipmentVariablesListLoading: action.payload,
      };

    case types.UPDATE_EQUIPMENT_VARIABLES_VALUE_LOADING:
    case types.DELETE_EQUIPMENT_VARIABLES_VALUE_LOADING:
      return {
        ...state,
        equipmentProfile: {
          ...state.equipmentProfile,
          equipmentVariablesValueLoading: action.payload,
        },
      };

    case types.GET_BUILDING_VARIABLES_LIST_SUCCESS:
      return {
        ...state,
        buildingVariablesList: action.payload,
      };
    case types.GET_BUILDING_VARIABLES_LIST_LOADING:
      return {
        ...state,
        buildingVariablesListLoading: action.payload,
      };

    case types.UPDATE_BUILDING_VARIABLES_VALUE_LOADING:
    case types.DELETE_BUILDING_VARIABLES_VALUE_LOADING:
      return {
        ...state,
        equipmentProfile: {
          ...state.equipmentProfile,
          buildingVariablesValueLoading: action.payload,
        },
      };

    case types.GET_POINT_TYPE_VIEW_SUCCESS:
      return {
        ...state,
        pointTypeViewList: action.payload,
      };
    case types.GET_POINT_TYPE_VIEW_LOADING:
      return {
        ...state,
        pointTypeViewListLoading: action.payload,
      };

      // Bulk Edit Equipment Details
    case types.BULK_EDIT_EQUIPMENT_DETAILS_SUCCESS:
      return {
        ...state,
        bulkEditEquipmentDetails: {
          ...state.bulkEditEquipmentDetails,
          lastUpdated: action.payload,
        },
      };
    case types.BULK_EDIT_EQUIPMENT_DETAILS_SAVING:
      return {
        ...state,
        bulkEditEquipmentDetails: {
          ...state.bulkEditEquipmentDetails,
          saving: action.payload,
        },
      };

    default:
      return state;
  }
}
