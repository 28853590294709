/**
 *  * Created by Stewart Gordon on 9/5/2017.
 */
// import 'whatwg-fetch';
import camelcaseKeys from 'camelcase-keys';
// import { getBaseUrl, getAuthUrl } from './baseUrl';
import { getAuthUrl } from './baseUrl';
import { generateUniqueId, getCookieByName, toQueryString } from '../utils';
import modules from '../enums/modules';
// const baseUrl = getBaseUrl();
const authUrl = getAuthUrl();

function isSingle(json) {
  let jsonToReturn = json;
  if (Array.isArray(json)) {
    jsonToReturn = json[0] || {};
  }
  return jsonToReturn;
}

function getSessionParams() {
  return {
    timezoneOffset: new Date().getTimezoneOffset() / -60,
    kgsIdpForceLogOutOnLogOff: getCookieByName('iDpLogout') || false,
  };
}

function getSessionQueryString() {
  const session = getSessionParams();
  return `timezoneOffset=${session.timezoneOffset}&kgsIdpForceLogOutOnLogOff=${session.kgsIdpForceLogOutOnLogOff}`;
}

function handle401Response(e, url) {
  if (url.includes('/api/Login')) {
    return Promise.resolve(e.json.Data);
  }
  const redirectUrl = e.json.Data[0].url || '/login';
  window.location = redirectUrl;
  return null;
}

function handleResponse(response, options = { isBulk: false }) {
  return response
    .json()
    .then((json) => {
      if (response.ok) {
        try {
          const jsonToReturn = camelcaseKeys(json, { deep: true });
          return jsonToReturn;
        } catch (error) {
          return json;
        }
      }
      return Promise.reject(
        Object.assign(
          {},
          { json },
          {
            status: response.status,
            statusText: response.statusText,
          },
        ),
      );
    })
    .then(((res) => options.isBulk ? res : res.data))
    .catch((e) => {
      const isValidEmail = Boolean(e.json.Data);

      if (e.status === 401 && isValidEmail) {
        return handle401Response(e, response.url);
      }

      const error = {
        status: e.status,
        statusText: e.statusText,
        json: e.json.Errors[0],
      };
      throw error;
    });
}

/* function get(url, messageProps, currLoc = document.location) {
  const commonMessage = {
    mode: 'cors',
    credentials: 'include',
    headers: new Headers({
      Accept: 'text/plain',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Location: currLoc,
    }),
  };
  const message = Object.assign({}, commonMessage, messageProps);
  return fetch(baseUrl + url, message).then(onSuccess, onError);
}*/

function deleteResource(url, currLoc = document.location, params) {
  const stringifiedBody = params ? JSON.stringify(params) : undefined;
  const message = {
    mode: 'cors',
    method: 'DELETE',
    credentials: 'include',
    headers: new Headers({
      Accept: 'text/plain',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Location: currLoc,
    }),
    body: stringifiedBody,
  };
  return fetch(authUrl + url, message)
    .then((response) => handleResponse(response))
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function bulkDeleteResource(url, currLoc = document.location, params) {
  const stringifiedBody = params ? JSON.stringify(params) : undefined;
  const message = {
    mode: 'cors',
    method: 'DELETE',
    credentials: 'include',
    headers: new Headers({
      Accept: 'text/plain',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Location: currLoc,
    }),
    body: stringifiedBody,
  };

  return fetch(authUrl + url, message)
    .then((response) => handleResponse(response, { isBulk: true }))
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function getAuth(url, messageProps, currLoc = document.location) {
  const commonMessage = {
    mode: 'cors',
    credentials: 'include',
    headers: new Headers({
      Accept: 'text/plain',
      'Content-Type': 'application/json',
      Location: currLoc,
    }),
  };
  const message = Object.assign({}, commonMessage, messageProps);
  return fetch(authUrl + url, message)
    .then((response) => handleResponse(response))
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

/* function put(url, body, currLoc = document.location) {
  const commonMessage = {
    method: 'PUT',
    headers: new Headers({
      Accept: 'text/plain',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Location: currLoc,
    }),
  };
  const message = Object.assign({}, commonMessage, body);
  return fetch(baseUrl + url, message)
    .then((response) => handleResponse(response))
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}*/

/* function post(url, body, currLoc = document.location) {
  const commonMessage = {
    method: 'POST',
    headers: new Headers({
      Accept: 'text/plain',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Location: currLoc,
    }),
  };
  const message = Object.assign({}, commonMessage, body);
  return fetch(baseUrl + url, message)
    .then((response) => handleResponse(response))
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}*/

function patchAuth(url, resource, wrapResource = true, isBulk = false, currLoc = document.location) {
  let body = { body: JSON.stringify({ resource }) };
  if (!wrapResource) {
    body = { body: JSON.stringify(resource) };
  }
  const commonMessage = {
    mode: 'cors',
    credentials: 'include',
    method: 'PATCH',
    headers: new Headers({
      Accept: 'text/plain',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Location: currLoc,
    }),
  };

  const message = Object.assign({}, commonMessage, body);
  return fetch(authUrl + url, message)
    .then((response) => handleResponse(response, { isBulk }))
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function postAuth(url, body, currLoc = document.location) {
  const commonMessage = {
    mode: 'cors',
    credentials: 'include',
    method: 'POST',
    headers: new Headers({
      Accept: 'text/plain',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Location: currLoc,
    }),
  };

  const message = Object.assign({}, commonMessage, body);
  return fetch(authUrl + url, message)
    .then((response) => handleResponse(response))
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function putAuth(url, body, currLoc = document.location) {
  const resourceWrapped = { body: JSON.stringify(body) };
  const commonMessage = {
    mode: 'cors',
    credentials: 'include',
    method: 'PUT',
    headers: new Headers({
      Accept: 'text/plain',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Location: currLoc,
    }),
  };

  const message = Object.assign({}, commonMessage, resourceWrapped);
  return fetch(authUrl + url, message)
    .then((response) => handleResponse(response))
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function postMultipart(url, body, currLoc = document.location) {
  const commonMessage = {
    mode: 'cors',
    credentials: 'include',
    method: 'PUT',
    headers: new Headers({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Location: currLoc,
    }),
  };

  const message = Object.assign({}, commonMessage, { body });
  return fetch(authUrl + url, message)
    .then((response) => handleResponse(response))
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

export function openLearnUpon() {
  return getAuth('/learnupon').then(isSingle);
}

export function getWidgetDatasets() {
  return getAuth('/datasets', {});
}

export function getAnalysisTasks() {
  return getAuth('/analyses', {});
}

export function getTheme() {
  return getAuth('/themes').then(isSingle);
}

export function deleteWidget(id) {
  return deleteResource(`/widgets/${id}`);
}

export function editWidget(widget, id) {
  // return put(`widgets/${widget.id}`, body);
  return patchAuth(`/widgets/${id}`, widget).then(isSingle);
}

export function editUser(user) {
  return patchAuth('/user', user).then(isSingle);
}
export function getContact(unitId) {
  return getAuth(`/contacts?UnitID=${unitId}`).then(isSingle);
}
export function getDiagnosticSupportRequest(unitId, buildingId) {
  return getAuth(`/diagnosticsupportrequest?UnitID=${unitId}${buildingId ? `&BID=${buildingId}` : ''}`).then(isSingle);
}
export function addContact(contact, isDiagnostics) {
  const formData = new FormData();
  for (const name in contact) {
    if (name === 'attachment') {
      for (let i = 0; i < contact[name].length; i++) {
        formData.append(`attachment${i}`, contact[name][i]);
      }
    } else if (name === 'queryString' && !isDiagnostics) {
      formData.append('queryString', `${contact[name]}&${getSessionQueryString()}`);
    } else {
      formData.append(name, contact[name]);
    }
  }
  const url = isDiagnostics ? '/diagnosticsupportrequest' : '/contacts';
  return postMultipart(url, formData).then(isSingle);
}

export function addWidget(widget) {
  return putAuth('/widgets', { resource: widget }).then(isSingle);
}

export function getWidgetDetails(id) {
  return getAuth(`/widgets/${id}`, {}).then(isSingle);
}

export function getWidgetSummaries() {
  return getAuth('/widgets');
}

export function getWidgetTypes() {
  return getAuth('/widgetTypes');
}

export function getWidgetCategories() {
  return getAuth('/widgetCategories', {});
}

/**
 * Dashboard Api
 */

export function getDashboardTemplate(id) {
  return getAuth(`/dashboardTemplates/${id}`, {}).then(isSingle);
}

export function getDashboardTemplates() {
  return getAuth('/dashboardTemplates', {});
}

export function getDashboard(id) {
  return getAuth(`/dashboards/${id}`, {}).then(isSingle);
}

export function getDashboards() {
  return getAuth('/dashboards', {});
}

// Email Templates
export function getEmailTemplates() {
  return getAuth('/emailtemplate', {});
}

export function getEmailTemplateAssignedUsers(EmailTemplateID) {
  return getAuth(`/assignedUsers/emailtemplate?EmailTemplateID=${EmailTemplateID}`).then(isSingle);
}


export function assignEmailTemplatesToUsers(params) {
  return putAuth('/assignedusers/emailtemplate', params);
}

export function createDashboard(dashboard) {
  return putAuth('/dashboards', { resource: dashboard }).then(isSingle);
}

export function saveDashboard(dashboard, id) {
  return patchAuth(`/dashboards/${id}`, dashboard);
}

export function deleteDashboardById(id) {
  return deleteResource(`/dashboards/${id}`);
}

export function getStatuses() {
  return getAuth('/status', {});
}

export function getBuildingSettings(bids) {
  const body = {
    body: JSON.stringify({ BID: bids }),
  };
  return postAuth('/buildingsettings', body);
}

/* export function getDashboardNames() {
  return get('dashboardNames', {});
} */

export function getResources() {
  return getAuth('/resources');
}

export function getUser() {
  return getAuth('/user').then(isSingle);
}
// BUILDINGS FILTERS

export function getBuildings(unitIds) {
  return getAuth(`/buildings?UnitID=${unitIds}`);
}

export function getBuildingClasses() {
  return getAuth('/buildingclasses');
}

export function getBuildingTypes() {
  return getAuth('/buildingtypes');
}

export function getUnitBuildingGroups(unitIds) {
  return getAuth(`/buildinggroups?UnitID=${unitIds}`);
}

export function getBuildingGroupBuildings(unitIds) {
  return getAuth(`/buildinggroupbuildings?UnitID=${unitIds}`);
}

export function getUnitDeploymentGroups(unitIds) {
  return getAuth(`/deploymentgroups?UnitID=${unitIds}`);
}

export function getOrgsByPids(pids) {
  const body = {
    body: JSON.stringify({ unitId: pids }),
  };
  return postAuth('/unitsProvidedForByProviders', body);
}

export function getUnits() {
  return getAuth('/units');
}

// SESSION

export function renew() {
  return getAuth('/renew');
}

export function LoggedIn() {
  return getAuth('/LoggedIn', {}).then(isSingle);
}

/* export function Logout() {
  return getAuth('/api/Logout', { mode: 'cors', credentials: 'include' });
} */

export function Logout(iDpLogout) {
  const body = {
    body: JSON.stringify({
      iDpLogout,
      urlOnLogout: `${window.location.protocol}//${window.location.host}`,
    }),
  };
  return postAuth('/Logout', body).then(isSingle);
}

export function Login(username, redirectUrl, impersonateduid = null) {
  const urlOnAuthenticated = `${window.location.protocol}//${window.location.host}${redirectUrl}`;
  const body = {
    body: JSON.stringify({
      userName: username,
    }),
  };
  const commonMessage = {
    mode: 'cors',
    credentials: 'include',
    method: 'POST',
    headers: new Headers({
      Accept: 'text/plain',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Location: urlOnAuthenticated,
      ImpersonatedUID: impersonateduid,
    }),
  };
  const message = Object.assign({}, commonMessage, body);
  return fetch(`${authUrl}/Login`, message)
    .then((response) => handleResponse(response))
    .then((json) => json)
    .catch((error) => {
      throw error;
    }).then(isSingle);
}

// Template
export function createTemplate(template) {
  return putAuth('/dashboardtemplates', { resource: template }).then(isSingle);
}

export function saveDashboardTemplate(dashboardTemplate, id) {
  return patchAuth(`/dashboardtemplates/${id}`, dashboardTemplate);
}

/**
 *
 * @param {number} id
 */
export function deleteTemplateById(id) {
  return deleteResource(`/dashboardtemplates/${id}`);
}

export function getDashboardTemplatePermissions() {
  return getAuth('/permissions/dashboardtemplates').then(isSingle);
}

export function getEquipmentClasses() {
  return getAuth('/equipmentclasses');
}

export function getEquipmentTypes() {
  return getAuth('/equipmenttypes');
}

export function getEquipments(params) {
  const body = {
    body: JSON.stringify(params),
  };
  return postAuth('/equipment', body);
}

export function getEquipmentConfigurationStatuses() {
  return getAuth('/configurationstatus', {});
}

export function getPointClasses() {
  return getAuth('/pointClasses');
}

export function getPointTypes() {
  return getAuth('/pointTypes');
}

export function getResultGroups() {
  return getAuth('/resultgroups');
}

export function getResultClasses() {
  return getAuth('/resultclasses');
}

export function getResultTypes() {
  return getAuth('/resulttypes');
}

export function getResultSubTypes() {
  return getAuth('/resultsubtype');
}

export function getPoints(params) {
  const body = {
    body: JSON.stringify(params),
  };
  return postAuth('/points', body);
}

export function getVPoints(params) {
  const body = {
    body: JSON.stringify(params),
  };
  return postAuth('/vpoints', body);
}

export function getTaskAssignees(unitIds) {
  return getAuth(`/taskassignees?UnitID=${unitIds}`);
}

export function getTaskReporters(unitIds) {
  return getAuth(`/taskreporters?UnitID=${unitIds}`);
}

export function getWidgetData(dataset, queryString) {
  const finalQString = `${queryString}&${getSessionQueryString()}`;
  return getAuth(`/${dataset}${finalQString}`);
}

export function getAdxQuery(dataset, queryString) {
  const finalQString = `${queryString}&${getSessionQueryString()}`;
  return getAuth(`/${dataset}/export${finalQString}`);
}


export function putWidgetParams(url, params) {
  const updatedParams = { ...params, ...getSessionParams() };
  return putAuth(`/${url}`, updatedParams).then(isSingle);
}

export function getEquipmentAnalyses(params) {
  const body = {
    body: JSON.stringify(params),
  };
  return postAuth('/analysesequipment', body)
    .then((list) => list.map(({ eid, aiDs: aids }) => ({ eid, aids })));
}

export function getAnalyses(params) {
  const body = {
    body: JSON.stringify(params),
  };
  return postAuth('/analyses', body);
}

// Task Module
export function createTask(params) {
  return putAuth('/Taskrecords', params).then(isSingle);
}

export function editTaskDetails(bid, id, payload) {
  return patchAuth(`/Taskrecords/${id}?BID=${bid}`, payload, false).then(isSingle);
}

export function getTaskModule(queryString) {
  return getAuth(`/Taskrecords${queryString}&${getSessionQueryString()}`);
}

export function getTaskDetails(id) {
  return getAuth(`/Taskrecords?&taskID=${id}&${getSessionQueryString()}`).then(isSingle);
}

export function getTaskPermissions(unitId) {
  return getAuth(`/permissions/taskrecords?unitId=${unitId}`).then(isSingle);
}

export function getTaskReportersAndAssignees(unitID, bID) {
  return getAuth(`/authorizedusers/taskrecords?unitid=${unitID}&bid=${bID}`);
}

export function putTaskModuleParams(params) {
  return putAuth('/TasksRequestParamsLookup', params).then(isSingle);
}

export function deleteTaskModule({ id, bid }) {
  return deleteResource(`/taskrecords/${id}?bid=${bid}`).then(isSingle);
}

export function bulkDeleteTaskModule(params) {
  return bulkDeleteResource('/taskrecords', document.location, params);
}

export function bulkEditTaskModule(params) {
  return patchAuth('/taskrecords', params, false, true, document.location);
}

export function updateTaskModuleConfig(params) {
  const taskModuleId = modules.userModules.Tasks;
  return patchAuth(`/usermodules/${taskModuleId}`, params);
}

// Diagnostics Module
export function getDiagnosticsModule(queryString) {
  return getAuth(`/aggregateddiagnostics${queryString}&${getSessionQueryString()}`);
}

export function putDiagnosticsModuleParams(params) {
  return putAuth('/DiagnosticsRequestParamsLookup', params).then(isSingle);
}

export function updateDiagnosticsModuleConfig(params) {
  const diagnosticsModuleId = modules.userModules.Diagnostics;
  return patchAuth(`/usermodules/${diagnosticsModuleId}`, params);
}

export function getDiagnosticDetails({ unitid, bid, eid, aid, startDate, analysisRange, lastModifiedDate }) {
  return getAuth(`/diagnosticreport?unitID=${unitid}&BID=${bid}&EID=${eid}&AID=${aid}&StartDate=${startDate}&AnalysisRange=${analysisRange}&LastModifiedDate=${lastModifiedDate}`, {}).then(isSingle);
}

export function getDiagnosticReportEquipment({ bid, eid }) {
  return getAuth(`/diagnosticreportequipment?BID=${bid}&EID=${eid}`, {}).then(isSingle);
}

export function getDiagnosticReportEquipmentVariables({ bid, eid }) {
  return getAuth(`/diagnosticreportequipmentvariable?BID=${bid}&EID=${eid}`, {});
}

export function getConfigurationCharts(eid) {
  return getAuth(`/equipmentprofileconfigcharts?EquipmentID=${eid}`, {});
}

export function getDiagnosticReportEquipmentPoints({ bid, eid, refreshDate }) {
  return getAuth(`/diagnosticreportequipmentpoints?BID=${bid}&EID=${eid}&refreshDate=${refreshDate}`, {});
}

export function getDiagnosticReportEquipmentBuildingVariables({ bid }) {
  return getAuth(`/buildingvariablesvalue?BID=${bid}`, {});
}
export function getBuildingVariablesClasses () {
  return getAuth('/variableclasses');
}

export function getDiagnosticReportAssociatedEquipment({ bid, eid }) {
  return getAuth(`/diagnosticreportassociatedequipment?BID=${bid}&EID=${eid}`, {});
}

export function getDiagnosticReportAssociatedEquipmentVariables({ bid, eid }) {
  return getAuth(`/diagnosticreportassociatedequipmentvariables?BID=${bid}&EID=${eid}`, {});
}

export function getDiagnosticReportAssociatedEquipmentPoints({ bid, eid }) {
  return getAuth(`/diagnosticreportassociatedequipmentpoints?BID=${bid}&EID=${eid}`, {});
}

// Diagnostics Preview Module
export function getDiagnosticsPreviewModule(queryString) {
  return getAuth(`/aggregateddiagnosticsviaresults${queryString}&${getSessionQueryString()}`);
}

export function getDiagnosticsPreviewDetail(queryString) {
  return getAuth(`/aggregatedresultsfordiagnostic${queryString}&${getSessionQueryString()}`);
}

export function updateDiagnosticsPreviewModuleConfig(params) {
  const diagnosticsPreviewModuleId = modules.userModules.DiagnosticsPreview;
  return patchAuth(`/usermodules/${diagnosticsPreviewModuleId}`, params);
}

export function putDiagnosticsPreviewModuleParams(params) {
  return putAuth('/DiagnosticsRequestParamsLookup', params).then(isSingle);
}

// Reprocess Diagnostics
export function reprocessDiagnostics(params) {
  return putAuth('/reprocessdiagnostics', params);
}

// Equipment Dataset
export function getEquipmentDataset({ BID, EID }) {
  const uniqueId = generateUniqueId();
  return getAuth(`/EquipmentDataset?BID=${BID}&EID=${EID}&refreshID=${uniqueId}`);
}

export function editEquipmentFlags(payload) {
  const body = {
    body: JSON.stringify(payload),
  };
  return postAuth('/equipmentflag', body);
}

// Map
export function getMapTokenClientId() {
  return getAuth('/Map').then(isSingle);
}

export function getUserCurrencies() {
  return getAuth('/currencies');
}


// Module
export function getModuleConfig() {
  const ids = modules.getModuleIds().toString();
  return getAuth(`/usermodules?moduleID=${ids}`);
}

// Assign Dashboard Template
export function getAuthorizedUsers(unitIds) {
  return getAuth(`/authorizedUsers/units?unitID=${unitIds}`);
}

export function assignDashboardTemplate(resource) {
  return putAuth('/dashboards', resource).then(isSingle);
}

// Un-Assign Dashboard Template
export function unAssignDashboardTemplate(resource) {
  return deleteResource('/dashboards', document.location, resource).then(isSingle);
}

// Org Templates
export function getOrgTemplatesList({ unitID }) {
  return getAuth(`/unitdashboardtemplates?unitID=${unitID}`);
}

export function createOrgTemplate(resource) {
  return putAuth('/unitdashboardtemplates', { resource }).then(isSingle);
}

export function getOrgTemplatesOrganizations() {
  return getAuth('/authorizedunits/unitdashboardtemplates');
}

export function getOrgDashboardTemplate({ id }) {
  return getAuth(`/unitDashboardTemplates/${id}`, {}).then(isSingle);
}

export function saveOrgDashboardTemplate(dashboardTemplate, id) {
  return patchAuth(`/unitdashboardtemplates/${id}`, dashboardTemplate);
}

export function getOrgTemplatesPermission({ unitID }) {
  return getAuth(`/permissions/unitdashboardtemplates?unitID=${unitID}`).then(isSingle);
}

export function deleteOrgTemplateById(id) {
  return deleteResource(`/unitdashboardtemplates/${id}`);
}

export function getOrgTemplateAssignedUsers({ unitId, udtid }) {
  return getAuth(`/assignedUsers/unitDashboardTemplates?unitId=${unitId}&udtid=${udtid}`).then(isSingle);
}

export function assignUnassignOrgTemplateToUsers(params) {
  return putAuth('/assignedUsers/unitdashboardtemplates', params);
}

export function bulkDeleteOrgTemplates(params) {
  return bulkDeleteResource('/unitDashboardTemplates', document.location, params);
}

export function bulkDeleteGlobalTemplates(params) {
  return bulkDeleteResource('/dashboardTemplates', document.location, params);
}

export function getTemplateAssignedUsers({ unitId, dtid }) {
  return getAuth(`/assignedUsers/dashboardtemplates?unitId=${unitId}&dtid=${dtid}`).then(isSingle);
}

export function assignUnassignTemplateToUsers(params) {
  return putAuth('/assignedUsers/dashboardtemplates', params);
}

export function copyOrgTemplatesToOrgs(params) {
  return putAuth('/unitDashboardTemplates', params);
}

export function getOrgTemplatesOrganizationsWithCreatePermission() {
  return getAuth('/authorizedunits/unitdashboardtemplates?perm=c');
}

export function getDataSources(params) {
  const body = {
    body: JSON.stringify(params),
  };
  return postAuth('/datasources', body);
}

export function getDashboardPermission() {
  return getAuth('/permissions/dashboards').then(isSingle);
}

export function getBuildingPermission() {
  return getAuth('/permissions/buildings').then(isSingle);
}

export function getBuildingByIdPermission(bid) {
  return getAuth(`/permissions/buildings/${bid}`).then(isSingle);
}

export function getEquipmentPermission(unitId) {
  return getAuth(`/permissions/equipment${unitId ? `?UnitID=${unitId}` : ''}`).then(isSingle);
}

export function getEquipmentVariablesPermission(unitId) {
  return getAuth(`/permissions/equipmentvariables${unitId ? `?UnitID=${unitId}` : ''}`).then(isSingle);
}

export function getOrgPermission() {
  return getAuth('/permissions/organizations').then(isSingle);
}

export function getPointPermission(unitId) {
  return getAuth(`/permissions/points${unitId ? `?UnitID=${unitId}` : ''}`).then(isSingle);
}

export function getWidgetTemplatesPermission() {
  return getAuth('/permissions/widgettemplates').then(isSingle);
}

export function getEquipmentVariablesList(params) {
  const queryString = toQueryString(params);
  return getAuth(`/equipmentvariables?${queryString}`);
}

export function createEquipmentVariablesValue(payload) {
  return putAuth('/equipmentvariablesvalue', payload).then(isSingle);
}

export function updateEquipmentVariablesValue(payload) {
  return patchAuth('/equipmentvariablesvalue', payload, false).then(isSingle);
}

export function deleteEquipmentVariablesValue(payload) {
  return deleteResource('/equipmentvariablesvalue', undefined, payload);
}

export function getBuildingVariablesPermission(unitId) {
  return getAuth(`/permissions/buildingvariablesvalue${unitId ? `?UnitID=${unitId}` : ''}`).then(isSingle);
}

export function getBuildingVariablesList(params) {
  const queryString = toQueryString(params);
  return getAuth(`/buildingvariable/unassigned?${queryString}`);
}

export function createBuildingVariablesValue(payload) {
  return putAuth('/BuildingVariablesvalue', payload).then(isSingle);
}

export function updateBuildingVariablesValue(payload) {
  return patchAuth('/BuildingVariablesvalue', payload, false).then(isSingle);
}

export function deleteBuildingVariablesValue(payload) {
  return deleteResource('/BuildingVariablesvalue', undefined, payload);
}

// Assign / Add Building Variables
export function bulkUpdateBuildingVariables(params) {
  return patchAuth('/bulkbuildingbvarsupdate', params, false);
}

// Unassign Building Variables
export function bulkDeleteBuildingVariables(params) {
  return deleteResource('/bulkbuildingbvarsunassign', document.location, params);
}

export function updateEquipmentProfile(payload) {
  return patchAuth('/equipmentdescriptor', payload, false).then(isSingle);
}

export function getEquipmentVariablesListByEquipmentClassId(params) {
  return getAuth(`/EquipmentEvarsByClass?EquipmentClassID=${params.equipmentClassId}`);
}

export function getEquipmentVariablesPreviewList(params) {
  const queryString = toQueryString(params);
  return getAuth(`/bulkequipmentevarsview?${queryString}`);
}

// Assign / Add Equipment Variables
export function bulkUpdateEquipmentVariables(params) {
  return patchAuth('/bulkequipmentevarsupdate', params, false);
}

// Unassign Equipment Variables
export function bulkDeleteEquipmentVariables(params) {
  return deleteResource('/bulkequipmentevarsunassign', document.location, params);
}

// Home Module
export function getHomeModule(queryString) {
  return getAuth(`/aggregateddiagnostics${queryString}&${getSessionQueryString()}`);
}

export function putHomeModuleParams(params) {
  return putAuth('/HomeRequestParamsLookup', params).then(isSingle);
}

export function updateHomeModuleConfig(params) {
  const diagnosticsModuleId = modules.userModules.Home;
  return patchAuth(`/usermodules/${diagnosticsModuleId}`, params);
}

export function getPointTypeView(params) {
  return getAuth(`/pointtypesview?bid=${params.bid}&eid=${params.eid}`);
}

export function updatePoint(payload) {
  return patchAuth('/points', payload, false).then(isSingle);
}

export function getDeploymentGroups(unitIds) {
  return getAuth(`/deploymentgroups?UnitID=${unitIds}`);
}

export function createDeploymentGroups(params) {
  return putAuth('/deploymentgroups', params).then(isSingle);
}

export function updateDeploymentGroups(params) {
  return patchAuth('/deploymentgroups', params, false).then(isSingle);
}

export function deleteDeploymentGroups(resource) {
  return deleteResource('/deploymentgroups', document.location, resource);
}

export function getScheduledAnalysesPermissions(unitId) {
  return getAuth(`/permissions/scheduledanalyses?unitId=${unitId}`).then(isSingle);
}

// Building Module
export function getBuildingModule(queryString) {
  return getAuth(`/BuildingAdminDataset${queryString}&${getSessionQueryString()}`);
}

export function putBuildingModuleParams(params) {
  return putAuth('/BuildingsRequestParamsLookup', params).then(isSingle);
}

export function updateBuildingModuleConfig(params) {
  const buildingModuleId = modules.userModules.Building;
  return patchAuth(`/usermodules/${buildingModuleId}`, params);
}

export function updateBuilding(payload) {
  return patchAuth('/buildingadmindataset', payload).then(isSingle);
}

// Lookups
export function getCountryLookup() {
  return getAuth('/lookup/country');
}

export function getCountryStateLookup(countryName) {
  return getAuth(`/lookup/state?countryName=${countryName}`);
}

export function getTimeZoneLookup() {
  return getAuth('/lookup/timezone');
}

export function getCultureLookup() {
  return getAuth('/lookup/culture');
}

// Equipment Module
export function getEquipmentModule(queryString) {
  return getAuth(`/equipmentdescriptor${queryString}&${getSessionQueryString()}`);
}

export function putEquipmentModuleParams(params) {
  return putAuth('/EquipmentsRequestParamsLookup', params).then(isSingle);
}

export function updateEquipmentModuleConfig(params) {
  const equipmentModuleId = modules.userModules.Equipment;
  return patchAuth(`/usermodules/${equipmentModuleId}`, params);
}

// Point Module
export function getPointModule(queryString) {
  return getAuth(`/PointsAdminDataset${queryString}&${getSessionQueryString()}`);
}

export function putPointModuleParams(params) {
  return putAuth('/PointsRequestParamsLookup', params).then(isSingle);
}

export function updatePointModuleConfig(params) {
  const pointModuleId = modules.userModules.Point;
  return patchAuth(`/usermodules/${pointModuleId}`, params);
}

export function bulkEditEquipmentDetails(params) {
  return patchAuth('/bulkequipmentupdate', params, false);
}

export function getAllBuildingVariables() {
  return getAuth('/BuildingVariable');
}

export function getBuildingVariableView(params) {
  return getAuth(`/BuildingVariableView?BID=${params.bids}&BVID=${params.bvid}`);
}

export function getTaskLabels() {
  return getAuth('/tasktags');
}

export function getEquipmentLabels() {
  return getAuth('/equipmenttags');
}
