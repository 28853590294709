const columns = [
  {
    'title': 'Building',
    'field': 'buildingName',
    'width': 12.5,
    'show': true,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 1,
  },
  {
    'title': 'Address',
    'field': 'address',
    'width': 12.5,
    'show': true,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 2,
  },
  {
    'title': 'City',
    'field': 'city',
    'width': 12.5,
    'show': true,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 3,
  },
  {
    'title': 'State',
    'field': 'stateName',
    'width': 12.5,
    'show': true,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 4,
  },
  {
    'title': 'Country',
    'field': 'countryName',
    'width': 12.5,
    'show': true,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 5,
  },
  {
    'title': 'Building Type',
    'field': 'buildingTypeName',
    'width': 12.5,
    'show': true,
    'filter': 'text',
    'orderIndex': 6,
  },
  {
    'title': 'Area',
    'field': 'area',
    'width': 9,
    'show': true,
    'filter': 'numeric',
    'headerClassName': 'has-text-centered',
    'className': 'has-text-right noWeglot',
    'render': 'numberWithUnits',
    'renderOptions': {
      'unitField': 'areaUnitLabel',
    },
    'orderIndex': 7,
  },
  {
    'title': 'Active',
    'field': 'isActive',
    'width': 8,
    'show': true,
    'filter': 'text',
    'orderIndex': 8,
  },
  {
    'title': 'Visible',
    'field': 'isVisible',
    'width': 8,
    'show': true,
    'filter': 'text',
    'orderIndex': 9,
  },
  {
    'title': 'Building Class',
    'field': 'buildingClassName',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 10,
  },
  {
    'title': 'Building ID',
    'field': 'bid',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 11,
  },
  {
    'title': 'Configuration Complete Date',
    'field': 'buildingConfigurationCompleteDate',
    'width': 12.5,
    'show': false,
    'filter': 'date',
    'render': 'date',
    'className': 'noWeglot',
    'orderIndex': 12,
  },
  {
    'title': 'Culture',
    'field': 'culture',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 13,
  },
  {
    'title': 'Date Created',
    'field': 'dateCreated',
    'width': 12.5,
    'show': false,
    'filter': 'date',
    'render': 'date',
    'className': 'noWeglot',
    'orderIndex': 14,
  },
  {
    'title': 'Internal Notes',
    'field': 'internalNotes',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 15,
  },
  {
    'title': 'Latitude',
    'field': 'latitude',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 16,
  },
  {
    'title': 'Longitude',
    'field': 'longitude',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 17,
  },
  {
    'title': 'Organization ID',
    'field': 'unitId',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 18,
  },
  {
    'title': 'Organization Name',
    'field': 'unitName',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 19,
  },
  {
    'title': 'Raw Data Delay (Hours)',
    'field': 'rawDataDelay',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'render': 'number',
    'orderIndex': 20,
  },
  {
    'title': 'Support Email Override',
    'field': 'supportEmailOverride',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 21,
  },
  {
    'title': 'SAS Reference ID',
    'field': 'sasReferenceId',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 22,
  },
  {
    'title': 'SAS Link',
    'field': 'sasLink',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 23,
  },
  {
    'title': 'Time Zone',
    'field': 'timeZone',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 24,
  },
  {
    'title': 'Unit System',
    'field': 'unitSystem',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'render': 'unitSystem',
    'orderIndex': 25,
  },
  {
    'title': 'Zip Code',
    'field': 'zip',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'className': 'noWeglot',
    'orderIndex': 26,
  },
  {
    'title': 'Missing Utility Rates',
    'field': 'missingUtilityRatesInfo',
    'width': 12.5,
    'show': false,
    'filter': 'text',
    'orderIndex': 27,
    'render': 'html',
  },
];

export default columns;
