import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LoadExternalCharts from './LoadExternalCharts';
import ExpansionPanel from '../../common/ExpansionPanel';
import { getDiagnosticReportConfigurationCharts } from '../../../actions/diagnosticsModuleActions';

const ConfigurationCharts = ({ eid }) => {
  const dispatch = useDispatch();
  const configChartsLoading = useSelector((state) => state.diagnosticsModule.equipmentProfile.configurationChartsLoading);
  const configurationCharts = useSelector((state) => state.diagnosticsModule.equipmentProfile.configurationCharts);
  useEffect(() => {
    dispatch(getDiagnosticReportConfigurationCharts(eid));
  }, []);

  return (
    <ExpansionPanel
      title={`Configuration Charts (${configurationCharts.length})`}
      loading={configChartsLoading}
    >{
        configChartsLoading
          ? <i className="bulma-loader-mixin m-auto" aria-hidden="true"></i>
          : configurationCharts.length === 0
            ? <div style={{ padding: '8px' }}>No configuration charts exist for this equipment.</div>
            : <LoadExternalCharts eid={eid} loading={configChartsLoading} />
      }
    </ExpansionPanel>
  );
};

ConfigurationCharts.propTypes = {
  eid: PropTypes.number.isRequired,
};

export default ConfigurationCharts;
