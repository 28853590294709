/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext, useCallback, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import BaseGrid from '../common/Grid/BaseGrid';
import { GridColumn, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { getSparklineChartOptions, getVisibleColumns } from '../diagnostics/utils';
import FilterContext from '../filters/FilterContext';
import { addToList, getPercentage, getTableWidth, parseQueryString, removeFromList, searchForMatchKeyword, getGridColumnWidthInPx, toQueryString } from '../../utils';
import useResizeObserver from '../../hooks/useResizeObserver';
import { CultureContext } from '../intl';
import diagnosticsPreviewColumns from './diagnosticsPreviewColumns';
import { getDatasetQueryStringParams } from '../widgets/widgetUtils';
import { getDiagnosticsPreviewModule, updateDiagnosticsPreviewModuleConfig, updateDiagnosticsPreviewModuleConfigColumns, updateDiagnosticsPreviewModuleGridConfig, updateDiagnosticsPreviewModuleConfigTopFilter, getDiagnosticsPreviewDetail } from '../../actions/diagnosticsPreviewModuleActions';
import diagnosticsPreviewGrid from './diagnosticsPreviewGrid';
import DiagnosticsPreviewMenuPopup from './DiagnosticsPreviewMenuPopup';
import { makeAsync } from '../common/Grid/utils';
import { exportCsv, formatExportExcelData } from '../tasks/utils';
import { showModal, hideModal, addModal, removeModal } from '../../actions/modalActions';
import { addContact } from '../../actions/contactActions';
import { apiErrorHandler } from '../../api/apiErrorHandler';
import HeaderCell from '../common/HeaderCell';
import ColumnSelectorPopup from '../admin/widgets/ColumnSelectorPopup';
import TopFilter from '../common/TopFilter';
import ManageDiagnosticsPreviewGridDetail from './ManageDiagnosticsPreviewGridDetail';
import { clearTaskModuleList } from '../../actions/taskModuleActions';
import { userResources } from '../../enums/resources';
import * as modalActions from '../../actions/modalActions';
import * as dashboardActions from '../../actions/dashboardActions';
import * as userActions from '../../actions/userActions';
import { createCellRenderer, topFilterDiagnosticsPreviewOptions, topFilterDiagnosticsPreviewFieldMapping, topFilterDiagnosticsPreviewDefaultValues } from './utils';

const tableSelector = '.diagnostics-grid .k-widget.k-grid';

const ManageDiagnosticsPreview = (props) => {
  const {
    user,
    diagnosticsPreviewModule,
    userSettings,
    taskStatuses,
    resultSubType,
    currentFilterField,
    jsonFiltersWithDefault,
    diagnosticsPreviewModuleJsonFilters,
    filterOverrides,
    dispatch,
    reportTheme,
    isAllFiltersLoading,
    filterOpen,
    CLASSIC_URL,
    search: locationSearch,
  } = props;

  const {
    jsonLayout,
  } = diagnosticsPreviewModule.config;

  const searchObject = parseQueryString(locationSearch);
  const { culture } = useContext(CultureContext);
  const { setApplyFilters, setSaveFilters, setPageFiltersHasChanged } = useContext(FilterContext);
  const anchorMenuRef = useRef();
  const menuWrapperRef = useRef();
  const columnSelectorPopupWrapperRef = useRef();
  const blurTimeoutRef = useRef();
  const exportExcelRef = useRef();
  const gridLoadingRef = useRef(true);
  const skipRef = useRef(diagnosticsPreviewGrid.skip);
  const topFilterRef = useRef({
    topValue: searchObject.topValue || jsonLayout.topValue || topFilterDiagnosticsPreviewDefaultValues.topValue,
    topBy: searchObject.topBy || jsonLayout.topBy || topFilterDiagnosticsPreviewDefaultValues.topBy,
  });

  const [popupAction, setPopupAction] = useState('btnExport');
  const [showMenu, setShowMenu] = useState(false);
  const [showColumnSelectorPopup, setShowColumnSelectorPopup] = useState(false);

  const [gridWidth, setGridWidth] = useState(768);

  useResizeObserver(document.querySelector('.main-container'), (result) => {
    if (result) setGridWidth((prevWidth) => getTableWidth(prevWidth, tableSelector));
  });

  const [loading, setLoading] = useState(false);
  const [queryString, setQueryString] = useState('');
  const [queryParams, setQueryParams] = useState({});
  const refreshDate = useRef(Date.now());
  const hasOnboardingReadPermission = user.resources.includes(userResources.Onboarding);

  const columns = useMemo(
    () => {
      const moduleColumns = Array.isArray(jsonLayout.columns) && jsonLayout.columns.length
        ? jsonLayout.columns
        : diagnosticsPreviewColumns;
      return hasOnboardingReadPermission
        ? moduleColumns
        : moduleColumns.filter((column) => column.field !== 'configurationNotes');
    },
    [jsonLayout.columns, hasOnboardingReadPermission],
  );

  const selectorColumns = useMemo(
    () => hasOnboardingReadPermission
      ? diagnosticsPreviewColumns
      : diagnosticsPreviewColumns.filter((column) => column.field !== 'configurationNotes'),
    [diagnosticsPreviewColumns, hasOnboardingReadPermission],
  );

  const visibleColumns = useMemo(
    () => getVisibleColumns(columns),
    [columns],
  );

  const [gridState, setGridState] = useState(() => createDataState(diagnosticsPreviewModule.config.jsonLayout.grid || diagnosticsPreviewGrid, []));
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [search, setSearch] = useState('');
  const [diagnosticsPreviewList, setDiagnosticsPreviewList] = useState([]);
  const [appliedSearch, setAppliedSearch] = useState('');
  const [selectedDiagnosticsPreview, setSelectedDiagnosticsPreview] = useState([]);

  const allData = useMemo(
    () => {
      const config = { ...gridState.dataState, skip: 0, take: undefined };
      const list = createDataState(config, diagnosticsPreviewList);
      return list.result.data;
    },
    [gridState, diagnosticsPreviewList],
  );

  const stringifiedGridStateData = useMemo(
    () => {
      const withoutSelectedProperty = gridState.result.data.map((e) => omit(e, 'selected'));
      return JSON.stringify(withoutSelectedProperty);
    },
    [gridState.result.data, gridState.dataState.skip],
  );

  useEffect(() => {
    setSelectedDiagnosticsPreview([]);
    setSelectedIds([]);
  }, [diagnosticsPreviewList]);

  const exportColumns = useMemo(
    () => {
      const noWidthColumns = visibleColumns.map((column) => omit(column, ['width']));
      const hasCurrencyColumn = noWidthColumns.some((s) => s.render === 'currency');
      if (hasCurrencyColumn) {
        return [
          ...noWidthColumns,
          { title: 'Currency', field: 'isoCurrencySymbol' },
        ];
      }
      return noWidthColumns;
    },
    [visibleColumns],
  );

  const savedComputedFilters = useMemo(() => {
    let newFilters = [];

    diagnosticsPreviewModuleJsonFilters.forEach((eachFilter) => {
      const found = jsonFiltersWithDefault.find((e) => e.key === eachFilter.key);
      if (Array.isArray(eachFilter.value) && eachFilter.value.length) {
        newFilters.push(eachFilter);
      } else if (found) {
        newFilters.push(found);
      } else {
        newFilters.push(eachFilter);
      }
    });
    return newFilters;
  }, [jsonFiltersWithDefault, diagnosticsPreviewModuleJsonFilters]);

  const forceRefreshTable = () => {
    refreshDate.current = Date.now();
    handleRefresh();
  };

  const handleRefresh = useCallback(() => {
    const jsonFiltersToRefresh = diagnosticsPreviewModuleJsonFilters
      .map((e) => ({ key: e.key, value: e.value }))
      .filter((e) => Array.isArray(e.value) ? e.value.length : true);
    fetchDiagnosticsPreview(jsonFiltersToRefresh);
    setSearch('');
    setAppliedSearch('');
  }, [diagnosticsPreviewModuleJsonFilters, jsonFiltersWithDefault]);

  const handleSave = useCallback(() => {
    const jsonFiltersToSave = diagnosticsPreviewModule.config.jsonFilters.map((e) => ({ key: e.key, value: e.value }));
    const jsonLayoutToSave = {
      ...diagnosticsPreviewModule.config.jsonLayout,
      columns: columns.map((e) => omit(e, ['widthInPx'])),
    };
    const params = {
      update: {
        jsonFilters: JSON.stringify(jsonFiltersToSave),
        jsonLayout: JSON.stringify(jsonLayoutToSave),
      },
    };
    dispatch(updateDiagnosticsPreviewModuleConfig(params)).then(() => {
      dispatch(hideModal());
      showSuccessToast('Configuration Saved.');
    });
  }, [diagnosticsPreviewModule.config]);

  const debouncedResize = useCallback(
    debounce((evt) => handleColumnResize(evt), 350),
    [columns, visibleColumns],
  );

  const saveCallback = () => {
    closeViewModal();
    forceRefreshTable();
    skipRef.current = gridState.dataState.skip;
  };

  const handleShowCreateTask = useCallback((dataItem) => {
    const [firstHistoricalPriority] = dataItem.historicalPriorities;
    dispatch(
      showModal(
        'CREATE_TASK',
        {
          modalContent: 'full scrollable',
          cancelCallback: closeViewModal,
          saveCallback,
          data: {
            defaultData: {
              ...dataItem,
              notesSummary: firstHistoricalPriority.notesSummary,
            },
            appliedQueryParams: queryParams,
          },
        }),
    );
  }, [queryParams, gridState.dataState.skip]);

  const saveContact = useCallback(
    (contact, closeModalCallback) => {
      const diagnosticDetails = JSON.parse(contact.diagnosticDetails);
      const payload = {
        ...contact,
        queryString: JSON.stringify([{
          'UnitID': diagnosticDetails.organizationId,
          'BuildingID': diagnosticDetails.buildingId,
          'EquipmentClassID': diagnosticDetails.equipmentClassId,
          'EquipmentID': diagnosticDetails.equipmentId,
          'AnalysisID': diagnosticDetails.analysisId,
          'DiagnosticStartDate': diagnosticDetails.historicalPriorities[0].diagnosticDate,
          'AnalysisRange': diagnosticDetails.analysisInterval,
          'Organization': diagnosticDetails.organizationName,
          'BuildingName': diagnosticDetails.buildingName,
          'EquipmentName': diagnosticDetails.equipmentName,
          'AnalysisName': diagnosticDetails.analysisName,
        }]),
      };
      dispatch(addContact(payload, true)).then(() => {
        const toastText = 'Email Sent';
        toastr.success(toastText, toastText, {
          removeOnHover: true,
          removeOnHoverTimeOut: 1000,
        });
        closeModalCallback();
      }).catch(() => dispatch(apiErrorHandler));
    }, [],
  );

  const handleRequestSupport = useCallback((dataItem, options = {}) => {
    const modalAction = options.addModal ? addModal : showModal;
    const close = options.addModal
      ? () => closeTopModal('CONTACT_FORM')
      : () => dispatch(hideModal());
    const save = (contact) => saveContact(contact, close);
    dispatch(
      modalAction(
        'CONTACT_FORM',
        {
          modalContent: 'full',
          title: 'Contact Form',
          saveCallback: save,
          cancelCallback: close,
          saveButtonLabel: 'Save',
          showExportWidgetQuery: false,
          diagnosticDetails: dataItem,
        }),
    );
  }, []);

  const handleViewRawData = useCallback((dataItem) => {
    const queryString = `target=${JSON.stringify({ bid: dataItem.buildingId, classid: dataItem.equipmentClassId, eid: dataItem.equipmentId })}`;
    const pageQueryString = `_e=${user.email}&_t=${user.timezoneOffset}&_l=${user.iDpLogout}&unitid=${dataItem.organizationId}`;
    const redirectUrl = `https://${typeof reportTheme === 'number'
      ? `${CLASSIC_URL[reportTheme]}/AnalysisBuilder?${pageQueryString}&${encodeURIComponent(queryString)}`
      : 'badurl'}`;
    window.open(redirectUrl, '_blank');
  }, [user, reportTheme]);

  const handleChangeTopFilter = (topFilter) => {
    setPageFiltersHasChanged(true);
    updateTopFilter(topFilter);

    const field = topFilterDiagnosticsPreviewFieldMapping[topFilter.topBy];
    const newDataState = { ...gridState.dataState, skip: 0, sort: [{ field, dir: 'desc' }] };
    setGridState(createDataState(newDataState, diagnosticsPreviewList));

    dispatch(updateDiagnosticsPreviewModuleConfigTopFilter(topFilter));
    forceRefreshTable();
  };

  const updateTopFilter = (topFilter) => {
    topFilterRef.current = {
      topValue: topFilter.topValue,
      topBy: topFilter.topBy,
    };
  };

  const sparklineChartOptions = useMemo(
    () => getSparklineChartOptions({ onPointClick: () => {} }),
    [queryParams],
  );

  const getCellOptions = useCallback((column) => {
    if (column.render === 'action') {
      return {
        onCreateTask: handleShowCreateTask,
        onRequestSupport: handleRequestSupport,
        onViewRawData: handleViewRawData,
        chartOptions: sparklineChartOptions,
      };
    }
    if (column.render === 'sparkLine') {
      return {
        chartOptions: sparklineChartOptions,
      };
    }
    return null;
  }, [handleShowCreateTask, sparklineChartOptions]);

  const cellRender = useCallback(
    (column, gridView) => column.render
      ? createCellRenderer(column, { renderType: 'normal', culture, gridView, onRowClick: () => {}, openViewModal: () => {}, ...getCellOptions(column) })
      : column.cell,
    [culture, getCellOptions],
  );

  const renderedColumns = useMemo(
    () => visibleColumns.map((column) => (
      <GridColumn
        key={column.field}
        field={column.field}
        title={column.title}
        filter={column.filter}
        sortable={column.sortable}
        reorderable={column.reorderable !== false}
        orderIndex={column.orderIndex}
        width={getGridColumnWidthInPx(column, gridWidth - 32)}
        headerClassName={column.headerClassName}
        render={column.render}
        cell={cellRender(column)}
        headerCell={HeaderCell}
      />
    )),
    [visibleColumns, cellRender, gridWidth, stringifiedGridStateData],
  );

  const renderGridDetail = useCallback(
    (innerProps) => (
      <ManageDiagnosticsPreviewGridDetail
        {...innerProps}
        key={stringifiedGridStateData}
        gridWidth={gridWidth}
        jsonLayout={jsonLayout}
        visibleColumns={visibleColumns}
        cellRender={cellRender}
      />
    ),
    [gridWidth, jsonLayout, visibleColumns, queryParams, cellRender, stringifiedGridStateData],
  );

  useEffect(() => {
    setApplyFilters(handleRefresh);
    setSaveFilters(handleSave);
  }, [handleRefresh, handleSave]);

  // load DiagnosticsPreview on mount
  useEffect(() => {
    if (!loading && !isAllFiltersLoading && !filterOpen) {
      fetchDiagnosticsPreview(savedComputedFilters);
    }
  }, [queryString, currentFilterField, isAllFiltersLoading, filterOpen, savedComputedFilters]);

  // update diagnosticsPreviewList on diagnosticsPreviewModule.list, appliedSearch change
  useEffect(() => {
    setDiagnosticsPreviewList(getDiagnosticsPreviewListWithSearch(diagnosticsPreviewModule.list));
  }, [diagnosticsPreviewModule.list, appliedSearch]);

  // update grid on diagnosticsPreviewList change
  useEffect(() => {
    setGridState(createDataState({ ...gridState.dataState, skip: skipRef.current || 0 }, diagnosticsPreviewList));
    skipRef.current = 0;
  }, [diagnosticsPreviewList]);

  // update grid on selectedIds change
  useEffect(() => {
    setGridState(createDataState(gridState.dataState, diagnosticsPreviewList));
  }, [selectedIds]);

  // update isAllSelected on selectedIds, diagnosticsPreviewList change
  useEffect(() => {
    if (selectedDiagnosticsPreview.length !== diagnosticsPreviewList.length || !selectedIds.length) {
      setIsAllSelected(false);
    }
  }, [selectedIds, diagnosticsPreviewList]);

  // update redux on grid change
  useEffect(() => {
    dispatch(updateDiagnosticsPreviewModuleGridConfig(gridState.dataState));
  }, [gridState.dataState]);

  // update grid on selectedIds change
  useEffect(() => {
    setGridState(createDataState(gridState.dataState, diagnosticsPreviewList));
  }, [selectedIds]);

  function showSuccessToast(message) {
    toastr.success(message, message, {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  }

  function fetchDiagnosticsPreview(jsonFilters) {
    const availableListValues = {
      taskStatuses,
    };
    let mergedFilters = [...jsonFilters];
    const hasClientFilter = jsonFilters.some((s) => s.key === 'client');
    const clientFilter = jsonFiltersWithDefault.find((e) => e.key === 'client');
    if (!hasClientFilter && clientFilter) {
      mergedFilters.push(clientFilter);
    }

    const hasBuildingFilter = jsonFilters.some((s) => s.key === 'building');
    const buildingFilter = jsonFiltersWithDefault.find((e) => e.key === 'building');
    if (!hasBuildingFilter && buildingFilter) {
      mergedFilters.push(buildingFilter);
    }

    const hasResultGroupFilter = Boolean(jsonFilters.find((s) => s.key === 'resultGroup')?.value);
    const hasResultClassFilter = Boolean(jsonFilters.find((s) => s.key === 'resultClass')?.value);
    const hasResultSubTypeFilter = Boolean(jsonFilters.find((s) => s.key === 'resultSubType')?.value);
    if (hasResultGroupFilter && !hasResultClassFilter && !hasResultSubTypeFilter) {
      mergedFilters = mergedFilters
        .filter((s) => s.key !== 'resultSubType')
        .concat({
          key: 'resultSubType',
          value: resultSubType.map((e) => e.id),
        });
    }

    const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
      userSettings,
      mergedFilters,
      availableListValues,
      filterOverrides,
      topFilter: topFilterRef.current,
    });

    setQueryString(newQueryString);
    setQueryParams(newQueryParams);

    if (newQueryString) {
      setLoading(true);
      dispatch(
        getDiagnosticsPreviewModule({
          queryString: `${newQueryString}&refreshDate=${refreshDate.current}`,
          queryParams: newQueryParams,
        }),
      ).finally(() => {
        gridLoadingRef.current = false;
        setLoading(false);
      });
    }
  }

  function fetchDiagnosticsPreviewDetail(dataItem, rowKey) {
    const params = {
      ...queryParams,
      MaxRows: undefined,
      TopBy: undefined,
      AnalysisID: dataItem.analysisId,
      EquipmentID: dataItem.equipmentId,
    };
    const queryString = toQueryString(params);
    dispatch(getDiagnosticsPreviewDetail(`?${queryString}`, rowKey));
  }

  function createDataState(dataState, data = []) {
    const list = data.map((item) => ({
      ...item,
      selected: selectedIds.some((s) => {
        const diagnosticId = getUniqueDiagnosticId(item);
        return s === diagnosticId;
      }),
    }));

    const result = process(list, dataState);

    return {
      dataState,
      result,
    };
  }

  function handleUpdateColumns(newColumns) {
    dispatch(updateDiagnosticsPreviewModuleConfigColumns(newColumns));
  }

  function handleColumnsSubmit(columnsState) {
    handleUpdateColumns(columnsState);
    setGridState(createDataState(gridState.dataState, diagnosticsPreviewList, columnsState));
  }

  function handleColumnReorder(evt) {
    const newColumns = columns.map((oldCol) => {
      const foundColumn = evt.columns.find((newCol) => newCol.field === oldCol.field);
      if (foundColumn) {
        return {
          ...oldCol,
          orderIndex: foundColumn.orderIndex,
        };
      }
      return oldCol;
    });

    handleUpdateColumns(newColumns);
  }

  function handleColumnResize(evt) {
    if (evt.end) {
      // index + 2, first is collapser, second is checkbox
      const resizedColumn = visibleColumns.sort((a, b) => a.orderIndex - b.orderIndex).find((_, index) => evt.index === index + 2);
      const newColumns = columns.map((col) => {
        if (resizedColumn && resizedColumn.field === col.field) {
          return {
            ...col,
            width: getPercentage(gridWidth, evt.newWidth),
            widthInPx: evt.newWidth,
          };
        }
        return col;
      });
      handleUpdateColumns(newColumns);
    }
  }

  function expandChange(event) {
    const rowKey = `${event.dataItem.analysisId}-${event.dataItem.equipmentId}`;
    event.dataItem.rowKey = rowKey;
    event.dataItem[event.target.props.expandField] = event.value;
    setGridState({ ...gridState });
    if (event.value) {
      fetchDiagnosticsPreviewDetail(event.dataItem, rowKey);
    }
  }

  function dataStateChange(event) {
    setGridState(({ dataState: prevDataState }) => ({
      ...prevDataState,
      ...createDataState(event.data, diagnosticsPreviewList),
    }));
  }

  function handleSelectionChange(event) {
    const { selected } = event.dataItem;

    const diagnosticId = getUniqueDiagnosticId(event.dataItem);
    if (selected) {
      setSelectedIds((prevIds) => removeFromList(prevIds, diagnosticId));
      handleRemoveSelectedDiagnosticsPreview(event.dataItem);
    } else {
      setSelectedIds((prevIds) => addToList(prevIds, diagnosticId));
      handleAddSelectedDiagnosticsPreview(event.dataItem);
    }
  }

  function handleAddSelectedDiagnosticsPreview(diagnostic) {
    setSelectedDiagnosticsPreview((prev) => [...prev, diagnostic]);
  }

  function handleRemoveSelectedDiagnosticsPreview(diagnostic) {
    const removedSelectedDiagnostic = selectedDiagnosticsPreview.filter((item) => item.aeid !== diagnostic.aeid);
    setSelectedDiagnosticsPreview(removedSelectedDiagnostic);
  }

  function handleHeaderSelectionChange(event) {
    const { checked: selected } = event.syntheticEvent.target;
    if (selected) {
      const diagnosticIds = diagnosticsPreviewList.map((item) => {
        const diagnosticId = getUniqueDiagnosticId(item);
        return diagnosticId;
      });
      setSelectedIds(diagnosticIds);
      setSelectedDiagnosticsPreview(diagnosticsPreviewList);
    } else {
      setSelectedIds([]);
      setSelectedDiagnosticsPreview([]);
    }
    setIsAllSelected(selected);
  }

  function handleKeyPress(e) {
    if ((e.keyCode || e.which) === 13) {
      applySearch();
    }
  }

  function handleSearchChange(e) {
    setSearch(e.target.value);
  }

  function getDiagnosticsPreviewListWithSearch(list) {
    return list.filter((item) => {
      const { latestAnalysisResult } = item;
      const fields = [latestAnalysisResult];
      const fullString = fields.filter((field) => field !== null).join('  ');
      return searchForMatchKeyword(fullString, appliedSearch);
    });
  }

  function applySearch() {
    setAppliedSearch(search);
  }

  function handleSelect(e) {
    setShowMenu(false);

    switch (e.item.text) {
      case 'Export CSV':
        handleExportCsv();
        break;
      case 'Export Excel':
        handleExportExcel();
        break;
      case 'Choose Columns':
        setShowColumnSelectorPopup(true);
        break;
      default:
        break;
    }
  }

  function blurTimeout() {
    showMenu && setShowMenu(false);
    showColumnSelectorPopup && setShowColumnSelectorPopup(false);
    blurTimeoutRef.current = undefined;
  }

  function handleMenuClick(evt) {
    anchorMenuRef.current = evt.target;
    setPopupAction(evt.target.name);
    setTimeout(() => setShowMenu((prevShow) => !prevShow));
  }

  function handlePopupOpen() {
    const wrapperEl = menuWrapperRef.current;
    wrapperEl && wrapperEl.focus();

    const wrapperEl2 = columnSelectorPopupWrapperRef.current;
    wrapperEl2 && wrapperEl2.focus();
  }

  function handleFocusHandler() {
    clearTimeout(blurTimeoutRef.current);
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = undefined;
  }

  function handleBlurHandler(evt) {
    const { relatedTarget } = evt;
    if (
      relatedTarget &&
      relatedTarget.getAttribute &&
      relatedTarget.getAttribute('id')
    ) {
      if (relatedTarget.getAttribute('id').includes('column-visibility')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-container')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-reset')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-submit')) return;
    }

    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(blurTimeout, 100);
  }

  function handleExportExcel() {
    if (exportExcelRef.current) {
      makeAsync(() => {
        const excelColumns = exportColumns.map((column) => ({
          ...column,
          cell: column.render
            ? createCellRenderer(column, { renderType: 'excel', culture })
            : column.cell,
        }));
        const formattedAllData = formatExportExcelData(allData, excelColumns);
        exportExcelRef.current.save(formattedAllData, excelColumns);
      });
    }
  }

  function handleExportCsv() {
    makeAsync(() => {
      const csvColumns = exportColumns.map((column) => ({
        ...column,
        cell: column.render
          ? createCellRenderer(column, { renderType: 'csv', culture })
          : column.cell,
      }));
      exportCsv(`DiagnosticsPreview - ${Date.now()}`, csvColumns, allData);
    });
  }

  function closeViewModal() {
    dispatch(hideModal());
    dispatch(clearTaskModuleList());
  }

  function closeTopModal(modalType) {
    dispatch(removeModal(modalType));
  }

  return (
    <div className="column content-spacing-container">
      <div className="level" style={{ marginBottom: '.5rem' }}>
        <div className="level-left">
          <div className="level-item">
            <h1 className="title">Diagnostics (preview)</h1>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <TopFilter
              loading={loading}
              topBy={topFilterRef.current.topBy}
              topValue={topFilterRef.current.topValue}
              onChange={handleChangeTopFilter}
              topByOptions={topFilterDiagnosticsPreviewOptions}
              showTopBy={true}
            />
          </div>
        </div>
      </div>

      <DiagnosticsPreviewMenuPopup
        action={popupAction}
        show={showMenu}
        menuRef={anchorMenuRef}
        menuWrapperRef={menuWrapperRef}
        onPopupOpen={handlePopupOpen}
        onSelect={handleSelect}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
      />

      <ColumnSelectorPopup
        initialColumns={selectorColumns}
        columns={columns}
        show={showColumnSelectorPopup}
        menuRef={anchorMenuRef}
        menuWrapperRef={columnSelectorPopupWrapperRef}
        onPopupOpen={handlePopupOpen}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
        onColumnsSubmit={handleColumnsSubmit}
      />

      <BaseGrid
        wrapperClassName="diagnostics-grid"
        data={gridState.result}
        {...gridState.dataState}
        detail={renderGridDetail}
        onDataStateChange={dataStateChange}
        onSelectionChange={handleSelectionChange}
        onHeaderSelectionChange={handleHeaderSelectionChange}
        expandField="expanded"
        selectedField="selected"
        onExpandChange={expandChange}
        sortable={{ allowUnsort: true }}
        onColumnReorder={handleColumnReorder}
        onColumnResize={debouncedResize}
        reorderable
        resizable
        pageable={{ type: 'input', info: true, pageSizes: [10, 25, 50, 100] }}
      >
        <GridToolbar>
          <div className="level">
            <div className="level-left" />
            <div className="level-right">
              <div className="level-item">
                <input
                  size={32}
                  type="text"
                  className="input"
                  placeholder="Search Notes"
                  value={search}
                  onChange={handleSearchChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="level-item">
                <button className="button" title="Search" aria-label='Search' onClick={applySearch}>
                  <span className="icon">
                    <i className="flaticon-search-interface-symbol" aria-hidden="true" />
                  </span>
                </button>
              </div>
              <div className="level-item">
                <button
                  name="btnExport"
                  className="button"
                  title="Export"
                  aria-label='Export'
                  ref={anchorMenuRef}
                  onClick={handleMenuClick}
                >
                  <span className="icon">
                    <i className="flaticon-menu" aria-hidden="true" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </GridToolbar>
        <GridNoRecords>
          {isAllFiltersLoading || loading || gridLoadingRef.current
            ? <i className="bulma-loader-mixin m-auto" aria-hidden="true"></i>
            : 'No records available'
          }
        </GridNoRecords>
        <GridColumn
          field="selected"
          className="colCheckbox"
          resizable={false}
          reorderable={false}
          headerSelectionValue={isAllSelected}
          width={34}
        />
        {renderedColumns}
      </BaseGrid>

      <ExcelExport
        ref={exportExcelRef}
        fileName={`DiagnosticsPreview - ${Date.now()}.xlsx`}
      />
    </div>
  );
};

const getUniqueDiagnosticId = (diagnosticItem) => {
  const { equipmentId, analysisId, diagnosticDate } = diagnosticItem;
  return `equipmentId=${equipmentId}&analysisId=${analysisId}&diagnosticDate=${diagnosticDate}`;
};

ManageDiagnosticsPreview.propTypes = {
  search: PropTypes.string,
  jsonFiltersWithDefault: PropTypes.array,
  diagnosticsPreviewModuleJsonFilters: PropTypes.array,
  taskStatuses: PropTypes.array,
  resultSubType: PropTypes.array,
  filterOverrides: PropTypes.string,
  userSettings: PropTypes.object.isRequired,
  diagnosticsPreviewModule: PropTypes.object.isRequired,
  currentFilterField: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object,
  isAllFiltersLoading: PropTypes.bool,
  filterOpen: PropTypes.bool,
  reportTheme: PropTypes.number,
  user: PropTypes.object.isRequired,
  CLASSIC_URL: PropTypes.array,
  actions: PropTypes.object.isRequired,
};


const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...modalActions, ...dashboardActions, ...userActions }, dispatch),
});

export default connect(null, mapDispatchToProps)(ManageDiagnosticsPreview);

