import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useGetBuildingSetting } from './hooks';

const EquipmentDetails = ({ details }) => {
  const buildingSetting = useGetBuildingSetting(details.bid);
  const isEnableCreateWorkOrder = buildingSetting?.isEnableCreateWorkOrder;

  return (
    <div className="columns is-multiline is-gapless mb-0">
      <LabelValue label="Equipment Name:" value={details.equipmentName} />
      <LabelValue label="Organization Name:" value={details.organizationName} />
      <LabelValue label="Building Name:" value={details.buildingName} />
      <LabelValue label="Equipment Class:" value={details.equipmentClassName} />
      <LabelValue label="Equipment Type:" value={details.equipmentTypeName} />
      <LabelValue label="Equipment Labels:" value={details.equipmentTags.join(', ')} />
      <LabelValue label="Active:" value={details.active ? 'Yes' : 'No'} />
      <LabelValue label="Visible:" value={details.visible ? 'Yes' : 'No'} />
      <LabelValue label="Configuration Status:" value={details.configurationStatusName} />
      {details.equipmentNotes && (
        <LabelValue label="Equipment Notes:" value={details.equipmentNotes} />
      )}
      {isEnableCreateWorkOrder && (
        <>
          <LabelValue label="CMMS Reference ID:" value={details.cmmsReferenceId} />
          <LabelValue label="CMMS Location ID:" value={details.cmmsLocationId} />
          <LabelValue label="CMMS Site ID:" value={details.cmmsSiteId} />
          <LabelValue label="CMMS Link:" value={details.cmmsLink} />
        </>
      )}
    </div>
  );
};

const defaultStyle = { marginBottom: '.4rem' };
const LabelValue = ({ label, value, style = defaultStyle }) => (
  <Fragment>
    <div className="column is-one-quarter has-text-right has-text-weight-bold">
      {label}
    </div>
    <div className="column is-three-quarters">
      <p style={{ marginLeft: '.75rem', minHeight: '1.5rem', ...style }}>
        {value}
      </p>
    </div>
  </Fragment>
);

LabelValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
};

EquipmentDetails.propTypes = {
  details: PropTypes.object,
  culture: PropTypes.string,
};

export default EquipmentDetails;
