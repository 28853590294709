import React from 'react';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import ManageDiagnostics from './ManageDiagnostics';
import withDiagnosticsModuleWrapper from '../../hocs/withDiagnosticsModuleWrapper';
import { useSettingsSelector } from '../../selectors/useSettings';
import { getAppliedFiltersWithDefault } from '../../selectors/appliedDashboardFilterWithDefaultSelector';
import { loadingAllFiltersSelector } from '../../selectors/loadingFilters';

const included = [
  'jsonFiltersWithDefault',
  'diagnosticsModuleJsonFilters',
  'diagnosticsModule',
  'isAllFiltersLoading',
  'userSettings',
];

const arePropsEqual = (prevProps, nextProps) => JSON.stringify(pick(nextProps, included)) === JSON.stringify(pick(prevProps, included));
const ManageDiagnosticsContainer = React.memo(ManageDiagnostics, arePropsEqual);

const mapStateToProps = (state) => ({
  userSettings: useSettingsSelector(state.user),
  jsonFiltersWithDefault: getAppliedFiltersWithDefault(state),
  taskStatuses: state.taskStatuses,
  taskLabels: state.taskLabels,
  diagnosticsModule: state.diagnosticsModule,
  currentFilterField: state.appliedFilters.currentFilterField,
  isAllFiltersLoading: loadingAllFiltersSelector(state),
  filterOpen: state.dashboardFilters.filterOpen,
  reportTheme: state.whiteLabel.reportTheme,
  user: state.user,
  modal: state.modal,
});

export default withDiagnosticsModuleWrapper(connect(mapStateToProps)(ManageDiagnosticsContainer));
