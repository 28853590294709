import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { getConfigurationCharts } from '../../../api/api';
import smpl from '../../highcharts/themes/smpl.json';

const LoadExternalCharts = ({ eid }) => {
  const [htmlContent, setHtmlContent] = useState(null);
  useEffect(() => {
    initialize();
  }, []);

  function initialize() {
    // Load jQuery, Highcharts, and other Highcharts modules in sequence
    if (!window.jQuery) {
      loadScripts(['https://code.jquery.com/jquery-3.6.4.min.js']).then(() => {
        // Check if Highcharts is already defined
        if (typeof window.Highcharts === 'undefined') {
          // If not defined, load Highcharts and its modules
          loadScripts([
            'https://code.highcharts.com/highcharts.js',
            'https://code.highcharts.com/highcharts-more.js',
            'https://code.highcharts.com/modules/exporting.js',
            'https://code.highcharts.com/modules/heatmap.js',
            'https://code.highcharts.com/modules/accessibility.js',
            'https://code.highcharts.com/modules/treemap.js',
            'https://code.highcharts.com/modules/treegraph.js',
            'https://code.highcharts.com/modules/timeline.js',
            'https://code.highcharts.com/modules/sankey.js',
            'https://code.highcharts.com/modules/organization.js',
          ]).then(() => {
            window.Highcharts.setOptions(smpl);
            bindHighchartsToJQuery();
            loadExternalHTML();
          }).catch(() => {
            //do nothing
          });
        } else {
          // If Highcharts is already defined, bind it to jQuery and load the HTML
          bindHighchartsToJQuery();
          loadExternalHTML();
        }
      }).catch(() => {
        //console.error('Error loading jQuery:', error);
      });
    }
    else {
      bindHighchartsToJQuery();
      loadExternalHTML();
    }
  }

  const bindHighchartsToJQuery = () => {
    if (typeof window.jQuery !== 'undefined' && typeof window.Highcharts !== 'undefined') {
      window.jQuery.fn.highcharts = function () {
        if (this.length === 0) {
          return null;
        }
        return window.Highcharts.chart(this[0], ...arguments);
      };
    }
  };

  const loadScripts = (urls) =>
    urls.reduce((promise, url) => promise.then(() => new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.onload = resolve; // Proceed to the next script once this one is loaded
      script.onerror = reject;
      document.body.appendChild(script);
    })), Promise.resolve()) // Start with a resolved promise
  ;

  const loadExternalHTML = () => {
    getConfigurationCharts(eid)
      .then((html) => {
        const newHtml = executeScripts(html); // Execute jQuery-dependent scripts
        setHtmlContent(newHtml);
      })
      .catch(() => {
        //console.error('Error loading HTML:', error)
      });
  };

  const executeScripts = (html) => {
    const chartFolder = document.querySelector('#configurationCharts');
    if (chartFolder) {
      chartFolder.remove();
    }
    const scriptContainer = document.createElement('div');
    const serializer = new XMLSerializer();
    let finalChartString = '';
    scriptContainer.id = 'configurationCharts';

    for( let i = 0; i < html.length; i++ ) {
      const doc = new DOMParser().parseFromString(html[i].fileContents, 'text/html');
      const divs = doc.querySelectorAll('div');
      divs[0].style.width='100%';
      divs[1].style.width = '100%';
      divs[1].style.aspectRatio = `${divs[0].dataset.width} / ${divs[0].dataset.height}`;
      const scripts = doc.querySelectorAll('script');
      scripts.forEach((oldScript) => {
        const newScript = document.createElement('script');
        if (oldScript.src) {
          // Load external scripts if present in the HTML
          newScript.src = oldScript.src;
        } else {
          // Wrap inline scripts in $(document).ready() to ensure they run after everything is loaded
          newScript.textContent = oldScript.textContent;
        }

        scriptContainer.appendChild(newScript); // Append and execute the script
        document.body.appendChild(scriptContainer);
        oldScript.remove();
      });

      finalChartString = finalChartString + serializer.serializeToString(doc);
    }
    return finalChartString;
  };

  return (
    <div
      id="configurationChartSection"
      dangerouslySetInnerHTML={{ __html: htmlContent || '' }}
    />
  );
};

LoadExternalCharts.propTypes = {
  eid: PropTypes.number,
};

export default memo(LoadExternalCharts);
