import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { hasFilterValue } from '../actionHelpers/commonHelpers';
import { setCurrentFilterField, setCurrentFilterFieldSkipFilters, setExcludePageFilterIds } from '../actions/appliedFiltersActions';
import { addOrUpdateJsonFilters, setGroupFilters } from '../actions/commonActions';
import { setGlobalJsonFilters } from '../actions/userActions';
import { clearDiagnosticsModule } from '../actions/diagnosticsModuleActions';
import FilterContext from '../components/filters/FilterContext';
import { filterFields, filterIds } from '../enums/filters';
import { diagnosticsModuleJsonFilterSelector } from '../selectors/diagnosticsModuleJsonFilterSelector';
import PageLoading from '../components/common/PageLoading';

const withDiagnosticsModuleWrapper = (WrappedComponent) => (props) => {
  const { setPageFiltersHasChanged } = useContext(FilterContext);
  const CLASSIC_URL = process.env.CLASSIC_URL;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const search = location.search;
  const prevSearch = useRef(search);
  const [mounted, setMounted] = useState(false);

  const jsonFilters = useSelector((state) => state.diagnosticsModule.config.jsonFilters);
  const jsonFiltersFromAPILoaded = useSelector((state) => state.diagnosticsModule.config.jsonFiltersFromAPILoaded);
  const jsonFiltersFromQueryString = useSelector(diagnosticsModuleJsonFilterSelector);
  const diagnosticsModuleJsonFilters = mounted ? jsonFilters : jsonFiltersFromQueryString || jsonFilters;

  useEffect(() => {
    document.title = 'Diagnostics';
    dispatch(setExcludePageFilterIds([
      filterIds.isBuildingActive,
      filterIds.isEquipmentActive,
      filterIds.pointClass,
      filterIds.pointType,
      filterIds.point,
      filterIds.vPoint,
      filterIds.isPointActive,
      filterIds.rawdatasetDate,
      filterIds.dataSource,

      filterIds.currency,

      filterIds.resultGroup,
      filterIds.resultClass,
      filterIds.resultType,
      filterIds.resultSubType,
    ]));
    return () => {
      dispatch(clearDiagnosticsModule());
    };
  }, []);

  const setFilters = async () => {
    if (jsonFiltersFromQueryString) {
      const providerFilter = diagnosticsModuleJsonFilters.find((e) => e.key === 'provider');
      const clientFilter = diagnosticsModuleJsonFilters.find((e) => e.key === 'client');

      if (hasFilterValue(providerFilter) || hasFilterValue(clientFilter)) {
        const newGlobalFilters = [providerFilter, clientFilter].filter(Boolean);
        dispatch(setGlobalJsonFilters(newGlobalFilters, true));
      }

      dispatch(setCurrentFilterFieldSkipFilters(filterFields.diagnosticsModule));
      dispatch(addOrUpdateJsonFilters(diagnosticsModuleJsonFilters, true));
      await dispatch(setGroupFilters({ skipGlobalFilters: false }));
      setPageFiltersHasChanged(true);
      setMounted(true);
    }
  };

  const setModule = async () => {
    await dispatch(setCurrentFilterField(filterFields.diagnosticsModule));
    setMounted(true);
  };

  useEffect(() => {
    if (jsonFiltersFromAPILoaded && !jsonFiltersFromQueryString) {
      setModule();
    }
  }, [jsonFiltersFromAPILoaded, jsonFiltersFromQueryString]);

  useEffect(() => {
    const prev = prevSearch.current;
    const current = search;
    if (prev && !current) {
      history.push({
        pathname: location.pathname,
        state: { forceRefresh: Date.now() },
      });
    }
    prevSearch.current = search;
  }, [search]);

  useEffect(() => {
    if (!jsonFiltersFromAPILoaded) return;
    setFilters();
  }, [search, jsonFiltersFromAPILoaded]);

  if (!mounted) {
    return <PageLoading label="Loading" boxClass={'page-loading-container'} />;
  }

  return mounted && (
    <WrappedComponent
      {...props}
      key={search}
      search={search}
      CLASSIC_URL={CLASSIC_URL}
      diagnosticsModuleJsonFilters={diagnosticsModuleJsonFilters}
    />
  );
};

export default withDiagnosticsModuleWrapper;
