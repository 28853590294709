import React, { useEffect, useState, useContext, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { process } from '@progress/kendo-data-query';
import { GridColumn, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import TaskMenuPopup from './TaskMenuPopup';
import BaseGrid from '../common/Grid/BaseGrid';
import IconButton from '../common/IconButton';
import TaskSummary from './TaskSummary';
import { getDatasetQueryStringParams } from '../widgets/widgetUtils';
import {
  getTaskModule,
  updateTaskModuleGridConfig,
  getTaskDetails,
  bulkDeleteTaskModule,
  bulkEditTaskModule,
  clearTaskDetails,
  clearReportersAndAssignees,
  updateTaskModuleConfigColumns,
  updateTaskModuleConfig,
  resetTaskModuleJsonFilters,
} from '../../actions/taskModuleActions';
import { showModal, updateModal, hideModal } from '../../actions/modalActions';
import { addOrUpdateJsonFilters } from '../../actions/commonActions';
import { CultureContext } from '../intl';
import taskColumns from './taskColumns';
import taskGrid from './taskGrid';
import { getPercentage, getTableWidth, parseQueryString, searchForMatchKeyword, getGridColumnWidthInPx } from '../../utils';
import { makeAsync } from '../common/Grid/utils';
import { createCellRenderer, exportCsv, formatExportExcelData } from './utils';
import useResizeObserver from '../../hooks/useResizeObserver';
import FilterContext from '../filters/FilterContext';
import HeaderCell from '../common/HeaderCell';
import ColumnSelectorPopup from '../admin/widgets/ColumnSelectorPopup';
import { push } from 'connected-react-router';
import { userResources as resources } from '../../enums/resources';

function addToList(list, id) {
  return Array.from(new Set([...list, id]));
}

function removeFromList(list, id) {
  return list.filter((item) => item !== id);
}

const tableSelector = '.task-grid .k-widget.k-grid';

const ManageTasks = (props) => {
  const {
    history,
    taskModule,
    userSettings,
    taskStatuses,
    currentFilterField,
    jsonFiltersWithDefault,
    taskModuleJsonFilters,
    filterOverrides,
    dispatch,
    title,
    preCannedFilters = [],
    taskAssignee,
    isAllFiltersLoading,
    filterOpen,
    search: locationSearch,
    userResources,

  } = props;

  const searchObject = parseQueryString(locationSearch);
  const queryGrid = searchObject.topBy ? { sort: [{ field: 'dateModified', dir: 'desc' }] } : null;

  const { culture } = useContext(CultureContext);
  const { setApplyFilters, setSaveFilters } = useContext(FilterContext);
  const anchorMenuRef = useRef();
  const menuWrapperRef = useRef();
  const columnSelectorPopupWrapperRef = useRef();
  const blurTimeoutRef = useRef();
  const exportExcelRef = useRef();
  const computed = useRef(false);

  const [popupAction, setPopupAction] = useState('btnExport');
  const [showMenu, setShowMenu] = useState(false);
  const [showColumnSelectorPopup, setShowColumnSelectorPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryString] = useState('');

  const [gridState, setGridState] = useState(() => createDataState(queryGrid || taskModule.config.jsonLayout.grid || taskGrid, []));
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [search, setSearch] = useState('');
  const [appliedSearch, setAppliedSearch] = useState('');

  const [gridWidth, setGridWidth] = useState(768);
  useResizeObserver(document.querySelector('.main-container'), (result) => {
    if (result) setGridWidth((prevWidth) => getTableWidth(prevWidth, tableSelector));
  });

  const hasChecked = Boolean(selectedIds.length);

  const columns = useMemo(() => {
    if (
      Array.isArray(taskModule.config.jsonLayout.columns) &&
      taskModule.config.jsonLayout.columns.length
    ) {
      return taskModule.config.jsonLayout.columns;
    }
    return taskColumns;
  }, [taskModule.config.jsonLayout.columns]);

  const allData = useMemo(
    () => {
      const config = { ...gridState.dataState, skip: 0, take: undefined };
      const list = createDataState(config, taskList);
      return list.result.data;
    },
    [gridState, taskList],
  );
  const visibleColumns = useMemo(
    () => columns.filter((column) => column.show),
    [columns],
  );
  const exportColumns = useMemo(
    () => {
      const noWidthColumns = visibleColumns.map((column) => omit(column, ['width']));
      const hasCurrencyColumn = noWidthColumns.some((s) => s.render === 'currency');
      if (hasCurrencyColumn) {
        return [
          ...noWidthColumns,
          { title: 'Currency', field: 'convertedAnnualAvoidableCostUnitLabel' },
          { title: 'Local Currency', field: 'isoCurrencySymbol' },
        ];
      }
      return noWidthColumns;
    },
    [visibleColumns],
  );

  const computedFilters = useMemo(() => {
    let newFilters =  [];

    if (!computed.current) {
      taskModuleJsonFilters.forEach((eachFilter) => {
        const preCanned = preCannedFilters.find((e) => e.key === eachFilter.key);
        const defaultFilter = jsonFiltersWithDefault.find((e) => e.key === eachFilter.key);
        if (!preCanned && eachFilter.value) {
          newFilters.push(eachFilter);
        } else if (!preCanned && !eachFilter.value && defaultFilter) {
          newFilters.push(defaultFilter);
        } else if (preCanned) {
          newFilters.push(preCanned);
        }
      });
    } else {
      jsonFiltersWithDefault.forEach((eachFilter) => {
        const preCanned = preCannedFilters.find((e) => e.key === eachFilter.key);
        if (!preCanned) {
          newFilters.push(eachFilter);
        } else {
          newFilters.push(preCanned);
        }
      });
    }

    const notFiltersAdded = preCannedFilters.filter((e) => !newFilters.some((s) => s.key === e.key));
    if (notFiltersAdded.length) {
      newFilters = [...newFilters, ...notFiltersAdded];
    }

    computed.current = true;

    return newFilters;
  }, [jsonFiltersWithDefault]);

  const savedComputedFilters = useMemo(() => {
    let newFilters =  [];

    taskModuleJsonFilters.forEach((eachFilter) => {
      const found = jsonFiltersWithDefault.find((e) => e.key === eachFilter.key);
      if (Array.isArray(eachFilter.value) && eachFilter.value.length) {
        newFilters.push(eachFilter);
      } else if (found) {
        newFilters.push(found);
      } else {
        newFilters.push(eachFilter);
      }
    });

    return newFilters;
  }, [jsonFiltersWithDefault, taskModuleJsonFilters]);

  const handleRefresh = useCallback(({ dashboardFilters } = {}) => {
    const jsonFiltersToRefresh = taskModule.config.jsonFilters
      .map((e) => ({ key: e.key, value: e.value }))
      .filter((e) => Array.isArray(e.value) ? e.value.length : true);
    fetchTasks(jsonFiltersToRefresh, dashboardFilters);
    setSelectedIds([]);
    setSearch('');
    setAppliedSearch('');
  }, [taskModule.config, jsonFiltersWithDefault]);

  const handleSave = useCallback(() => {
    let jsonFiltersToSave = taskModule.config.jsonFilters.map((e) => ({ key: e.key, value: e.value }));
    const assigneeNotFromAPI = taskAssignee.find((e) => e.notFromAPI);
    const taskAssigneeFilter = taskModule.config.jsonFilters.find((s) => s.key === 'taskAssignee');
    if (
      history.location.pathname === '/tasks/my-tasks' &&
      assigneeNotFromAPI &&
      taskAssigneeFilter &&
      taskAssigneeFilter.value.includes(assigneeNotFromAPI.uid)
    ) {
      jsonFiltersToSave = jsonFiltersToSave.map((e) => {
        if (e.key === 'taskAssignee') {
          return {
            ...e,
            value: e.value.filter((v) => v !== assigneeNotFromAPI.uid),
          };
        }
        return e;
      });
      showAssigneeModal(() => dispatchSave(jsonFiltersToSave));
    } else {
      dispatchSave(jsonFiltersToSave);
    }
  }, [taskModule.config]);

  const debouncedResize = useCallback(
    debounce((evt) => handleColumnResize(evt), 350),
    [columns, visibleColumns],
  );

  useEffect(()=>{
    if(!userResources.includes(resources.Tasks)) {
      dispatch(push('/pagenotfound'));
    }
  },[]);

  useEffect(() => {
    setApplyFilters(handleRefresh);
    setSaveFilters(handleSave);
  }, [handleRefresh, handleSave]);

  useEffect(() => {
    if (preCannedFilters.length) {
      preCannedFilters.forEach((eachFilter) => {
        dispatch(addOrUpdateJsonFilters(eachFilter));
      });
    }
    return () => {
      dispatch(resetTaskModuleJsonFilters());
    };
  }, []);

  // load tasks on mount
  useEffect(() => {
    if (!loading && !isAllFiltersLoading && !filterOpen) {
      const mergedFilters = preCannedFilters.length ? computedFilters : savedComputedFilters;
      fetchTasks(mergedFilters);
    }
  }, [queryString, currentFilterField, isAllFiltersLoading, computedFilters, savedComputedFilters]);

  // update taskList on taskModule.list, appliedSearch change
  useEffect(() => {
    setTaskList(getTaskListWithSearch(taskModule.list));
  }, [taskModule.list, appliedSearch]);

  // update grid on taskList change
  useEffect(() => {
    setGridState(createDataState({ ...gridState.dataState, skip: 0 }, taskList));
  }, [taskList]);

  // update redux on grid change
  useEffect(() => {
    dispatch(updateTaskModuleGridConfig(gridState.dataState));
  }, [gridState.dataState]);

  // update grid on selectedIds change
  useEffect(() => {
    setGridState(createDataState(gridState.dataState, taskList));
  }, [selectedIds]);

  // update isAllSelected on selectedIds, taskList change
  useEffect(() => {
    if (!taskList.length || !selectedIds.length) {
      setIsAllSelected(false);
    } else {
      const diffList = taskList.filter((item) => !selectedIds.includes(item.taskId));
      setIsAllSelected(!diffList.length);
    }
  }, [selectedIds, taskList]);

  function createDataState(dataState, taskRecords) {
    const list = taskRecords.map((item) => ({
      ...item,
      selected: selectedIds.some((s) => s === item.taskId),
    }));
    const result = process(list, dataState);

    return {
      dataState,
      result,
    };
  }

  function fetchTasks(jsonFilters) {

    const availableListValues = {
      taskStatuses,
    };

    const mergedFilters = [...jsonFilters];
    const hasClientFilter = jsonFilters.some((s) => s.key === 'client');
    const clientFilter = jsonFiltersWithDefault.find((e) => e.key === 'client');
    if (!hasClientFilter && clientFilter) {
      mergedFilters.push(clientFilter);
    }

    const hasBuildingFilter = jsonFilters.some((s) => s.key === 'building');
    const buildingFilter = jsonFiltersWithDefault.find((e) => e.key === 'building');
    if (!hasBuildingFilter && buildingFilter) {
      mergedFilters.push(buildingFilter);
    }

    const { newQueryString, newQueryParams } = getDatasetQueryStringParams({
      userSettings,
      mergedFilters,
      availableListValues,
      filterOverrides,
    });

    setQueryString(newQueryString);

    if (newQueryString) {
      setLoading(true);
      dispatch(
        getTaskModule({
          queryString: newQueryString,
          queryParams: newQueryParams,
        }),
      ).finally(() => {
        setLoading(false);
      });
    }
  }

  function dispatchSave(jsonFiltersToSave) {
    const jsonLayoutToSave = {
      ...taskModule.config.jsonLayout,
      columns: columns.map((e) => omit(e, ['widthInPx'])),
    };
    const params = {
      update: {
        jsonFilters: JSON.stringify(jsonFiltersToSave),
        jsonLayout: JSON.stringify(jsonLayoutToSave),
      },
    };
    dispatch(updateTaskModuleConfig(params)).then(() => {
      dispatch(hideModal());
      showSuccessToast('Configuration Saved.');
      history.push('/tasks');
    });
  }

  function handleUpdateColumns(newColumns) {
    dispatch(updateTaskModuleConfigColumns(newColumns));
  }

  function handleColumnsSubmit(columnsState) {
    handleUpdateColumns(columnsState);
  }

  function handleColumnReorder(evt) {
    const newColumns = columns.map((oldCol) => {
      const foundColumn = evt.columns.find((newCol) => newCol.field === oldCol.field);
      if (foundColumn) {
        return {
          ...oldCol,
          orderIndex: foundColumn.orderIndex,
        };
      }
      return oldCol;
    });

    handleUpdateColumns(newColumns);
  }

  function handleColumnResize(evt) {
    if (evt.end) {
      const resizedColumn = visibleColumns.sort((a, b) => a.orderIndex - b.orderIndex).find((_, index) => evt.index === index + 1);
      const newColumns = columns.map((col) => {
        if (resizedColumn && resizedColumn.field === col.field) {
          return {
            ...col,
            width: getPercentage(gridWidth, evt.newWidth),
            widthInPx: evt.newWidth,
          };
        }
        return col;
      });
      handleUpdateColumns(newColumns);
    }
  }

  function expandChange(event) {
    // eslint-disable-next-line no-param-reassign
    event.dataItem[event.target.props.expandField] = event.value;
    setGridState({ ...gridState });
  }

  function dataStateChange(event) {
    setGridState(({ dataState: prevDataState }) => ({
      ...prevDataState,
      ...createDataState(event.data, taskList),
    }));
  }

  function handleSelectionChange(event) {
    const { taskId, selected } = event.dataItem;
    if (selected) {
      setSelectedIds((prevIds) => removeFromList(prevIds, taskId));
    } else {
      setSelectedIds((prevIds) => addToList(prevIds, taskId));
    }
  }

  function handleHeaderSelectionChange(event) {
    const { checked: selected } = event.syntheticEvent.target;
    if (selected) {
      const taskIds = taskList.map((item) => item.taskId);
      setSelectedIds(taskIds);
    } else {
      setSelectedIds([]);
    }
  }

  function handleKeyPress(e) {
    if ((e.keyCode || e.which) === 13) {
      applySearch();
    }
  }

  function handleSearchChange(e) {
    setSearch(e.target.value);
  }

  function getTaskListWithSearch(list) {
    return list.filter((item) => {
      const {
        summary, description, recommendations, actions,
        assignee, reporter, status, organizationName, buildingName,
        equipmentName, equipmentClassName, equipmentTypeName, analysisName, clientTaskId, workOrderId,
      } = item;
      const fields = [
        summary, description, recommendations, actions,
        assignee, reporter, status, organizationName, buildingName,
        equipmentName, equipmentClassName, equipmentTypeName, analysisName, clientTaskId, workOrderId,
      ];
      const fullString = fields.filter((field) => field !== null).join('  ');
      return searchForMatchKeyword(fullString, appliedSearch);
    });
  }

  function applySearch() {
    setAppliedSearch(search);
  }

  function closeModal() {
    dispatch(hideModal());
  }

  function hideTaskDetailsModal() {
    closeModal();
    dispatch(clearTaskDetails());
    dispatch(clearReportersAndAssignees());
  }

  function handleRowClick(evt) {
    const { dataItem, nativeEvent } = evt;
    const isCheckboxColumn = (
      nativeEvent.target &&
      typeof nativeEvent.target.className === 'string' &&
      nativeEvent.target.className.includes('colCheckbox')
    );

    if (isCheckboxColumn) return;

    dispatch(getTaskDetails(dataItem.taskId));
    dispatch(
      showModal(
        'TASK_DETAILS',
        {
          culture,
          dataItem,
          modalContent: 'full scrollable',
          cancelCallback: hideTaskDetailsModal,
        },
      ),
    );
  }

  function bulkDelete() {
    dispatch(
      bulkDeleteTaskModule({
        ids: selectedIds,
      }),
    ).then(() =>
      showBulkFeedback('deleted'),
    );
  }

  function bulkEdit(payload) {
    dispatch(
      bulkEditTaskModule({
        taskId: selectedIds,
        resource: { update: payload },
      }),
    ).then(() =>
      showBulkFeedback('edited'),
    );
  }

  function showBulkFeedback(action) {
    setSelectedIds([]);
    closeModal();
    showSuccessToast(`Task(s) have been successfully ${action}.`);
  }

  function showSuccessToast(message) {
    toastr.success(message, message, {
      removeOnHover: true,
      removeOnHoverTimeOut: 1000,
    });
  }

  function showAssigneeModal(yesCallback) {
    dispatch(
      updateModal(
        'NOTIFICATION',
        {
          type: 'error',
          title: 'Error',
          message: 'Task Assignee list cannot be saved because you are not currently a task assignee for the selected organizations. Your other filters will be saved.',
          yesLabel: 'OK',
          yesCallback,
        },
      ),
    );
  }

  function handleBulkDeleteClick() {
    const plural = selectedIds.length > 1;
    dispatch(
      showModal(
        'DELETE',
        {
          culture,
          message: `You are about to delete ${selectedIds.length} task${plural ? 's' : ''}`,
          stateProgress: 'taskModule.bulkDeleting',
          deleteFunc: bulkDelete,
          close: closeModal,
        },
      ),
    );
  }

  function handleBulkEditClick() {
    dispatch(
      showModal(
        'BULK_EDIT_TASK',
        {
          modalContent: 'full scrollable',
          cancelCallback: closeModal,
          saveCallback: bulkEdit,
          stateProgress: 'taskModule.bulkEditing',
        },
      ),
    );
  }

  function handleSelect(e) {
    setShowMenu(false);

    switch (e.item.text) {
      case 'Export CSV':
        handleExportCsv();
        break;
      case 'Export Excel':
        handleExportExcel();
        break;
      case 'Edit':
        handleBulkEditClick();
        break;
      case 'Delete':
        handleBulkDeleteClick();
        break;
      case 'Choose Columns':
        setShowColumnSelectorPopup(true);
        break;
      default:
        break;
    }
  }

  function blurTimeout() {
    showMenu && setShowMenu(false);
    showColumnSelectorPopup && setShowColumnSelectorPopup(false);
    blurTimeoutRef.current = undefined;
  }

  function handleMenuClick(evt) {
    anchorMenuRef.current = evt.target;
    setPopupAction(evt.target.name);
    setTimeout(() => setShowMenu((prevShow) => !prevShow));
  }

  function handlePopupOpen() {
    const wrapperEl = menuWrapperRef.current;
    wrapperEl && wrapperEl.focus();

    const wrapperEl2 = columnSelectorPopupWrapperRef.current;
    wrapperEl2 && wrapperEl2.focus();
  }

  function handleFocusHandler() {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = undefined;
  }

  function handleBlurHandler(evt) {
    const { relatedTarget } = evt;
    if (
      relatedTarget &&
      relatedTarget.getAttribute &&
      relatedTarget.getAttribute('id')
    ) {
      if (relatedTarget.getAttribute('id').includes('column-visibility')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-container')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-reset')) return;
      if (relatedTarget.getAttribute('id').includes('column-popup-submit')) return;
    }

    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(blurTimeout, 100);
  }

  function handleExportExcel() {
    if (exportExcelRef.current) {
      makeAsync(() => {
        const excelColumns = exportColumns.map((column) => ({
          ...column,
          cell: column.render
            ? createCellRenderer(column, { renderType: 'excel', culture })
            : column.cell,
        }));
        const formattedAllData = formatExportExcelData(allData, excelColumns);
        exportExcelRef.current.save(formattedAllData, excelColumns);
      });
    }
  }

  function handleExportCsv() {
    makeAsync(() => {
      const csvColumns = exportColumns.map((column) => ({
        ...column,
        cell: column.render
          ? createCellRenderer(column, { renderType: 'csv', culture })
          : column.cell,
      }));
      exportCsv(`Task - ${Date.now()}`, csvColumns, allData);
    });
  }

  return (
    <div data-testid="ManageTasks" className="column content-spacing-container">
      <div className="level" style={{ marginBottom: '.5rem' }}>
        <div className="level-left">
          <div className="level-item">
            <h1 className="title">{title}</h1>
          </div>
        </div>

        <div className="level-right">
          <div className="level-item">
            <IconButton
              title="Save Configuration"
              className={`dashboard-icon-button button${taskModule.configSaving ? ' is-loading' : ''}`}
              iconName='flaticon-floppy-disk'
              onClick={handleSave}
            />
          </div>
        </div>
      </div>

      <div className="level" style={{ marginBottom: '.5rem' }}>
        <TaskSummary culture={culture} taskList={taskList} />
      </div>

      <TaskMenuPopup
        action={popupAction}
        show={showMenu}
        menuRef={anchorMenuRef}
        menuWrapperRef={menuWrapperRef}
        onPopupOpen={handlePopupOpen}
        onSelect={handleSelect}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
      />

      <ColumnSelectorPopup
        initialColumns={taskColumns}
        columns={columns}
        show={showColumnSelectorPopup}
        menuRef={anchorMenuRef}
        menuWrapperRef={columnSelectorPopupWrapperRef}
        onPopupOpen={handlePopupOpen}
        onFocusHandler={handleFocusHandler}
        onBlurHandler={handleBlurHandler}
        onColumnsSubmit={handleColumnsSubmit}
      />

      <BaseGrid
        wrapperClassName="task-grid"
        data={gridState.result}
        {...gridState.dataState}
        onDataStateChange={dataStateChange}
        onSelectionChange={handleSelectionChange}
        onHeaderSelectionChange={handleHeaderSelectionChange}
        expandField="expanded"
        selectedField="selected"
        onExpandChange={expandChange}
        sortable={{ allowUnsort: true }}
        onRowClick={handleRowClick}
        onColumnReorder={handleColumnReorder}
        onColumnResize={debouncedResize}
        resizable
        reorderable
        pageable={{ type: 'input', info: true, pageSizes: [10, 25, 50, 100] }}
      >
        <GridToolbar>
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <button
                  disabled={!hasChecked}
                  name="btnBulkAction"
                  className="button"
                  title={hasChecked ? 'Bulk action' : 'No tasks are selected' }
                  onClick={handleMenuClick}
                >
                  Bulk Action
                </button>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <input
                  size={32}
                  type="text"
                  className="input"
                  placeholder="Enter text to search columns"
                  value={search}
                  onChange={handleSearchChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="level-item">
                <button className="button" title="Search" onClick={applySearch}>
                  <span className="icon">
                    <i className="flaticon-search-interface-symbol" aria-hidden="true" />
                  </span>
                </button>
              </div>
              <div className="level-item">
                <button
                  name="btnExport"
                  className="button"
                  title="More"
                  ref={anchorMenuRef}
                  onClick={handleMenuClick}
                >
                  <span className="icon">
                    <i className="flaticon-menu" aria-hidden="true" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </GridToolbar>
        <GridNoRecords>
          {isAllFiltersLoading || loading
            ? <i className="bulma-loader-mixin m-auto" aria-hidden="true"></i>
            : 'No records available'
          }
        </GridNoRecords>
        <GridColumn
          field="selected"
          className="colCheckbox"
          resizable={false}
          reorderable={false}
          headerSelectionValue={isAllSelected}
          width={34}
        />
        {visibleColumns.map((column, idx) => (
          <GridColumn
            key={idx}
            className={`${column.className || ''} ${column.nowrap ? 'nowrap' : ''}`}
            field={column.field}
            title={column.title}
            filter={column.filter}
            reorderable={column.reorderable !== false}
            orderIndex={column.orderIndex}
            cell={column.render
              ? createCellRenderer(column, { renderType: 'normal', culture })
              : column.cell}
            width={getGridColumnWidthInPx(column, gridWidth)}
            headerCell={HeaderCell}
          />
        ))}
      </BaseGrid>

      <ExcelExport
        ref={exportExcelRef}
        fileName={`Tasks - ${Date.now()}.xlsx`}
      />
    </div>
  );
};

ManageTasks.defaultProps = {
  title: 'Manage Tasks',
};

ManageTasks.propTypes = {
  userResources: PropTypes.array,
  search: PropTypes.string,
  jsonFiltersWithDefault: PropTypes.array,
  taskModuleJsonFilters: PropTypes.array,
  taskStatuses: PropTypes.array,
  taskLabels: PropTypes.array,
  filterOverrides: PropTypes.string,
  userSettings: PropTypes.object.isRequired,
  taskModule: PropTypes.object.isRequired,
  currentFilterField: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  title:  PropTypes.string,
  preCannedFilters: PropTypes.array,
  taskAssignee: PropTypes.array,
  history: PropTypes.object,
  isAllFiltersLoading: PropTypes.bool,
  filterOpen: PropTypes.bool,
};

export default ManageTasks;
