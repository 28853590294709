/* eslint-disable no-param-reassign */
import { formatDate, parseDate } from '@telerik/kendo-intl';
import { addDays, addMonths, addWeeks, addYears, Day, firstDayOfMonth, firstDayInWeek, firstMonthOfYear, lastDayOfMonth, lastMonthOfYear } from '@progress/kendo-date-math';
import { convertToISOString, formatDateIfDefined, getLastXDaysRange, utcStringToDatePlusOffset } from '../../utils';
import diffDays from '../../utils/diffDays';
import { getPredefinedDateValue } from '../dashboardFilters/filterComponents/DateRangeFilter';
import * as datasets from '../../enums/datasets';
import { getDatasetIdByEndpoint } from '../../enums/datasetEndpoint';
import { threeStateFilterFields } from '../../enums/filters';
import modules from '../../enums/modules';

export function getClonedWidgetName(jsonWidgetSetting, widget) {
  return `Clone - ${jsonWidgetSetting && jsonWidgetSetting.templateName.length > 0 ? jsonWidgetSetting.templateName : widget.widgetTemplateName}`;
}

export function getDatasetQueryStringParams(data) {
  const {
    userSettings, widgetQueryString, availableListValues, topFilter, moduleId,
    filterOverrides, preview = false, kql, datasetIDs = [], crossFilterCurrent, widgetDrilldown, buildings = [], mergedFilters,
  } = data;
  const { taskStatuses } = availableListValues;

  const filterOverridesToArray = (filterOverrides || '').split('&');
  const getDashboardFilterValue = preview ? returnUndefined : withAppliedFilters(mergedFilters);
  const getBuidingsGroupFilterValue = withAppliedFilters(mergedFilters);

  let buildingsGroupFilters = {
    UnitID: getBuidingsGroupFilterValue('client'),
    BID: getBuidingsGroupFilterValue('building'),
    BuildingClassID: getBuidingsGroupFilterValue('buildingClass'),
    BuildingTypeID: getBuidingsGroupFilterValue('buildingType'),
    BGID: getBuidingsGroupFilterValue('buildingGroup'),
  };

  if (crossFilterCurrent) {
    crossFilterCurrent.filters.forEach((eachFilter) => {
      const included = Object.keys(buildingsGroupFilters).includes(eachFilter.filterName);
      if (included && eachFilter.filterValue) {
        buildingsGroupFilters[eachFilter.filterName] = [eachFilter.filterValue];
      }
    });
    const unitIdFilter = crossFilterCurrent.filters.find((s) => s.filterName === 'UnitID');
    if (unitIdFilter) {
      const availableBIDs = buildings.filter((e) => e.unitId === unitIdFilter.filterValue).map((e) => e.bid);
      buildingsGroupFilters.BID = buildingsGroupFilters.BID.filter((bid) => availableBIDs.includes(bid));
    }
  }

  if (widgetDrilldown && widgetDrilldown.filters) {
    widgetDrilldown.filters.forEach((eachFilter) => {
      const included = Object.keys(buildingsGroupFilters).includes(eachFilter.filterName);
      if (included && eachFilter.filterValue) {
        buildingsGroupFilters[eachFilter.filterName] = [eachFilter.filterValue];
      }
    });
  }

  const [EnergyMin, EnergyMax] = getDashboardFilterValue('energy') || [];
  const [ComfortMin, ComfortMax] = getDashboardFilterValue('comfort') || [];
  const [MaintenanceMin, MaintenanceMax] = getDashboardFilterValue('maintenance') || [];
  const [StartDate, EndDate] = (datasetIDs.includes(datasets.widgetDatasets.AggregatedRawDataDataset) ? getDashboardFilterValue('aggregationDate') : getDashboardFilterValue('rawdatasetDate')) || [];
  const [DiagnosticStartDate, DiagnosticEndDate] = getDashboardFilterValue('diagnosticDate') || [];
  const [TaskOpenedStartDate, TaskOpenedEndDate] = getDashboardFilterValue('taskOpenedDate') || [];
  const [TaskCompletionStartDate, TaskCompletionEndDate] = getDashboardFilterValue('taskCompletionDate') || [];
  const [TaskModifiedStartDate, TaskModifiedEndDate] = getDashboardFilterValue('taskModifiedDate') || [];
  const [DateModifiedStartDate, DateModifiedEndDate] = getDashboardFilterValue('taskModifiedDate') || [];

  const startEndDates = moduleId === modules.userModules.Home
    ? {
      DateModifiedStartDate,
      DateModifiedEndDate,
    } : {
      TaskModifiedStartDate,
      TaskModifiedEndDate,
      StartDate,
      EndDate,
    };

  const dashboardFiltersPart = {
    ...startEndDates,
    EnergyMin,
    EnergyMax,
    ComfortMin,
    ComfortMax,
    MaintenanceMin,
    MaintenanceMax,
    DiagnosticStartDate,
    DiagnosticEndDate,
    TaskOpenedStartDate,
    TaskOpenedEndDate,
    TaskCompletionStartDate,
    TaskCompletionEndDate,
    DeploymentGroupID: getDashboardFilterValue('deploymentGroup'),
    EquipmentClassID: getDashboardFilterValue('equipmentClass'),
    EquipmentTypeID: getDashboardFilterValue('equipmentType'),
    EquipmentTags: getDashboardFilterValue('equipmentLabel'),
    EID: getDashboardFilterValue('equipment'),
    PointClassID: getDashboardFilterValue('pointClass'),
    PointTypeID: getDashboardFilterValue('pointType'),
    PID: getDashboardFilterValue('point'),
    VPID: getDashboardFilterValue('vPoint'),
    AvoidableCost: getDashboardFilterValue('avoidableCost'),
    AnnualAvoidableCost: getDashboardFilterValue('annualAvoidableCost'),
    TaskStatus: getDashboardFilterValue('taskStatus', taskStatuses),
    DiagnosticAnalysisInterval: getDashboardFilterValue('diagnosticAnalysisInterval'),
    AggregationInterval: getDashboardFilterValue('aggregationInterval'),
    TaskAssignedUID: getDashboardFilterValue('taskAssignee'),
    TaskReporterUID: getDashboardFilterValue('taskReporter'),
    AID: getDashboardFilterValue('analysis'),
    ISOCurrencySymbol: getDashboardFilterValue('currency'),
    DSID: getDashboardFilterValue('dataSource'),
    IsBuildingVisible: getDashboardFilterValue('isBuildingVisible'),
    IsBuildingActive: getDashboardFilterValue('isBuildingActive'),
    IsEquipmentVisible: getDashboardFilterValue('isEquipmentVisible'),
    IsEquipmentActive: getDashboardFilterValue('isEquipmentActive'),
    IsPointActive: getDashboardFilterValue('isPointActive'),
    ConfigurationStatusID: getDashboardFilterValue('configurationStatusId'),
    ResultGroupID: getDashboardFilterValue('resultGroup'),
    ResultClassID: getDashboardFilterValue('resultClass'),
    ResultTypeID: getDashboardFilterValue('resultType'),
    ResultSubTypeID: getDashboardFilterValue('resultSubType'),
    TaskTags: getDashboardFilterValue('taskLabel'),
  };

  const userSettingParams = {
    isUserSI: userSettings.isSi,
    userISOCurrencySymbol: userSettings.isoCurrencySymbol,
  };

  const topFilterParams = topFilter
    ? {
      TopBy: topFilter.topBy,
      MaxRows: topFilter.topValue,
    } : {};

  const overridedBuildingsGroupFilters = overrideFilters(filterOverridesToArray, buildingsGroupFilters);
  const overridedDashboardFilters = overrideFilters(filterOverridesToArray, dashboardFiltersPart);

  const buildingsGroupFiltersQueryString = makeQueryString(overridedBuildingsGroupFilters);
  const dashboardFiltersQueryString = makeQueryString(overridedDashboardFilters);
  const userSettingQueryString = makeQueryString(userSettingParams);
  const topFilterQueryString = makeQueryString(topFilterParams);
  const separatorString = dashboardFiltersQueryString ? '&' : '';
  const filtersQueryString = `${buildingsGroupFiltersQueryString}${separatorString}${dashboardFiltersQueryString}&${userSettingQueryString}${topFilterQueryString ? `&${topFilterQueryString}` : ''}`;

  let newQueryString;
  let newQueryParams = applyKeyMapper({ ...overridedBuildingsGroupFilters, ...overridedDashboardFilters, ...userSettingParams, ...topFilterParams });

  if (datasets.kqlDatasets.includes(datasetIDs[0])) {
    newQueryString = `?kql=${encodeURIComponent(kql)}${preview ? '&isPreview=true' : ''}&${filtersQueryString}`;
    newQueryParams.kql = kql;
    if (datasetIDs.length > 1) {
      newQueryString = `${newQueryString}&datasetId=${datasetIDs}`;
      newQueryParams.datasetId = datasetIDs;
    }
  } else {
    newQueryString = `${widgetQueryString ? `${widgetQueryString}&` : '?'}${filtersQueryString}`;
  }

  /*
    Apply defaults for:
    isBuildingVisible, isBuildingActive, isEquipmentVisible, isEquipmentActive, isPointActive
  */
  threeStateFilterFields.forEach((filterField) => {
    newQueryString = overrideThreeStateQueryString(filterField, newQueryString);
  });

  return { newQueryString, newQueryParams };
}

function overrideFilters(overridesArray = [], filtersPart = {}) {
  let overriddenFilters = Object.entries(filtersPart).reduce((accFilters, [key, value]) => {
    const overrideField = overridesArray.find((str) => str.includes(`${key}=`));
    let newValue;

    if (overrideField) {
      const overrideValue = overrideField.replace(`${key}=`, '');
      newValue = overrideValue;
    } else {
      newValue = value;
    }

    if (newValue && overrideField && ['StartDate', 'EndDate'].includes(key)) {
      const format = 'd';
      const dateFormatter = (dateString) => formatDate(parseDate(dateString, 'yyyy-MM-dd'), format);
      newValue = getDateValue(newValue, undefined, { dateFormatter, parsedDateTokenFormat: format });
    } else if (newValue && key.toLocaleLowerCase().includes('date')) {
      newValue = getDateValue(newValue);
    } else if (newValue && [
      'BGID','BuildingClassID', 'BuildingTypeID', 'EquipmentClassID', 'EquipmentTypeID', 'TaskStatus', 'AID', 'ISOCurrencySymbol',
      'PointTypeID', 'PointClassID', 'PID', 'VPID',
    ].includes(key)) {
      newValue = formatStringToArray(key, newValue);
    }

    if (newValue !== undefined) {
      return { ...accFilters, [key]: newValue };
    }

    return accFilters;
  }, {});
  if (overridesArray.findIndex((el) => el.includes('usePrevious=true')) > -1 && overriddenFilters.StartDate) {
    const firstPeriod = diffDays(overriddenFilters.StartDate, overriddenFilters.EndDate);
    overriddenFilters.StartDate = addDays(new Date(overriddenFilters.StartDate), -firstPeriod);
  }
  return overriddenFilters;
}

function withAppliedFilters(appliedFilters) {
  function getDashboardFilterValue(filterKey, availableValues = []) {
    const dashboardFilter = appliedFilters.find(({ key }) => key === filterKey);

    // if filter not applied
    if (!dashboardFilter) return undefined;

    const { value: filterValue } = dashboardFilter;

    // if filter applied and has value
    return formatFilterValue(filterKey, filterValue, availableValues);
  }

  return getDashboardFilterValue;
}

function formatFilterValue(filterKey, filterValue, availableValues) {
  let newValue;

  switch (filterKey) {
    case 'diagnosticDate': {
      const { start, end } = filterValue.value === 'custom_range'
        ? filterValue.range
        : getPredefinedDateValue(filterValue.value);
      const startDate = formatDateIfDefined(start);
      const endDate = formatDateIfDefined(end);
      newValue = [startDate, endDate];
      break;
    }
    case 'aggregationDate':{
      if (filterValue?.value === undefined) {
        newValue= [undefined, undefined];
      } else {
        const { start, end } = filterValue.value === 'custom_range'
          ? filterValue.range
          : {
            start: formatDate(getPredefinedDateValue(filterValue.value).start, 'd'),
            end: null,
          };
        const startDate = formatDateIfDefined(start);
        const endDate = formatDateIfDefined(end);
        newValue = [startDate, endDate];
      }
      break;
    }
    case 'taskOpenedDate':
    case 'taskCompletionDate':
    case 'taskModifiedDate': {
      const { start, end } = filterValue.value === 'custom_range'
        ? filterValue.range
        : getPredefinedDateValue(filterValue.value);
      const startDate = formatDateIfDefined(start);
      const endDate = formatDateIfDefined(end, filterValue.value === 'custom_range' ? 'y-MM-dd' : 'yyyy-MM-ddTHH:mm:ss.sssZ');
      newValue = [startDate, endDate];
      break;
    }
    case 'rawdatasetDate': {
      if (filterValue.value === 'All') {
        newValue= [undefined, undefined];
      } else {
        const { start, end } = filterValue.value === 'custom_range'
          ? filterValue.range
          : getLastXDaysRange(filterValue.inputValue);
        const startDate = formatDateIfDefined(start);
        const endDate = formatDateIfDefined(end);
        newValue = [startDate, endDate];
      }
      break;
    }
    case 'taskStatus': {
      const taskStatuses = Array.isArray(filterValue)
        ? filterValue.map((taskStatusId) => (availableValues.find(({ statusId }) => statusId === taskStatusId) || {}).statusName)
        : filterValue || undefined;
      newValue = taskStatuses;
      break;
    }

    case 'diagnosticAnalysisInterval':
    case 'aggregationInterval':
      newValue = filterValue === 'All' ? undefined : filterValue;
      break;


    default:
      newValue = filterValue;
      break;
  }

  return newValue;
}

function getValue(key, value) {
  let newValue = '';

  switch (key) {
    case 'UnitID':
    case 'BuildingClassID':
    case 'BuildingTypeID':
    case 'BGID':
    case 'BID':
    case 'Maintenance':
    case 'Comfort':
    case 'EquipmentClassID':
    case 'EquipmentTypeID':
    case 'EID':
    case 'PointClassID':
    case 'PointTypeID':
    case 'PID':
    case 'VPID':
    case 'TaskStatus':
    case 'TaskReporterUID':
    case 'TaskAssignedUID':
    case 'AID':
    case 'ISOCurrencySymbol':
    case 'DSID':
    case 'ConfigurationStatusID':
    case 'ResultGroupID':
    case 'ResultTypeID':
    case 'ResultClassID':
    case 'ResultSubTypeID':
      newValue = Array.isArray(value) ? value.join(',') : value;
      break;
    case 'EquipmentTags':
    case 'TaskTags':
      newValue = Array.isArray(value) ? value.map((e) => encodeURIComponent(e)).join(',') : encodeURIComponent(value);
      break;
    case 'StartDate':
      newValue = value ? `${formatDate(new Date(value), 'y-MM-dd')}T00:00:00.000Z` : value;
      break;
    case 'EndDate':
      newValue = value ? `${formatDate(new Date(value), 'y-MM-dd')}T23:59:59.000Z` : value;
      break;

    case 'DiagnosticStartDate':
    case 'DiagnosticEndDate':
      newValue = value;
      break;

    case 'TaskOpenedStartDate':
    case 'TaskCompletionStartDate':
    case 'TaskModifiedStartDate':
    case 'DateModifiedStartDate':
      newValue = value ? convertToISOString(utcStringToDatePlusOffset(value)) : value;
      break;

    case 'DateModifiedEndDate':
    case 'TaskModifiedEndDate':
    case 'TaskOpenedEndDate':
    case 'TaskCompletionEndDate': {
      const isCustomRange = value && value.length > 0 && value.split('-').length > 2 && value.split('-')[2].length === 2 ? true : false;
      newValue = value ? isCustomRange ? convertToISOString(utcStringToDatePlusOffset(`${value}T23:59:59.999Z`)) : convertToISOString(value) : value;
      break;
    }
    default:
      newValue = `${value}`;
      break;
  }

  return newValue;
}

function formatStringToArray(key, value) {
  if (!value || typeof value !== 'string') return value;

  let newValue = '';

  switch (key) {
    case 'BGID':
    case 'BuildingClassID':
    case 'BuildingTypeID':
    case 'EquipmentClassID':
    case 'EquipmentTypeID':
    case 'PointClassID':
    case 'PointTypeID':
    case 'PID':
    case 'VPID':
    case 'AID':
      newValue = value.split(',').map((e) => Number(e));
      break;

    case 'TaskStatus':
    case 'ISOCurrencySymbol':
      newValue = value.split(',');
      break;

    default:
      newValue = value;
      break;
  }

  return newValue;
}

function returnUndefined() {
  return undefined;
}

export function getDateValue(value, startDate = new Date(), options = {}) {
  const validTokens = ['$r', '$d', '$m', '$w', '$y', '$sw', '$sm', '$sy', '$ew', '$em', '$ey'];
  const valueHasToken = validTokens.some((token) => value.includes(token));

  if (valueHasToken) {
    return parseDateToken(value, startDate, options);
  }

  if (!value || value === 'null') {
    return '';
  }

  if (typeof options.dateFormatter === 'function') {
    return options.dateFormatter(value);
  }

  return formatDate(value, 'yyyy-MM-dd');
}

function parseDateToken(value, today, options = {}) {
  const tokenParts = value.split(/(\$[a-zA-z]+)/);
  const token = tokenParts[1];
  const tokenValue = Number(tokenParts[2]);
  let dateValue;

  switch (token) {
    case '$r':
    case '$d':
      dateValue = addDays(today, -tokenValue);
      break;
    case '$em':
      dateValue = lastDayOfMonth(addMonths(today, -tokenValue));
      break;
    case '$ew':
      dateValue = addDays(firstDayInWeek(addWeeks(today, -tokenValue), Day.Sunday), 6);
      break;
    case '$ey':
      // subtract the specified years, get to the last month, get the last day
      dateValue = lastDayOfMonth(lastMonthOfYear(addYears(today, -tokenValue)));
      break;
    case '$m':
      dateValue = addMonths(today, -tokenValue);
      break;
    case '$w':
      dateValue = addWeeks(today, -tokenValue);
      break;
    case '$sm':
      dateValue = firstDayOfMonth(addMonths(today, -tokenValue));
      break;
    case '$sw':
      dateValue = firstDayInWeek(addWeeks(today, -tokenValue), Day.Sunday);
      break;
    case '$sy':
      dateValue = firstDayOfMonth(firstMonthOfYear(addYears(today, -tokenValue)));
      break;
    case '$y':
      dateValue = addYears(today, -tokenValue);
      break;
    default:
      dateValue = value;
      break;
  }

  const format = options.parsedDateTokenFormat || 'yyyy-MM-dd';

  return formatDate(dateValue, format);
}

function applyKeyMapper(objectParams) {
  return Object.entries(objectParams).reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
}

function makeQueryString(filters = {}) {
  const queryString = Object
    .entries(filters)
    .filter(([, value]) => value !== undefined && value !== null)
    .reduce((accString, [key, value], index) => {
      const separatorString = index ? '&' : '';
      const equalityString = '=';
      const valueString = getValue(key, value);

      return `${accString}${separatorString}${key}${equalityString}${valueString}`;
    }, '');

  return queryString;
}

function overrideThreeStateQueryString(filterField, queryString) {
  if (!queryString.includes(`&${filterField}=`)) {
    queryString = `${queryString}&${filterField}=true`;
  }
  if (queryString.includes(`&${filterField}=all`)) {
    queryString = queryString.replace(`&${filterField}=all`, '');
  }
  return queryString;
}

export function getWidgetDatasetIds(datasetIDs, widgetDrilldown) {
  const widgetDatasetIds = (widgetDrilldown && widgetDrilldown.current)
    ? [getDatasetIdByEndpoint(widgetDrilldown.current.dataset)] || datasetIDs
    : datasetIDs;
  return widgetDatasetIds;
}

export default {
  getDateValue,
  getDatasetQueryStringParams,
};
