import React from 'react';
import PropTypes from 'prop-types';
import { GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import BaseGrid from '../../common/Grid/BaseGrid';
import useElementWidthListener from '../../../hooks/useElementWidthListener';
import { setPercentage } from '../../../utils';
import useGridDataState from '../../../hooks/useGridDataState';
import FormattedGridCell from '../../common/FormattedGridCell';

const EquipmentVariablePreviewGrid = ({ loading, data: dataProp }) => {
  const gridWidth = useElementWidthListener('.equipment-variable-preview .k-widget.k-grid');
  const { dataState, onDataStateChange } = useGridDataState(dataProp, config);

  return (
    <BaseGrid
      resizable
      scrollable="none"
      wrapperClassName="equipment-variable-preview-grid borderless-grid"
      data={dataState.result}
      {...dataState.dataState}
      onDataStateChange={onDataStateChange}
    >
      {columns.map((column) => {
        const Cell = column.cell;
        const CustomCell = (props) => (
          <Cell {...props} />
        );
        return (
          <GridColumn
            {...column}
            key={column.field}
            field={column.field}
            title={column.title}
            cell={column.cell ? CustomCell : undefined}
            width={setPercentage(gridWidth, column.width)}
          />
        );
      })}
      <GridNoRecords>
        {loading
          ? <i className="bulma-loader-mixin m-auto" aria-hidden="true"></i>
          : 'No records available'
        }
      </GridNoRecords>
    </BaseGrid>
  );
};

const config = {
  sort: [],
};

const renderers = {
  value: FormattedGridCell,
};

const columns = [
  {
    field: 'equipmentName',
    title: 'Equipment Name',
    width: 33,
  },
  {
    field: 'value',
    title: 'Old Value',
    width: 33,
    cell: renderers.value,
  },
  {
    field: 'engUnits',
    title: 'Building SI/IP Unit',
    width: 33,
  },

];

EquipmentVariablePreviewGrid.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

export default EquipmentVariablePreviewGrid;
